import React, { ReactElement } from 'react';
import { DoctIcon, DoctIconButton } from 'doct-core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Chip from '../../Chip/Chip';

interface Props {
    options?: [] | any;
    variant?: 'standard' | 'filled' | 'outlined';
    textFieldProps?: any;
    autoCompleteProps?: any;
    wrapperProps?: any;
    value: any;
    onChange: any;
    disabled?: boolean;
}

function DoctWrapper({ children, label, readOnly, onCheckClicked, disabled }) {
    return (
        <div className="d-flex">
            <div
                className={`doct-autocomplete-select doct-autocomplete-select-with-chips with-custom-close doct-autocomplete-select-with-chips-free-solo input-text-truncate w-100 ${
                    readOnly ? ' doct-read-only-input' : ''
                }${label ? ' ' : ' none-doct-input-label'}`}
            >
                {children}
            </div>
            <span
                className="mt-1"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onCheckClicked();
                }}
            >
                <DoctIconButton disabled={disabled} variant="outlined" type="secondary" size="medium" icon="check" />
            </span>
        </div>
    );
}

export default function AutoCompleteWithChipsFreeSolo({
    options = [],
    variant = 'filled',
    textFieldProps = {},
    autoCompleteProps = {},
    value = [],
    onChange = () => 0,
}: Props): ReactElement {
    const onDelete = (title) => () => {
        const filteredValue = value.filter((v) => v !== title);
        onChange(filteredValue);
    };

    const [inputValue, setInputValue] = React.useState('');

    const inputRef = React.useRef(null);

    const handleOnCheckClicked = () => {
        if (inputRef?.current?.value) {
            const newValue = [...value, inputRef?.current?.value];
            onChange(newValue);
            setInputValue('');
        }
    };

    return (
        <DoctWrapper
            label={textFieldProps?.label}
            readOnly={autoCompleteProps?.readOnly}
            onCheckClicked={handleOnCheckClicked}
            disabled={!inputValue}
        >
            <Autocomplete
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                freeSolo
                multiple
                options={options}
                value={value}
                onChange={(e, newValue) => onChange(newValue)}
                renderTags={() => null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={variant}
                        {...textFieldProps}
                        className={'doct-input'}
                        inputRef={inputRef}
                        onKeyDown={(e) => {
                            if (e.key == 'Backspace') {
                                e.stopPropagation();
                            }
                        }}
                    />
                )}
                disableClearable={!inputValue}
                clearIcon={
                    <div
                        className="cutsom-close-icon d-flex align-items-center"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setInputValue('');
                        }}
                    >
                        <DoctIcon name="close" width="20" height="20" className="text-grey-600" />
                    </div>
                }
                {...autoCompleteProps}
            />

            {value?.map((v) => (
                <Chip key={v} title={v} onCloseHandler={onDelete(v)} />
            ))}
        </DoctWrapper>
    );
}
