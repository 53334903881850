import React, { ReactElement } from 'react';

import { Controller, Control, FieldError } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { DeepMap } from 'react-hook-form/dist/types/utils';
// import OutlinedInput from
import '../Form.scss';

interface Props {
    label: string;
    id: string;
    name: string;
    control: Control<Record<string, any>>;
    variant?: 'standard' | 'filled' | 'outlined';
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    touched?: DeepMap<Record<string, any>, true>;
    validationRules?: any;
    defaultValue?: any;
    showStar?: boolean;
    formState?: any;
    inputProps?: object;
    standaloneName?: string;
}

export default function DoctTextField({
    label,
    id,
    name,
    variant = 'standard',
    disabled = false,
    readOnly = false,
    className,
    control,
    isErrors,
    defaultValue = '',
    validationRules,
    showStar = true,
    inputProps,
    standaloneName,
    ...rest
}: Props): ReactElement {
    return (
        <>
            <Controller
                // touched={touched[name]}
                rules={validationRules}
                render={({ onChange, value }) => (
                    <TextField
                        required={showStar && Object.keys(validationRules).length >= 1}
                        value={value}
                        onChange={(e) => {
                            if (!!!value && e.target.value == ' ') {
                                return;
                            }
                            onChange(e);
                        }}
                        error={isErrors[standaloneName || name] ? true : false}
                        helperText={isErrors[standaloneName || name]?.message}
                        inputProps={{
                            readOnly: readOnly,
                            ...inputProps,
                        }}
                        className={`doct-input${value ? ' doct-input-focused' : ''}${
                            readOnly ? ' doct-read-only-input' : ''
                        }${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}${
                            label ? ' ' : ' none-doct-input-label'
                        }`}
                        id={id}
                        name={name}
                        label={label}
                        variant={variant}
                        disabled={disabled}
                    />
                )}
                defaultValue={defaultValue}
                name={name}
                id={id}
                control={control}
                {...rest}
            />
        </>
    );
}
