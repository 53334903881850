import React, { useState, ReactElement, useRef, useEffect } from 'react';
import { PropsAccordion, PropsBody } from '../interface';

export function AccordionBody({
    index,
    indexOfExpandedBox,
    setIndexOfExpandedBox,
    header,
    children,
    loading,
    category,
    onAccordionSwitch,
    controlledAccordion,
}: PropsBody): ReactElement {
    const [expandedSteps, setExpandedSteps] = useState([]);

    const [toggleHight, setToggleHight] = useState(false);

    const [height, setHeight] = useState(null);
    const ref = useRef(null);

    const isExpanded = indexOfExpandedBox == index;

    useEffect(() => {
        let timeOutToHeightChangeToIntial;
        if (timeOutToHeightChangeToIntial) {
            clearTimeout(timeOutToHeightChangeToIntial);
        }
        if (isExpanded && ref?.current) {
            setHeight(ref?.current?.offsetHeight);
            timeOutToHeightChangeToIntial = setTimeout(() => {
                setHeight('initial');
            }, 500);
        } else {
            setHeight(0);
        }
        return () => {
            clearTimeout(timeOutToHeightChangeToIntial);
        };
    }, [ref?.current, indexOfExpandedBox, toggleHight, loading]);

    const handleExpandedSteps = () => {
        if (!expandedSteps?.includes(indexOfExpandedBox)) {
            const newArray = [...expandedSteps, indexOfExpandedBox];
            setExpandedSteps(newArray);
        }
    };

    const handleIndex = (index) => {
        setIndexOfExpandedBox(index);
    };

    const incrementExpandedBox = () => {
        handleExpandedSteps();
        if (!controlledAccordion) {
            handleIndex(indexOfExpandedBox + 1);
        }

        if (onAccordionSwitch) onAccordionSwitch(category, index);
    };

    const accordionHeaderClickHandler = (index) => {
        handleExpandedSteps();
        handleIndex(index);
        onAccordionSwitch(category, index, 'header');
    };

    const actionHandler = {
        skipHandler: () => {
            incrementExpandedBox();
        },
        doneHandler: () => {
            incrementExpandedBox();
        },
    };

    const onHeaderClick = () => {
        if (index <= indexOfExpandedBox || expandedSteps.includes(index)) {
            // prevent to expand on click
            // can expand - if previous steps || already skipped
            accordionHeaderClickHandler(index);
        }
    };

    return (
        <div
            className="doct-for-me-filters-box"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {React.cloneElement(header, {
                isExpanded,
                onHeaderClick,
            })}
            <div style={{ height }} className="doct-for-me-filters-box-transition overflow-hidden">
                <div ref={ref}>
                    {React.cloneElement(children, {
                        actionHandler,
                        toggleHeight: () => {
                            setToggleHight(!toggleHight);
                        },
                        isExpanded,
                        loading,
                    })}
                </div>
            </div>
        </div>
    );
}

export function Accordion({
    children,
    indexOfExpandedBox,
    setIndexOfExpandedBox,
    controlled,
}: PropsAccordion): ReactElement {
    return (
        <>
            {React.Children.map(children, (child) => {
                if (!child) return;
                return React.cloneElement(child, {
                    controlled,
                    indexOfExpandedBox,
                    setIndexOfExpandedBox,
                });
            })}
        </>
    );
}
