import React, { ReactElement } from 'react';

import { Controller, Control, FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import TextField from '@mui/material/TextField';

import '../Form.scss';

interface Props {
    label: string;
    id: string;
    name: string;
    control: Control<Record<string, any>>;
    variant?: 'standard' | 'filled' | 'outlined';
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    touched?: DeepMap<Record<string, any>, true>;
    validationRules?: any;
    defaultValue?: any;
    showStar?: boolean;
}

export default function DoctTextArea({
    label,
    id,
    name,
    variant = 'standard',
    disabled = false,
    readOnly = false,
    className,
    control,
    isErrors,
    touched,
    defaultValue,
    validationRules,
    showStar = true,
    ...rest
}: Props): ReactElement {
    return (
        <>
            <Controller
                // touched={touched[name]}
                rules={validationRules}
                render={({ onChange, value }) => (
                    <TextField
                        onChange={onChange}
                        required={showStar && Object.keys(validationRules).length >= 1}
                        error={isErrors[name] ? true : false}
                        helperText={isErrors[name]?.message}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        defaultValue={value}
                        className={`doct-input doct-text-area${value ? ' doct-input-focused' : ''}${
                            readOnly ? ' doct-read-only-input' : ''
                        }${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}`}
                        id={id}
                        label={label}
                        variant={variant}
                        disabled={disabled}
                        multiline
                        rows={2}
                        maxRows={4}
                    />
                )}
                defaultValue={defaultValue}
                name={name}
                control={control}
                {...rest}
            />
        </>
    );
}
