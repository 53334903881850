import { DoctButton, DoctContainer, DoctIcon, DoctTypography } from 'doct-core';
import React from 'react';
import { useOuterClick } from '../../AppBar/DoctAppBarDropdown';

import {
    BaseNavigationMenuProps,
    CompanyInfoBoxProps,
    FeatureBoxResponsiveProps,
    HeadingAndContentProps,
    LayoutNavigationMenuProps,
    LinkItemProps,
    NavigationItemColumnProps,
    NavigationMenuContainerProps,
    SharePlayAppStoreProps,
    EnterpriseLoginInfoProps,
} from '../interfaces';

import '../DoctNavigationMenu.scss';
import { EnterpriseLogo } from '../EnterpriseLogo';
import { Playstore } from './Playstore';
import { AppleSVG } from './AppleSVG';

export const SharePlayAppStore: React.FC<SharePlayAppStoreProps> = ({
    classNamePlayStoreLinkMargin = 'ml-auto ml-sm-4 ml-md-auto',
}) => {
    return (
        <>
            <a
                href="https://play.google.com/store/apps/details?id=com.docthub.app"
                className={`${classNamePlayStoreLinkMargin} reset-line-height`}
            >
                <DoctIcon width="44" height="44" name="playStoreRound" />
            </a>
            <a
                href="https://apps.apple.com/in/app/docthub-health-career-more/id1625281489"
                className="ml-2 reset-line-height"
            >
                <DoctIcon width="44" height="44" name="appStoreRound" />
            </a>
        </>
    );
};

export const EnterpriseLoginInfo: React.FC<EnterpriseLoginInfoProps> = ({ className }) => {
    return (
        <div
            className={`navigation-menu-company-box d-flex flex-column justify-content-center align-items-center ${className}`}
        >
            <EnterpriseLogo />

            <DoctTypography variant="subtitle2" fontWeight="bold" className="text-primary-700 my-0 mt-5 mb-3">
                Already a user?
            </DoctTypography>
            <a href={`${window?.DoctUrls?.doct_enterprise_url}/dashboard`}>
                <div
                    className="text-center text-primary-800 w-100 p-2 font-weight-bold"
                    style={{ border: '2px solid #01505F', borderRadius: '22px' }}
                >
                    <div className="cursor-pointer">Enterprise Login &gt;</div>
                </div>
            </a>
            <DoctTypography variant="subtitle2" fontWeight="bold" className="text-primary-700 my-0 mt-5">
                New user?
            </DoctTypography>
            <a href={`${window?.DoctUrls?.doct_enterprise_account_url}`}>
                <DoctButton text="Create Enterprise Account" type="semantic-info" size="medium" className="mt-3" />
            </a>
            <div style={{ fontSize: '11px', width: '276px' }} className="text-grey-500 text-center mt-4 my-0">
                Docthub Enterprise account lets you Post jobs, List courses, Create events, Manage Associations, Campus
                placements, E-certifications, All in one business account.
            </div>
        </div>
    );
};

export const CompanyInfoBox: React.FC<CompanyInfoBoxProps> = ({ className, branding }) => {
    const width = typeof window !== 'undefined' ? window.innerWidth : undefined;
    return (
        <div className={`navigation-menu-company-box d-flex flex-column ${className}`}>
            <DoctTypography variant="subtitle1" fontWeight="bold" className="my-0">
                Company
            </DoctTypography>
            <div className="d-flex flex-column flex-sm-row flex-md-column">
                {window?.DoctUrls?.doct_brand_url && (
                    <a href={`${window?.DoctUrls.doct_brand_url}/about-us`}>
                        <DoctTypography
                            variant="subtitle2"
                            className={`mt-4 mb-0 text-primary font-weight-medium pl-sm-3 pl-md-0 mb-3 mb-md-0`}
                        >
                            {`About us>`}
                        </DoctTypography>
                    </a>
                )}
                {window?.DoctUrls?.doct_brand_url && (
                    <a href={`${window?.DoctUrls.doct_brand_url}/media-releases`}>
                        <DoctTypography
                            variant="subtitle2"
                            className={`mb-0 text-primary font-weight-medium pr-sm-5 pr-md-0 mb-sm-4 mb-md-0`}
                        >
                            {`Media Releases>`}
                        </DoctTypography>
                    </a>
                )}
                {/* {!branding && window?.DoctUrls?.doct_brand_url && (
                    <a href={window.DoctUrls.doct_brand_url}>
                        <DoctTypography
                            variant="subtitle2"
                            className={`mt-4 mb-0 text-primary font-weight-medium pr-sm-5 pr-md-0 mb-sm-4 mb-md-0`}
                        >
                            {`Docthub.com>`}
                        </DoctTypography>
                    </a>
                )} */}
                {window?.DoctUrls?.doct_brand_url && (
                    <a href={`${window?.DoctUrls.doct_brand_url}/contact-us`}>
                        <DoctTypography
                            variant="subtitle2"
                            className={`mb-0 text-primary font-weight-medium pr-sm-5 pr-md-0 mb-sm-4 mb-md-0`}
                        >
                            {`Contact us>`}
                        </DoctTypography>
                    </a>
                )}
            </div>
            <div className={`mt-2 mt-md-auto`} />
            <div
                className={`d-flex align-items-center navigation-menu-company-box-download-box justify-content-sm-center`}
            >
                <div>
                    <DoctTypography
                        variant={width > 1279 ? 'subtitle2' : 'textLabel2'}
                        className=""
                        fontWeight="medium"
                    >
                        Docthub Career App
                    </DoctTypography>
                    <div className="d-flex">
                        <span className="mb-1 mr-2 mr-sm-0">
                            <a
                                target="_blank"
                                href="https://play.google.com/store/search?q=docthub&c=apps&hl=en"
                                rel="noreferrer"
                            >
                                <Playstore />
                            </a>
                        </span>
                        <a
                            target="_blank"
                            href="https://apps.apple.com/in/app/docthub-healthcare-career-app/id1625281489"
                            rel="noreferrer"
                            className="ml-2"
                        >
                            <AppleSVG />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const EntperiseNavigation: React.FC = () => {
    return (
        <div className="d-flex">
            <LinkItem
                href={window?.DoctUrls?.doct_membership_url}
                title="Membership Management"
                description="Association Management Solutions"
                iconName="membershipOutline"
                className="mb-md-0"
            />
            {/* <LinkItemOutLine /> */}
        </div>
    );
};

export const NavigationItemColumn: React.FC<NavigationItemColumnProps> = ({ tiny, children, className }) => {
    return (
        <div
            className={`navigation-menu-link-column ${
                tiny ? 'navigation-menu-link-column-tiny' : ''
            } w-100 ${className}`}
        >
            {children}
        </div>
    );
};

export const FeatureBoxResponsive: React.FC<FeatureBoxResponsiveProps> = ({
    href,
    title,
    iconName,
    className,
    membership,
}) => {
    return (
        <a
            href={href}
            className={`feature-box d-inline-flex justify-content-center align-items-center flex-column ${
                membership ? 'ml-3' : ''
            } ${className}`}
        >
            <DoctIcon width={'32'} height={'32'} name={iconName} />
            <DoctTypography
                variant="textLabel1"
                className="navigation-menu-link-item-text-responsive mb-0 text-grey-800 font-weight-semibold"
            >
                {title}
            </DoctTypography>
        </a>
    );
};

export const LinkItemOutLine: React.FC = () => {
    return (
        <a
            href={`${window?.DoctUrls?.doct_enterprise_url}/dashboard`}
            className={
                'navigation-menu-link-item navigation-menu-link-item-outline navigation-menu-btn-primary700 d-flex align-items-center cursor-pointer'
            }
        >
            <DoctTypography variant="textLabel2" className="text-grey-600">
                Go to your Enterprise Dashboard
            </DoctTypography>
            <DoctButton text="Enterprise Login >" size="medium" />
        </a>
    );
};

export const LinkItem: React.FC<LinkItemProps> = ({ href, title, description, iconName, tiny, className }) => {
    const width = typeof window !== 'undefined' ? window.innerWidth : undefined;

    return (
        <a
            href={href}
            className={`navigation-menu-link-item ${
                tiny ? 'navigation-menu-link-item-tiny' : ''
            } mb-sm-1 d-flex align-items-center flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-center ${className}`}
        >
            <DoctIcon width={width < 768 ? '32' : '44'} height={width < 768 ? '32' : '44'} name={iconName} />
            <div className={`navigation-menu-link-item-text`}>
                <DoctTypography
                    variant={width < 768 ? 'textLabel1' : 'subtitle2'}
                    className={`my-0 text-grey-800 font-weight-semibold`}
                >
                    {title}
                </DoctTypography>
                {description && (
                    <DoctTypography variant="subtitle3" className="mb-0 mt-1 text-grey-500 d-none d-sm-block">
                        {description}
                    </DoctTypography>
                )}
            </div>
        </a>
    );
};

export const HeadingAndContent: React.FC<HeadingAndContentProps> = ({
    heading,
    children,
    isBrandingView,
    className,
}) => {
    const width = typeof window !== 'undefined' ? window.innerWidth : undefined;

    return (
        <>
            <DoctTypography
                variant={width < 768 && !isBrandingView ? 'textLabel1' : 'subtitle1'}
                className={`mb-3 mt-0 pl-sm-3 ${className} ${
                    isBrandingView
                        ? 'navigation-menu-responsive-heading-branding-variant'
                        : 'navigation-menu-responsive-heading'
                } `}
                fontWeight="bold"
            >
                {heading}
            </DoctTypography>
            {children}
        </>
    );
};

export const LayoutNavigationMenu: React.FC<LayoutNavigationMenuProps> = ({ children, branding }) => {
    return (
        <>
            <div className={`navigation-menu-product-box d-flex w-100 flex-column flex-sm-row`}>{children}</div>
            <CompanyInfoBox branding={branding} />
        </>
    );
};

export const LayoutNavigationMenuBranding: React.FC<LayoutNavigationMenuProps> = ({ children }) => {
    return (
        <>
            <div className="navigation-menu-product-box d-flex w-100 flex-row flex-sm-column">{children}</div>
            <EnterpriseLoginInfo />
        </>
    );
};

export const ProductGridLayoutBrandingPage: React.FC = ({ children }) => {
    return <div className={`navigation-menu-enterprise-feature`}>{children}</div>;
};

export const BaseNavigationMenu: React.FC<BaseNavigationMenuProps> = ({ children, onClosePressed, setMenuOpen }) => {
    const innerRef = useOuterClick((e) => {
        onClosePressed(e);
        if (setMenuOpen) {
            setMenuOpen(false);
        }
    });
    return (
        <DoctContainer>
            <div
                className={`navigation-menu-base bg-white overflow-hidden d-flex flex-column flex-md-row position-relative`}
                ref={innerRef}
            >
                {children}
            </div>
        </DoctContainer>
    );
};

export const NavigationMenuContainer: React.FC<NavigationMenuContainerProps> = ({
    className,
    children,
    adjustSpacing,
}) => {
    return (
        <div className={`navigation-menu ${className}`} style={{ paddingRight: `${adjustSpacing || 0}px` }}>
            {children}
        </div>
    );
};
