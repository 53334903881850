export enum Variant {
    Subtitle1 = 'subtitle1',
    Subtitle2 = 'subtitle2',
    Subtitle3 = 'subtitle3',
    Body1 = 'body1',
    Body2 = 'body2',
    Body3 = 'body3',
    TextLabel1 = 'textLabel1',
    TextLabel2 = 'textLabel2',
    Caption1 = 'caption1',
    Caption2 = 'caption2',
    Input = 'input',
    List = 'list',
    Dropdown = 'dropdown',
    ResultCounter = 'result-counter',
    Overline = 'overline',
    Link1 = 'link1',
    Link2 = 'link2',
    TabItem = 'tabItem',
}

export enum TextTransformVariant {
    Uppercase = 'uppercase',
    Normal = 'normal',
    Capitalize = 'capitalize',
}

export enum FontWeightVariant {
    Regular = 'regular',
    Medium = 'medium',
    Bold = 'bold',
}
