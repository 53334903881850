import { DoctIcon, DoctTypography } from 'doct-core';
import React from 'react';
import {
    BaseNavigationMenu,
    HeadingAndContent,
    LayoutNavigationMenu,
    LinkItem,
    NavigationItemColumn,
    NavigationMenuContainer,
} from './Components/Components';
import {
    CourseOutlineIcon,
    EventsOutlineIcon,
    JobOutlineIcon,
    LibraryIcon,
    LogbookIcon,
    ResumeBuilderIcon,
} from '../TopBar/Branding/Icons';

interface DoctNavigationMenuUserAppBrandingProps {
    onClosePressed: any;
    setMenuOpen?: any;
    branding?: boolean;
    adjustSpacing?: number;
}

interface UserAppNavigationPropsProps {
    branding?: boolean;
}

export const UserAppNavigation: React.FC<UserAppNavigationPropsProps> = ({ branding }) => {
    const linkItemsOffering =
        typeof window !== 'undefined'
            ? [
                  {
                      imgSRC: <JobOutlineIcon />,
                      title: 'Jobs',
                      description: 'Search for Healthcare Jobs.',
                      href: `${window?.DoctUrls.doct_jobs_url}`,
                  },
                  {
                      imgSRC: <CourseOutlineIcon />,
                      title: 'Courses',
                      description: 'Find healthcare institutes',
                      href: `${window?.DoctUrls.doct_course_url}`,
                  },
                  {
                      imgSRC: <EventsOutlineIcon />,
                      title: 'Events',
                      description: 'Conferences, Workshops & more.',
                      href: `${window?.DoctUrls.doct_event_url}`,
                  },
              ]
            : [];
    const toolsItems =
        typeof window !== 'undefined'
            ? [
                  {
                      imgSRC: <LogbookIcon />,
                      title: 'Logbook',
                      href: `${window?.DoctUrls.doct_log_book_url}`,
                  },
                  {
                      imgSRC: <ResumeBuilderIcon />,
                      title: 'Create Resume',
                      href: `${window?.DoctUrls.doct_resume_url}`,
                  },
              ]
            : [];
    return (
        <>
            <NavigationItemColumn className="col-5">
                <HeadingAndContent heading={branding ? 'Our offerings' : 'Go to'}>
                    {linkItemsOffering.map((item, index) => (
                        <a
                            href={item?.href}
                            key={index}
                            className={`navigation-menu-link-item mb-sm-1 d-flex align-items-center flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-center`}
                        >
                            {item?.imgSRC}
                            <div className={`navigation-menu-link-item-text`}>
                                <DoctTypography
                                    variant="subtitle1"
                                    className={`my-0 text-grey-800 font-weight-semibold`}
                                >
                                    {item?.title}
                                </DoctTypography>
                                {item?.description && (
                                    <DoctTypography
                                        variant="subtitle3"
                                        className="mb-0 mt-1 text-grey-500 d-none d-sm-block"
                                    >
                                        {item?.description}
                                    </DoctTypography>
                                )}
                            </div>
                        </a>
                    ))}
                </HeadingAndContent>
                <div
                    className={`navigation-menu-divider-horizontal navigation-menu-divider-horizontal-gap-midium navigation-menu-divider d-sm-none w-100`}
                />
            </NavigationItemColumn>
            <NavigationItemColumn>
                <div className="d-flex flex-sm-column align-items-stretch h-100 w-100 ">
                    <div className={'navigation-menu-tools'}>
                        <HeadingAndContent heading="Tools">
                            {/* {window?.DoctUrls?.doct_log_book_url && (
                                <LinkItem
                                    imgSRC="logbookOutline"
                                    title="Logbook"
                                    href={window.DoctUrls.doct_log_book_url}
                                />
                            )}
                            {window?.DoctUrls?.doct_log_book_url && (
                                <LinkItem
                                    imgSRC="logbookOutline"
                                    title="Create Resume"
                                    href={window.DoctUrls.doct_log_book_url}
                                />
                            )} */}
                            {toolsItems.map((item, index) => (
                                <a
                                    href={item?.href}
                                    key={index}
                                    className={`navigation-menu-link-item mb-sm-1 d-flex align-items-center flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-center`}
                                >
                                    {item?.imgSRC}
                                    <div className={`navigation-menu-link-item-text`}>
                                        <DoctTypography
                                            variant="subtitle2"
                                            className={`my-0 text-grey-800 font-weight-semibold`}
                                        >
                                            {item?.title}
                                        </DoctTypography>
                                    </div>
                                </a>
                            ))}
                        </HeadingAndContent>
                    </div>
                </div>
            </NavigationItemColumn>
            <NavigationItemColumn>
                <div className={`navigation-menu-knowledge`}>
                    {/* <HeadingAndContent heading="Knowledge">
                        {window?.DoctUrls?.doct_blogs_url && (
                            <LinkItem
                                imgSRC="blogsIconOutline"
                                title="Library"
                                href={window.DoctUrls.doct_blogs_url}
                                tiny
                            />
                        )}
                        {window?.DoctUrls?.doct_blogs_url && (
                            <LinkItem
                                imgSRC="blogsIconOutline"
                                title="Course Pages"
                                href={window.DoctUrls.doct_blogs_url}
                                tiny
                            />
                        )}
                        {window?.DoctUrls?.doct_blogs_url && (
                            <LinkItem
                                imgSRC="blogsIconOutline"
                                title="Job Role Pages"
                                href={window.DoctUrls.doct_blogs_url}
                                tiny
                            />
                        )}
                        {window?.DoctUrls?.doct_blogs_url && (
                            <LinkItem
                                imgSRC="blogsIconOutline"
                                title="Blogs"
                                href={window.DoctUrls.doct_blogs_url}
                                tiny
                            />
                        )}
                        {window?.DoctUrls?.doct_news_url && (
                            <LinkItem imgSRC="newsOutline" title="News" href={window.DoctUrls.doct_news_url} tiny />
                    )}
                    </HeadingAndContent> */}
                    <HeadingAndContent heading="Knowledge">
                        <div className="ml-3 pt-1">
                            {typeof window !== 'undefined' && window?.DoctUrls?.doct_library_url && (
                                <a
                                    href={window?.DoctUrls?.doct_library_url}
                                    className="text-decoration-none text-reset"
                                >
                                    <DoctTypography
                                        variant="subtitle2"
                                        className="d-flex align-items-center cursor-pointer"
                                    >
                                        <LibraryIcon />
                                        <span className="ml-2">Library</span>
                                    </DoctTypography>
                                </a>
                            )}
                            {/* <DoctTypography variant="subtitle2" className="d-flex align-items-center">
                                <DoctIcon name="newsOutline" width="32" height="32" className="mr-2" />
                                Course Pages
                            </DoctTypography>
                            <DoctTypography variant="subtitle2" className="d-flex align-items-center">
                                <DoctIcon name="blogsIconOutline" width="32" height="32" className="mr-2" />
                                Job Role Pages
                            </DoctTypography> */}

                            {typeof window !== 'undefined' && window.DoctUrls.doct_blogs_url && (
                                <a href={window.DoctUrls.doct_blogs_url} className="text-decoration-none text-black">
                                    <DoctTypography
                                        variant="subtitle2"
                                        className="d-flex align-items-center cursor-pointer"
                                    >
                                        <DoctIcon name="blogsIconOutline" width="32" height="32" className="mr-2" />
                                        Blogs
                                    </DoctTypography>
                                </a>
                            )}
                            {typeof window !== 'undefined' && window?.DoctUrls?.doct_news_url && (
                                <a href={window?.DoctUrls?.doct_news_url} className="text-decoration-none text-black">
                                    <DoctTypography
                                        variant="subtitle2"
                                        className="d-flex align-items-center cursor-pointer"
                                    >
                                        <DoctIcon name="newsOutline" width="32" height="32" className="mr-2" />
                                        News
                                    </DoctTypography>
                                </a>
                            )}
                        </div>
                    </HeadingAndContent>
                </div>
            </NavigationItemColumn>
        </>
    );
};

const DoctNavigationMenuUserAppUserApp: React.FC<DoctNavigationMenuUserAppBrandingProps> = ({
    onClosePressed,
    setMenuOpen,
    branding,
    adjustSpacing,
}) => {
    return (
        <NavigationMenuContainer adjustSpacing={adjustSpacing}>
            <BaseNavigationMenu onClosePressed={onClosePressed} setMenuOpen={setMenuOpen}>
                <LayoutNavigationMenu branding={branding}>
                    <span
                        className={`position-absolute navigation-menu-close d-sm-none`}
                        onClick={(e) => {
                            onClosePressed(e);
                            setMenuOpen(false);
                        }}
                    >
                        <DoctIcon name="close" width="24" height="24" className="text-grey-600" />
                    </span>
                    <UserAppNavigation branding={branding} />
                </LayoutNavigationMenu>
            </BaseNavigationMenu>
        </NavigationMenuContainer>
    );
};

export default DoctNavigationMenuUserAppUserApp;
