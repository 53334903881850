import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import './doctusermenu.scss';
import ListItems from './ListItems';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import SideBarProfile from './SideBarProfile';

interface IDoctUserMenu {
    open: boolean;
    image: string;
    name: string;
    professionalTitle: string;
    handleLogout: () => void;
    onClose: any;
    anchorEl: any;
}

export default function DoctUserMenu({
    onClose,
    open,
    image,
    name,
    professionalTitle,
    handleLogout,
    anchorEl,
}: IDoctUserMenu): React.ReactElement {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={onClose}
            className="doct-user-menu-padding"
            PaperProps={{
                elevation: 0,
                sx: {
                    boxShadow: '0px 4px 2px #00000014;',
                    border: '1px solid #00000014',
                    borderRadius: '8px',
                    mt: 1.5,
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <SideBarProfile name={name} image={image} professionalTitle={professionalTitle} />
            <Box role="presentation" onKeyDown={onClose} className="doct-user-menu-container">
                <List>
                    <ListItems handleLogout={handleLogout} />
                </List>
            </Box>
        </Menu>
    );
}
