import React, { ReactElement } from 'react';

import { Controller, Control, FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import './FormCheckbox.scss';

interface Props {
    label: string;
    id: string;
    name: string;
    control: Control<Record<string, any>>;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    // touched?: DeepMap<Record<string, any>, true>;
    validationRules?: any;
    defaultValue?: boolean;
    indeterminate?: boolean;
}

export default function DoctFormCheckbox({
    label = 'Checkbox',
    id,
    name,
    disabled = false,
    className,
    control,
    defaultValue = false,
    validationRules,
    indeterminate = false,
    ...rest
}: Props): ReactElement {
    return (
        <Controller
            name={name}
            id={id}
            rules={validationRules}
            control={control}
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
                <FormControlLabel
                    className={`doct-checkbox${className ? ` ${className}` : ''}`}
                    control={
                        <Checkbox
                            disabled={disabled}
                            indeterminate={indeterminate}
                            color="primary"
                            onChange={(e) => onChange(e.target.checked)}
                            checked={value}
                        />
                    }
                    label={label}
                />
            )}
            {...rest}
        />
    );
}
