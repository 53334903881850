import React, { ReactElement, useEffect, useState } from 'react';

import { DoctIcon } from 'doct-core';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Control, FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';

import match from './assets/match.js';
import { parse } from './helperFunction';

import { InputAdornment } from '@mui/material';

import './AutoCompleteWithHighLightedSearch.scss';
import '../AutoComplete/AutoComplete.scss';

interface Props {
    label?: string;
    id: string;
    name: string;
    control?: Control<Record<string, any>>;
    variant?: 'standard' | 'filled' | 'outlined';
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    touched?: DeepMap<Record<string, any>, true>;
    validationRules?: any;
    defaultValue?: any;
    showStar?: boolean;
    options: any[];
    showExtraLabel?: string;
    disableClearable?: boolean;
    optionLabel?: string;
    placeholder?: string;
    onEndScroll?: any;
    loading?: boolean;
    onClearInput?: () => void;
    onChangeHandler?: any;
    freeSolo?: boolean;
    onInputChangeHandler?: (val) => void;
    inputValue?: string;
    value?: any;
    hideSearchIcon?: boolean;
    disableOnEnterFormSubmit?: boolean;
    onEnterPressed?: (e) => void;
    noRadial?: boolean;
    inverseBackground?: boolean;
    bgWhite?: boolean;
    onFocus?: () => void;
}

export default function DoctAutoCompleteWithHighLightedSearch({
    label,
    id,
    name,
    variant = 'standard',
    disabled = false,
    readOnly = false,
    className,
    control,
    isErrors,
    touched,
    defaultValue,
    validationRules,
    showStar = false,
    options,
    disableClearable = false,
    optionLabel = 'label',
    placeholder,
    onClearInput,
    onEndScroll,
    loading = false,
    onChangeHandler = () => null,
    freeSolo,
    onInputChangeHandler,
    inputValue,
    value,
    hideSearchIcon,
    disableOnEnterFormSubmit,
    onEnterPressed = (e) => null,
    inverseBackground = true,
    noRadial = true,
    bgWhite,
    onFocus = () => null,
    ...rest
}: Props): ReactElement {
    // Checking if label is not passed as a key then throwing an error;
    const isLabelAvailable = options && options.every((obj) => obj.hasOwnProperty('label'));
    if (!isLabelAvailable) throw new Error('Properties name should be label');

    const filterOptions = createFilterOptions({
        stringify: (option: { label: string }) => option.label,
    });

    const [open, setOpen] = useState(false);

    const [classNameState, setClassNameState] = useState([]);

    useEffect(() => {
        const classNameList = ['doct-input', 'controlled-search', 'search-input', 'with-custom-close'];

        if (inverseBackground && !bgWhite) {
            classNameList.push('search-input-grey');
        }

        if (noRadial) {
            classNameList.push('no-radial');
        }

        if (bgWhite) {
            classNameList.push('search-input-white');
        }

        setClassNameState(classNameList);
    }, [inverseBackground]);

    return (
        // Adding readonly class to set custom style
        <div className={classNameState.join(' ')}>
            <Autocomplete
                freeSolo={freeSolo}
                id={id}
                disableClearable={disableClearable}
                disabled={disabled}
                filterOptions={filterOptions}
                options={[...options]}
                onOpen={() => setOpen(!open)}
                onClose={() => setOpen(!open)}
                open={open}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.label, inputValue);
                    const parts = parse(option.label, matches);
                    return (
                        <li {...props} className={`${props?.className} custom-dropdown-option w-100`}>
                            {parts.map((part: any, index: any) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 900 : 400,
                                    }}
                                >
                                    {part.text.replace(/ /g, '\u00A0')}
                                </span>
                            ))}
                        </li>
                    );
                }}
                value={value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onFocus={onFocus}
                        placeholder={placeholder}
                        className={`doct-input${value ? ' doct-input-focused' : ''}${
                            readOnly ? ' doct-read-only-input' : ''
                        }${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}`}
                        required={showStar && Object.keys(validationRules).length >= 1}
                        label={label}
                        variant={variant}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: hideSearchIcon ? null : (
                                <InputAdornment position="start">
                                    <DoctIcon name="search" className="text-grey-600" width="24" height="24" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '.MuiInput-root': {
                                '.MuiAutocomplete-endAdornment': {
                                    top: 'auto !important',
                                    transform: 'translate(0px, 0px) !important',
                                },
                            },
                        }}
                    />
                )}
                onChange={(event: any, newValue: any) => {
                    if (onChangeHandler) {
                        onChangeHandler(newValue);
                    }
                }}
                loading={loading}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    if (!!!inputValue && newInputValue == ' ') {
                        return;
                    }
                    onInputChangeHandler(newInputValue);
                }}
                clearIcon={
                    <div className="cutsom-close-icon" onClick={onClearInput}>
                        <DoctIcon name="close" width="24" height="24" className="text-grey-600" />
                    </div>
                }
                {...rest}
            />
        </div>
    );
}
