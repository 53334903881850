import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import {
    BlogsIcon,
    CourseOutlineIcon,
    EventsOutlineIcon,
    JobOutlineIcon,
    LibraryIcon,
    LogbookIcon,
    ResumeBuilderIcon,
} from '../../TopBar/Branding/Icons';
import { DoctButton, DoctIcon, DoctTypography } from 'doct-core';
import { AppleSVG } from './AppleSVG';
import { Playstore } from './Playstore';

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    border: '1px solid #E0E0E0',
    borderRadius: '16px',
    marginBottom: theme.spacing(1),
    boxShadow: 'none',
    width: '100%',
    overflow: 'hidden',
    '&:first-of-type': {
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
    },
    '&:last-of-type': {
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
    },
    '&.Mui-expanded': {
        borderRadius: '16px',
        margin: '0',
        marginBottom: '10px',
    },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: '#54cae217',
    padding: '10px',
    minHeight: '54px',
    height: '54px',
    borderRadius: '16px',
    '&.Mui-expanded': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0, // Remove the bottom corners when expanded
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    borderRadius: '0 0 16px 16px',
    '&.Mui-expanded': {
        borderTopLeftRadius: '16px', // Round top-left corner
        borderTopRightRadius: '16px', // Round top-right corner
    },
}));

const CloseButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px',
});
const Section = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: '16px',
}));

const IconContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
});
interface AppsProps {
    showCloseIcon?: boolean;
    isWhiteBox?: boolean;
}
const Apps: React.FC<AppsProps> = ({ showCloseIcon, isWhiteBox }) => {
    // Control menu visibility
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [menuOpen, setMenuOpen] = useState(true);
    if (!menuOpen) return null;

    const productList =
        typeof window !== 'undefined'
            ? [
                  {
                      href: window?.DoctUrls?.doct_recruiters_url,
                      title: 'Post a Job',
                      iconName: 'jobsEnterpriseFilled',
                  },
                  {
                      href: window?.DoctUrls?.doct_institutes_url,
                      title: 'List a Course',
                      iconName: 'courseEnterpriseFilled',
                  },
                  {
                      href: window?.DoctUrls?.doct_event_organizers,
                      title: 'Create an Event',
                      iconName: 'eventEnterpriseFilled',
                  },
                  {
                      href: `${window?.DoctUrls?.doct_membership_url}`,
                      title: 'Membership Management',
                      iconName: 'membershipOutline',
                  },
                  {
                      href: window?.DoctUrls?.doct_enterprise_solutions_url,
                      title: 'What is Docthub Enterprise?',
                      iconName: 'enterpriseFilled',
                  },
              ]
            : [];

    return (
        <Box
            sx={{
                ...(isWhiteBox && {
                    margin: 'auto',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '24px',
                    border: '1px solid #0000001A',
                    padding: '16px',
                    position: 'relative',
                }),
            }}
        >
            {showCloseIcon && (
                <CloseButtonContainer className="mt-n2 mr-n2">
                    <IconButton onClick={() => setMenuOpen(false)} aria-label="close" className="mt-n2">
                        <CloseIcon />
                    </IconButton>
                </CloseButtonContainer>
            )}

            {/* Accordion */}
            <CustomAccordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <DoctTypography variant="subtitle1" fontWeight="bold" className="px-2">
                        Go to
                    </DoctTypography>
                </CustomAccordionSummary>
                <CustomAccordionDetails className="ml-2">
                    <Section>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <DoctTypography variant="subtitle2" className="text-grey-600">
                                    Our offerings
                                </DoctTypography>
                                <a href={`${window?.DoctUrls?.doct_jobs_url}`}>
                                    <IconContainer>
                                        <JobOutlineIcon width={32} height={32} />

                                        <DoctTypography variant="body2" fontWeight="medium" className="text-grey-800">
                                            Jobs
                                        </DoctTypography>
                                    </IconContainer>
                                </a>
                                <a href={`${window?.DoctUrls?.doct_course_url}`}>
                                    <IconContainer>
                                        <CourseOutlineIcon width={32} height={32} />

                                        <DoctTypography variant="body2" fontWeight="medium" className="text-grey-800">
                                            Courses
                                        </DoctTypography>
                                    </IconContainer>
                                </a>
                                <a href={`${window?.DoctUrls?.doct_event_url}`}>
                                    <IconContainer>
                                        <EventsOutlineIcon width={32} height={32} />

                                        <DoctTypography variant="body2" fontWeight="medium" className="text-grey-800">
                                            Events
                                        </DoctTypography>
                                    </IconContainer>
                                </a>
                            </Grid>

                            <Grid item xs={6} spacing={1}>
                                <DoctTypography variant="subtitle2" className="text-grey-600">
                                    Tools
                                </DoctTypography>
                                <a href={`${window?.DoctUrls?.doct_log_book_url}`}>
                                    <IconContainer>
                                        <LogbookIcon width={32} height={32} />

                                        <DoctTypography variant="body2" fontWeight="medium" className="text-grey-800">
                                            Logbook
                                        </DoctTypography>
                                    </IconContainer>
                                </a>
                                <a href={`${window?.DoctUrls?.doct_resume_url}`}>
                                    <IconContainer>
                                        <ResumeBuilderIcon width={32} height={32} />

                                        <DoctTypography variant="body2" fontWeight="medium" className="text-grey-800">
                                            Create Resume
                                        </DoctTypography>
                                    </IconContainer>
                                </a>
                            </Grid>
                        </Grid>
                    </Section>

                    <Section className="mt-4">
                        <DoctTypography variant="subtitle2" className="text-grey-600">
                            Knowledge
                        </DoctTypography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <a href={`${window?.DoctUrls?.doct_blogs_url}`}>
                                    <IconContainer className="ml-n1">
                                        <BlogsIcon />
                                        <DoctTypography variant="body2" fontWeight="medium" className="text-grey-800">
                                            Blogs
                                        </DoctTypography>
                                    </IconContainer>
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href={`${window?.DoctUrls?.doct_news_url}`}>
                                    <IconContainer>
                                        <LibraryIcon width={32} height={32} />
                                        <DoctTypography variant="body2" fontWeight="medium" className="text-grey-800">
                                            News
                                        </DoctTypography>
                                    </IconContainer>
                                </a>
                            </Grid>
                        </Grid>
                    </Section>
                </CustomAccordionDetails>
            </CustomAccordion>

            {/* Second Accordion */}
            <CustomAccordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <DoctTypography variant="subtitle1" fontWeight="bold" className="px-2">
                        For Organizations
                    </DoctTypography>
                </CustomAccordionSummary>
                <CustomAccordionDetails className="ml-2 mt-3">
                    {productList.map((item, index) => (
                        <a
                            href={item?.href}
                            key={index}
                            className="navigation-menu-link-item mb-3 d-flex align-items-center align-items-center"
                        >
                            <DoctIcon width="32" height="32" name={item?.iconName} />
                            <div className="navigation-menu-link-item-text ml-2">
                                <DoctTypography variant="subtitle2" className="my-0 text-grey-800 font-weight-semibold">
                                    {item?.title}
                                </DoctTypography>
                            </div>
                        </a>
                    ))}
                    <div
                        style={{ backgroundColor: 'rgb(67 137 255 / 6%)', borderRadius: '20px' }}
                        className="p-2 mt-4 ml-n2"
                    >
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <DoctTypography
                                variant="subtitle2"
                                fontWeight="bold"
                                className="ml-3 text-primary-700 my-0"
                            >
                                Already a user?
                            </DoctTypography>

                            <div
                                className="text-center text-primary-800 p-2 mr-3 font-weight-bold"
                                style={{ border: '2px solid #01505F', borderRadius: '22px' }}
                            >
                                <div className="cursor-pointer">
                                    <a
                                        href={`${window?.DoctUrls?.doct_enterprise_url}/dashboard`}
                                        className="text-primary-700"
                                    >
                                        Enterprise Login &gt;
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                            <DoctTypography
                                variant="subtitle2"
                                fontWeight="bold"
                                className="text-primary-700 my-0 ml-3"
                            >
                                New user?
                            </DoctTypography>
                            <a href={`${window?.DoctUrls?.doct_enterprise_account_url}`}>
                                <DoctButton
                                    size="medium"
                                    type="semantic-info"
                                    className="btn btn-primary px-4 py-2 mr-3"
                                    text="Create Enterprise Account"
                                />
                            </a>
                        </div>
                    </div>
                </CustomAccordionDetails>
            </CustomAccordion>

            {/* Third Accordion */}
            <CustomAccordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
                <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <DoctTypography variant="subtitle1" fontWeight="bold" className="px-2">
                        Company
                    </DoctTypography>
                </CustomAccordionSummary>
                <CustomAccordionDetails className="ml-2">
                    <div className="d-flex flex-column flex-md-column">
                        {typeof window !== 'undefined' && window?.DoctUrls?.doct_brand_url && (
                            <a href={`${window?.DoctUrls?.doct_brand_url}/about-us`}>
                                <DoctTypography
                                    variant="subtitle2"
                                    className="mb-0 text-primary font-weight-medium pl-md-0 mb-md-0"
                                >
                                    About us &gt;
                                </DoctTypography>
                            </a>
                        )}
                        {typeof window !== 'undefined' && window?.DoctUrls?.doct_brand_url && (
                            <a href={`${window?.DoctUrls?.doct_brand_url}/media-releases`}>
                                <DoctTypography
                                    variant="subtitle2"
                                    className="mb-0 text-primary font-weight-medium pr-sm-5 pr-md-0 mb-md-0"
                                >
                                    Media Releases &gt;
                                </DoctTypography>
                            </a>
                        )}
                        {typeof window !== 'undefined' && window?.DoctUrls?.doct_brand_url && (
                            <a href={`${window?.DoctUrls?.doct_brand_url}/contact-us`}>
                                <DoctTypography
                                    variant="subtitle2"
                                    className="mb-0 text-primary font-weight-medium pr-sm-5 pr-md-0 mb-sm-4 mb-md-0"
                                >
                                    Contact us &gt;
                                </DoctTypography>
                            </a>
                        )}
                    </div>
                    <div className="d-flex align-items-center navigation-menu-company-box-download-box justify-content-md-center">
                        <div>
                            <DoctTypography variant={'subtitle2'} fontWeight="medium">
                                Docthub Career App
                            </DoctTypography>
                            <div className="d-flex">
                                <span className="mb-1 mr-2 mr-sm-0">
                                    <a
                                        target="_blank"
                                        href="https://play.google.com/store/search?q=docthub&c=apps&hl=en"
                                        rel="noreferrer"
                                    >
                                        <Playstore />
                                    </a>
                                </span>
                                <a
                                    target="_blank"
                                    href="https://apps.apple.com/in/app/docthub-healthcare-career-app/id1625281489"
                                    rel="noreferrer"
                                    className="ml-2"
                                >
                                    <AppleSVG />
                                </a>
                            </div>
                        </div>
                    </div>
                </CustomAccordionDetails>
            </CustomAccordion>
        </Box>
    );
};

export default Apps;
