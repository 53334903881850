import React from 'react';

import { DoctTypography, DoctIcon } from 'doct-core';

import './Chip.scss';

interface ChipProps {
    icon?: string;
    title: string | any;
    showCloseIcon?: boolean;
    onCloseHandler?: () => void;
    small?: boolean;
}

const Chip: React.FC<ChipProps> = ({ icon, title, onCloseHandler, showCloseIcon = true, small = false }) => {
    return (
        <div className={`doct-chip ${small ? 'doct-chip-small' : ''}`}>
            {icon && <DoctIcon name={icon} width="24" className="text-grey-600 chip-icon" />}
            <DoctTypography variant={small ? 'textLabel2' : 'input'} className="text-grey-800 my-0">
                {title}
            </DoctTypography>
            {/* <span className="doct-chip-title">Clickable deletable</span> */}
            {showCloseIcon && (
                <div className="close-button" onClick={onCloseHandler}>
                    <DoctIcon name="cancel" className="text-grey-600" width="18" />
                </div>
            )}
        </div>
    );
};

export default Chip;
