import React from 'react';

import { DoctAvatar, DoctIcon, DoctIconButton } from 'doct-core';

import { UserMenuPropTypes } from './PropTypes';

import './DoctTopBar.scss';

interface DoctUserMenuProps {
    handleCloseDrawer: () => void;
    userMenu?: UserMenuPropTypes;
}

const DoctUserMenu: React.FC<DoctUserMenuProps> = ({ handleCloseDrawer, userMenu }) => {
    const { userMenuLinks, userDetails } = userMenu || {};
    return (
        <>
            <div className="doct-user-menu">
                <div className="doct-user-information bg-white d-flex">
                    <div className="d-flex align-items-center">
                        <DoctAvatar name={userDetails !== undefined && userDetails.name?.charAt(0).toUpperCase()} />
                        <div className="doct-user-title">
                            <p className="m-0 font-weight-bolder">{userDetails?.name}</p>
                        </div>
                    </div>
                </div>
                {userMenuLinks.map((item, index) => (
                    <ul className="doct-user-links" key={index}>
                        {item.userLinkHeading && (
                            <small className="font-weight-bold user-links-heading">{item.userLinkHeading}</small>
                        )}
                        {item.links.map((menuItem, index) => (
                            <li key={index} className="d-flex justify-content-between align-items-center">
                                {menuItem.link}
                                <DoctIcon name="right" width="15px" />
                            </li>
                        ))}
                    </ul>
                ))}
            </div>
            <div className="close-btn text-center">
                <DoctIconButton onButtonClickHandler={handleCloseDrawer} icon="close" type="secondary"></DoctIconButton>
            </div>
        </>
    );
};

export default DoctUserMenu;
