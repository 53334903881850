import React from 'react';
import {
    CourseOutlineIcon,
    EventsOutlineIcon,
    JobOutlineIcon,
    LibraryIcon,
    LogbookIcon,
    ResumeBuilderIcon,
} from '../../TopBar/Branding/Icons';

export const listItems = typeof window !== 'undefined' && [
    {
        icon: 'brandIcon',
        name: 'My Account',
        url: '/my-account',
    },
    {
        icon: 'accountProfile',
        name: 'My Profile',
        url: '/profile',
    },
    {
        imgSRC: <JobOutlineIcon width={24} height={24} />,
        name: 'My Jobs',
        url: 'jobs/my-jobs',
    },
    {
        imgSRC: <CourseOutlineIcon width={24} height={24} />,
        name: 'My Courses',
        url: 'courses/my-courses',
    },
    {
        imgSRC: <EventsOutlineIcon width={24} height={24} />,
        name: 'My Events',
        url: 'events/my-events',
    },
    {
        imgSRC: <LogbookIcon width={24} height={24} />,
        name: 'Logbook',
        url: '/my-account',
    },
    {
        icon: 'setting',
        name: 'Settings',
        url: 'settings/login-security',
        color: '#717171',
    },
];
