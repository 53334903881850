import * as React from 'react';

import { TimePicker as TimePickerMUI } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Controller, DeepMap, FieldError } from 'react-hook-form';

interface timePickerComponentProps {
    label: string;
    className?: string;
    readOnly?: boolean;
    handleChangeProps?: any;
    disabled?: boolean;
    valueProps?: any;
    error?: boolean;
    errorText?: boolean;
    ampm?: boolean;
}

interface timePickerProps {
    readOnly?: boolean;
    className?: any;
    disabled?: any;
    label?: string;
    control?: any;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    touched?: DeepMap<Record<string, any>, true>;
    handleChange?: any;
    rest?: any;
    name: any;
    defaultValue?: any;
    id: any;
    validationRules?: any;
    valueProps?: any;
    ampm?: boolean;
}

const TimePickerComponent = ({
    label,
    className,
    readOnly,
    handleChangeProps,
    disabled,
    valueProps = null,
    error,
    errorText,
    ampm,
}: timePickerComponentProps) => {
    const [value, setValue] = React.useState<Date | null>(valueProps);

    React.useEffect(() => {
        setValue(valueProps ?? null);
    }, [valueProps]);

    const handleChange = (newValue: Date | null) => {
        setValue(newValue);
        if (handleChangeProps) handleChangeProps(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePickerMUI
                label={label}
                value={value}
                onChange={handleChange}
                renderInput={(params) => {
                    return (
                        <TextField
                            variant="filled"
                            {...params}
                            error={error}
                            className={`doct-input doct-input-time-picker ${readOnly ? ' doct-read-only-input' : ''}${
                                className ? ` ${className}` : ''
                            }${disabled ? ' disabled' : ''}`}
                            helperText={errorText}
                        />
                    );
                }}
                ampm={ampm}
            />
        </LocalizationProvider>
    );
};

export default function TimePicker({
    readOnly,
    className,
    disabled,
    label = 'Time Picker',
    control,
    handleChange: handleChangeProps,
    rest,
    name,
    defaultValue = null,
    valueProps = '',
    id,
    validationRules,
    isErrors,
    ampm = false,
}: timePickerProps) {
    if (control) {
        return (
            <Controller
                rules={validationRules}
                render={({ onChange, value }) => {
                    return (
                        <TimePickerComponent
                            handleChangeProps={onChange}
                            label={label}
                            className={className}
                            readOnly={readOnly}
                            valueProps={value}
                            disabled={disabled}
                            error={isErrors?.[name] && isErrors[name] ? true : false}
                            errorText={isErrors?.[name]?.message}
                            ampm={ampm}
                        />
                    );
                }}
                defaultValue={defaultValue}
                name={name}
                id={id}
                control={control}
                {...rest}
            />
        );
    }

    return (
        <TimePickerComponent
            handleChangeProps={handleChangeProps}
            label={label}
            className={className}
            readOnly={readOnly}
            valueProps={valueProps}
            disabled={disabled}
            ampm={ampm}
        />
    );
}
