import React from 'react';
import { DoctTypography, DoctIcon, DoctIconButton, DoctButton } from 'doct-core';
import DoctModal from '../Modal/DoctModal';

import { validateSize, maxSelectFile } from './utils';

import './FileUpload.scss';
import { Box, CircularProgress } from '@mui/material';

interface FileUploadsProps {
    uploadedFiles: Record<any, any>[];
    setUploadedFiles: (prevState) => void;
    maxFileSizeInMb?: any;
    maxFiles?: any;
    uploadTitle?: string;
    uploadMaxFilesMessage?: string;
    accept?: string;
    name?: string;
    id?: string;
    singleUpload?: boolean;
    loader?: boolean;
}

const FileUploads: React.FC<FileUploadsProps> = ({
    maxFiles,
    maxFileSizeInMb,
    setUploadedFiles,
    uploadedFiles,
    uploadTitle,
    uploadMaxFilesMessage,
    accept = '.pdf, .doc, .docx',
    name = 'attachments',
    id = 'attachments',
    singleUpload,
    loader = false,
}) => {
    const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
    const [uploadedFileLength, setUploadedFileLength] = React.useState<number | string>(0);

    const MAX_FILE_SIZE_MB = maxFileSizeInMb || 2;
    const MAX_FILE_SIZE_KB = MAX_FILE_SIZE_MB * 1000;
    const MAX_FILES = maxFiles || 5;

    const handleUploadFiles = (event) => {
        const files = event && event.target.files;
        let fileArray = [];
        setUploadedFileLength((prevState) => prevState + files.length);

        if (maxSelectFile(event, MAX_FILES)) {
            localStorage.setItem('totalFiles', files.length + uploadedFiles.length);
            const totalItems = localStorage.getItem('totalFiles') && Number(localStorage.getItem('totalFiles'));
            if (totalItems >= MAX_FILES + 1) {
                localStorage.setItem('totalFiles', '' + uploadedFiles.length);
                setIsOpenModal(true);
            } else {
                fileArray = [...files].filter((file) => validateSize(file, MAX_FILE_SIZE_KB, MAX_FILE_SIZE_MB) && file);
                setUploadedFiles([...uploadedFiles, ...fileArray]);
            }
        }
    };

    const handleRemoveFile = (e, i) => {
        e.preventDefault();
        const fileArray = uploadedFiles.filter((_, ind) => ind !== i);
        localStorage.setItem('totalFiles', '' + fileArray.length);
        setUploadedFiles(fileArray);
    };

    return (
        <>
            <DoctModal showFooter={false} open={isOpenModal} handleClose={() => setIsOpenModal(false)}>
                <div>
                    You can upload {maxFiles} document {maxFiles > 1 ? 's' : ''} only
                </div>
            </DoctModal>

            <div className="content-wrapper file-input-section mx-auto">
                <DoctTypography variant="body2" className="text-grey-600 m-0">
                    {uploadTitle}
                </DoctTypography>
                <ul className="uploaded-file-container ">
                    {uploadedFiles.map((file, i) => (
                        <li className="clearfix" key={i}>
                            <div className="file-card">
                                <DoctTypography variant="subtitle2" className="text-grey-800 m-0 text-truncate">
                                    {file.name}
                                </DoctTypography>
                            </div>
                            {loader ? (
                                <Box sx={{ display: 'flex' }}>
                                    <div className="loader-height">
                                        <CircularProgress disableShrink />
                                    </div>
                                </Box>
                            ) : (
                                <DoctIconButton
                                    onButtonClickHandler={(e?: any) => handleRemoveFile(e, i)}
                                    icon="delete"
                                    variant="outlined"
                                    type="semantic-danger"
                                    size="medium"
                                />
                            )}
                        </li>
                    ))}
                </ul>
                <div className="file-input-wrap d-flex align-items-center">
                    {uploadedFiles.length >= MAX_FILES ? (
                        <DoctButton
                            onButtonClickHandler={(e) => {
                                e.preventDefault();
                                setIsOpenModal(true);
                            }}
                            variant="contained"
                            text="Upload"
                            type="semantic-info"
                            size="medium"
                            icon="cloudBackup"
                            iconPosition="left"
                        />
                    ) : (
                        // <div
                        //     onClick={() => setIsOpenModal(true)}
                        //     // htmlFor="attachments"
                        //     className="doct-button d-flex align-items-center justify-content-center bg-info text-white doct-info-button doct-medium-button doct-contained-button"
                        // >
                        //     <DoctIcon name="cloudBackup" className="left" /> Upload
                        // </div>
                        <div>
                            <input
                                onChange={handleUploadFiles}
                                accept={accept}
                                type="file"
                                name={name}
                                id={id}
                                className="d-none"
                                multiple={singleUpload ? false : maxFiles > 1}
                            />
                            <label
                                htmlFor={id}
                                className="doct-button d-flex align-items-center justify-content-center bg-info text-white doct-info-button doct-medium-button doct-contained-button"
                            >
                                <DoctIcon name="cloudBackup" className="left" /> Upload
                            </label>
                        </div>
                    )}
                    <DoctTypography variant="textLabel2" className="text-grey-600 m-0">
                        <>{uploadMaxFilesMessage}</>
                    </DoctTypography>
                </div>
            </div>
        </>
    );
};

export default FileUploads;
