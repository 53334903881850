import { DoctTypography } from 'doct-core';
import React from 'react';
import { DoctPhoneCodeDropdown, DoctTextField } from '../Form';
import ModalImg from './VerifyMobileModalImg';

export default function VerifyMobileNumberLayout({
    control,
    errors,
    mobileNumber,
}: {
    control: any;
    errors: any;
    mobileNumber: any;
}): JSX.Element {
    return (
        <>
            <ModalImg />
            <div className="p-3">
                {mobileNumber ? (
                    <DoctTypography variant="body2" fontWeight="medium" className="m-0 text-grey-800">
                        To proceed further, you need to verify your mobile number.
                    </DoctTypography>
                ) : (
                    <DoctTypography variant="body2" fontWeight="medium" className="m-0 text-grey-800">
                        To proceed further, you need to add your mobile number.
                    </DoctTypography>
                )}
                <div className="d-flex pt-3 pb-3 mt-1 mb-1">
                    <div className="w-25 mr-3">
                        <DoctPhoneCodeDropdown control={control} isErrors={errors} name={'dialCode'} />
                    </div>

                    <DoctTextField
                        label={mobileNumber ? 'Mobile No' : 'Enter mobile number'}
                        name="mobileNumber"
                        id="mobileNumber"
                        className="ml-n2"
                        control={control}
                        isErrors={errors}
                        showStar={false}
                        validationRules={{
                            required: "It's required",
                            maxLength: {
                                value: 10,
                                message: 'Maximum 10 digits',
                            },

                            minLength: {
                                value: 10,
                                message: 'Minimum 10 digits',
                            },
                            pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: 'Mobile No is not valid',
                            },
                        }}
                        defaultValue={mobileNumber}
                    />
                </div>

                <DoctTypography variant="subtitle3" fontWeight="regular" className="text-grey-600 mt-0 mb-3">
                    An OTP will be sent to your mobile number as part of the verification process.
                </DoctTypography>
            </div>
        </>
    );
}
