import React, { useState, ReactElement, useEffect } from 'react';
import { DoctContainer, DoctRow, DoctCol, DoctIcon, DoctTypography, DoctLogoRegTM } from 'doct-core';
import Collapsible from '../Footer/Collapsible';
import '../Footer/DoctFooter.scss';

interface Props {
    className?: string;
    onTermConditionHandler: () => void;
    onPrivacyHandler: () => void;
    onPaymentHandler: () => void;
}
// Set global type for window
declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        DoctUrls: any;
    }
}

function EnterpriseFooter({
    className,
    onTermConditionHandler,
    onPrivacyHandler,
    onPaymentHandler,
    ...rest
}: Props): ReactElement {
    const [doctUrls, setDoctUrls] = useState(null);

    // Fetched urls from window and added in a local state
    useEffect(() => {
        setDoctUrls(typeof window !== 'undefined' && window.DoctUrls ? window.DoctUrls : null);
    }, []);

    return (
        <footer className={`footer ${className ? className : ''}`} {...rest}>
            <DoctContainer>
                <DoctRow>
                    {/* Social Links */}
                    <DoctCol xs={12} sm={5} md={3} className="footer-social-wrapper">
                        <div className="footer-widget">
                            <div className="footer-nav-logo d-block d-sm-flex">
                                <DoctLogoRegTM rest={true} />
                            </div>
                            <DoctTypography
                                variant="textLabel1"
                                className="footer-tag-line text-grey-800 d-block d-sm-flex"
                            >
                                Empowering Healthcare Fraternity
                            </DoctTypography>
                            <ul className={`footer-widget-social justify-content-sm-start`}>
                                <li>
                                    <DoctTypography
                                        href="https://www.facebook.com/docthub/"
                                        variant="link1"
                                        targetBlank={true}
                                        className="text-grey-600 facebook-icon footer-link align-items-center"
                                    >
                                        <>
                                            <DoctIcon width="16" name="facebook" />
                                        </>
                                    </DoctTypography>
                                </li>
                                <li>
                                    <DoctTypography
                                        className="text-grey-600 footer-link align-items-center"
                                        href="https://twitter.com/docthub"
                                        targetBlank={true}
                                        variant="link1"
                                    >
                                        <>
                                            <DoctIcon width="16" name="twitter" />
                                        </>
                                    </DoctTypography>
                                </li>
                                <li>
                                    <DoctTypography
                                        targetBlank={true}
                                        className="text-grey-600 footer-link align-items-center"
                                        href="https://www.linkedin.com/company/docthub2017/"
                                        variant="link1"
                                    >
                                        <>
                                            <DoctIcon width="16" name="linkedIn" />
                                        </>
                                    </DoctTypography>
                                </li>
                                <li>
                                    <DoctTypography
                                        targetBlank={true}
                                        className="text-grey-600 footer-link align-items-center"
                                        href="https://www.instagram.com/docthub/"
                                        variant="link1"
                                    >
                                        <>
                                            <DoctIcon width="16" name="instagram" />
                                        </>
                                    </DoctTypography>
                                </li>
                                <li>
                                    <DoctTypography
                                        targetBlank={true}
                                        className="text-grey-600 footer-link align-items-center"
                                        href="https://www.youtube.com/c/Docthub1/videos"
                                        variant="link1"
                                    >
                                        <>
                                            <DoctIcon width="16" name="youTube" />
                                        </>
                                    </DoctTypography>
                                </li>
                            </ul>
                        </div>
                    </DoctCol>
                    {/* Products Links */}

                    <DoctCol xs={12} className="col-sm">
                        <>
                            <Collapsible title="Enterprise Solutions">
                                <ul>
                                    <li>
                                        <DoctTypography
                                            href={doctUrls ? doctUrls.doct_institutes_url : '#'}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            List a Course
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={doctUrls ? doctUrls.doct_recruiters_url : '#'}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Post a Job
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={doctUrls ? doctUrls.doct_event_organizers : '#'}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Create an Event
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={doctUrls ? doctUrls.doct_membership_url : '#'}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Membership <br /> Management
                                        </DoctTypography>
                                    </li>
                                </ul>
                            </Collapsible>
                            <div className="d-md-none">
                                <Collapsible title="For Organizations">
                                    <ul>
                                        <li>
                                            <DoctTypography
                                                href={`${doctUrls?.doct_enterprise_solutions_url || ''}`}
                                                variant="link1"
                                                targetBlank={true}
                                                className="text-grey-600 footer-link"
                                            >
                                                Enterprise Dashboard
                                            </DoctTypography>
                                        </li>
                                    </ul>
                                </Collapsible>

                                <Collapsible title="Create Account">
                                    <ul>
                                        <li>
                                            <DoctTypography
                                                href={doctUrls ? doctUrls.doct_enterprise_account_url : ''}
                                                variant="link1"
                                                targetBlank={true}
                                                className="text-grey-600 footer-link"
                                            >
                                                Enterprise Account
                                            </DoctTypography>
                                        </li>
                                        <li>
                                            <DoctTypography
                                                href={doctUrls ? doctUrls.doct_individual_account_url : ''}
                                                variant="link1"
                                                targetBlank={true}
                                                className="text-grey-600 footer-link"
                                            >
                                                User Account
                                            </DoctTypography>
                                        </li>
                                    </ul>
                                </Collapsible>
                            </div>
                        </>
                    </DoctCol>

                    <DoctCol xs={12} className="col-sm d-none d-md-block">
                        <>
                            <Collapsible title="For Organizations">
                                <ul>
                                    <li>
                                        <DoctTypography
                                            href={`${doctUrls?.doct_enterprise_solutions_url || ''}`}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Enterprise Dashboard
                                        </DoctTypography>
                                    </li>
                                </ul>
                            </Collapsible>

                            <div className="d-none d-md-block mt-sm-4">
                                <Collapsible title="Create Account">
                                    <ul>
                                        <li>
                                            <DoctTypography
                                                href={doctUrls ? doctUrls.doct_enterprise_account_url : ''}
                                                variant="link1"
                                                targetBlank={true}
                                                className="text-grey-600 footer-link"
                                            >
                                                Enterprise Account
                                            </DoctTypography>
                                        </li>
                                        <li>
                                            <DoctTypography
                                                href={doctUrls ? doctUrls.doct_individual_account_url : ''}
                                                variant="link1"
                                                targetBlank={true}
                                                className="text-grey-600 footer-link"
                                            >
                                                User Account
                                            </DoctTypography>
                                        </li>
                                    </ul>
                                </Collapsible>
                            </div>
                        </>
                    </DoctCol>

                    <DoctCol xs={12} className="col-sm">
                        <>
                            <Collapsible title="Users Platform">
                                <ul>
                                    <li>
                                        <DoctTypography
                                            href={doctUrls ? doctUrls.doct_course_url : '#'}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Courses
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={doctUrls ? doctUrls.doct_jobs_url : '#'}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Jobs
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={doctUrls ? doctUrls.doct_event_url : '#'}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Events
                                        </DoctTypography>
                                    </li>
                                </ul>
                            </Collapsible>

                            <div className="mt-sm-4">
                                <Collapsible title="Explore more">
                                    <ul>
                                        <li>
                                            <DoctTypography
                                                href={doctUrls?.doct_blogs_url && `${doctUrls?.doct_blogs_url}`}
                                                variant="link1"
                                                targetBlank={true}
                                                className="text-grey-600 footer-link"
                                            >
                                                Blogs
                                            </DoctTypography>
                                        </li>
                                        <li>
                                            <DoctTypography
                                                href={doctUrls?.doct_news_url && `${doctUrls?.doct_news_url}`}
                                                variant="link1"
                                                targetBlank={true}
                                                className="text-grey-600 footer-link"
                                            >
                                                News
                                            </DoctTypography>
                                        </li>

                                        <li>
                                            <DoctTypography
                                                href={doctUrls?.doct_log_book_url && `${doctUrls?.doct_log_book_url}`}
                                                variant="link1"
                                                targetBlank={true}
                                                className="text-grey-600 footer-link"
                                            >
                                                Logbook
                                            </DoctTypography>
                                        </li>
                                    </ul>
                                </Collapsible>

                                {/* <div className="d-md-none mt-sm-4">
                                    <Collapsible title="Our Services">
                                        <ul>
                                            <li>
                                                <DoctTypography
                                                    href={doctUrls ? doctUrls.doct_talent_acquisition_url : ''}
                                                    variant="link1"
                                                    targetBlank={true}
                                                    className="text-grey-600 footer-link"
                                                >
                                                    Talent Acquisition
                                                </DoctTypography>
                                            </li>
                                        </ul>
                                    </Collapsible>
                                </div> */}
                            </div>
                        </>
                    </DoctCol>
                    <DoctCol xs={12} className="col-sm">
                        <>
                            <Collapsible title="Company">
                                <ul>
                                    <li>
                                        <DoctTypography
                                            href={`${doctUrls && doctUrls.doct_brand_url}/media-releases`}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Media Releases
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={`${doctUrls && doctUrls.doct_brand_url}/about-us`}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            About Docthub
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={`${doctUrls && doctUrls.doct_brand_url}/contact-us`}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Contact us
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={`${doctUrls && doctUrls.doct_brand_url}`}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Docthub Home
                                        </DoctTypography>
                                    </li>
                                </ul>
                            </Collapsible>

                            {/* <div className="d-none d-md-block mt-sm-4">
                                <Collapsible title="Our Services">
                                    <ul>
                                        <li>
                                            <DoctTypography
                                                href={doctUrls ? doctUrls.doct_talent_acquisition_url : ''}
                                                variant="link1"
                                                targetBlank={true}
                                                className="text-grey-600 footer-link"
                                            >
                                                Talent Acquisition
                                            </DoctTypography>
                                        </li>
                                    </ul>
                                </Collapsible>
                            </div> */}

                            <div className="d-md-none mt-sm-4">
                                <ul>
                                    <li>
                                        <DoctTypography
                                            href={`${doctUrls && doctUrls.doct_brand_url}/media-releases`}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Terms and <br /> Conditions
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={`${doctUrls && doctUrls.doct_brand_url}/about-us`}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Privacy Policy
                                        </DoctTypography>
                                    </li>
                                    <li>
                                        <DoctTypography
                                            href={`${doctUrls && doctUrls.doct_brand_url}/contact-us`}
                                            variant="link1"
                                            targetBlank={true}
                                            className="text-grey-600 footer-link"
                                        >
                                            Payment Policy
                                        </DoctTypography>
                                    </li>
                                </ul>
                                <Collapsible title="Policies">
                                    <ul>
                                        <li style={{ cursor: 'pointer' }} onClick={onTermConditionHandler}>
                                            <span className="text-grey-600 footer-link link-1 py-0">
                                                Terms and <br /> Conditions
                                            </span>
                                        </li>
                                        <li style={{ cursor: 'pointer' }} onClick={onPrivacyHandler}>
                                            <span className="text-grey-600 footer-link link-1 py-0"></span>
                                        </li>
                                        <li style={{ cursor: 'pointer' }} onClick={onPaymentHandler}>
                                            <span className="text-grey-600 footer-link link-1 py-0"></span>
                                        </li>
                                    </ul>
                                </Collapsible>
                            </div>
                        </>
                    </DoctCol>
                    <DoctCol xs={12} className="d-none d-md-block col-sm">
                        <Collapsible title="Policies">
                            <ul>
                                <li style={{ cursor: 'pointer' }} onClick={onTermConditionHandler}>
                                    <span className="text-grey-600 footer-link link-1 py-0">
                                        Terms and <br /> Conditions
                                    </span>
                                </li>
                                <li style={{ cursor: 'pointer' }} onClick={onPrivacyHandler}>
                                    <span className="text-grey-600 footer-link link-1 py-0">Privacy Policy</span>
                                </li>
                                <li style={{ cursor: 'pointer' }} onClick={onPaymentHandler}>
                                    <span className="text-grey-600 footer-link link-1 py-0">Payment Policy</span>
                                </li>
                            </ul>
                        </Collapsible>
                    </DoctCol>
                </DoctRow>
                {/* Copyright Footer */}
                <DoctTypography variant="textLabel2" className="text-center text-grey-400 copyright-footer">
                    <>Copyright © {new Date().getFullYear()} Docthub. All rights reserved.</>
                </DoctTypography>
            </DoctContainer>
        </footer>
    );
}

export default EnterpriseFooter;
