export const countries = [
    {
        code: 'AC',
        unicode: 'U+1F1E6 U+1F1E8',
        label: '🇦🇨 Ascension Island',
        emoji: '🇦🇨',
    },
    {
        code: 'AD',
        unicode: 'U+1F1E6 U+1F1E9',
        label: '🇦🇩 Andorra',
        emoji: '🇦🇩',
    },
    {
        code: 'AE',
        unicode: 'U+1F1E6 U+1F1EA',
        label: '🇦🇪 United Arab Emirates',
        emoji: '🇦🇪',
    },
    {
        code: 'AF',
        unicode: 'U+1F1E6 U+1F1EB',
        label: '🇦🇫 Afghanistan',
        emoji: '🇦🇫',
    },
    {
        code: 'AG',
        unicode: 'U+1F1E6 U+1F1EC',
        label: '🇦🇬 Antigua & Barbuda',
        emoji: '🇦🇬',
    },
    {
        code: 'AI',
        unicode: 'U+1F1E6 U+1F1EE',
        label: '🇦🇮 Anguilla',
        emoji: '🇦🇮',
    },
    {
        code: 'AL',
        unicode: 'U+1F1E6 U+1F1F1',
        label: '🇦🇱 Albania',
        emoji: '🇦🇱',
    },
    {
        code: 'AM',
        unicode: 'U+1F1E6 U+1F1F2',
        label: '🇦🇲 Armenia',
        emoji: '🇦🇲',
    },
    {
        code: 'AO',
        unicode: 'U+1F1E6 U+1F1F4',
        label: '🇦🇴 Angola',
        emoji: '🇦🇴',
    },
    {
        code: 'AQ',
        unicode: 'U+1F1E6 U+1F1F6',
        label: '🇦🇶 Antarctica',
        emoji: '🇦🇶',
    },
    {
        code: 'AR',
        unicode: 'U+1F1E6 U+1F1F7',
        label: '🇦🇷 Argentina',
        emoji: '🇦🇷',
    },
    {
        code: 'AS',
        unicode: 'U+1F1E6 U+1F1F8',
        label: '🇦🇸 American Samoa',
        emoji: '🇦🇸',
    },
    {
        code: 'AT',
        unicode: 'U+1F1E6 U+1F1F9',
        label: '🇦🇹 Austria',
        emoji: '🇦🇹',
    },
    {
        code: 'AU',
        unicode: 'U+1F1E6 U+1F1FA',
        label: '🇦🇺 Australia',
        emoji: '🇦🇺',
    },
    {
        code: 'AW',
        unicode: 'U+1F1E6 U+1F1FC',
        label: '🇦🇼 Aruba',
        emoji: '🇦🇼',
    },
    {
        code: 'AX',
        unicode: 'U+1F1E6 U+1F1FD',
        label: '🇦🇽 Åland Islands',
        emoji: '🇦🇽',
    },
    {
        code: 'AZ',
        unicode: 'U+1F1E6 U+1F1FF',
        label: '🇦🇿 Azerbaijan',
        emoji: '🇦🇿',
    },
    {
        code: 'BA',
        unicode: 'U+1F1E7 U+1F1E6',
        label: '🇧🇦 Bosnia & Herzegovina',
        emoji: '🇧🇦',
    },
    {
        code: 'BB',
        unicode: 'U+1F1E7 U+1F1E7',
        label: '🇧🇧 Barbados',
        emoji: '🇧🇧',
    },
    {
        code: 'BD',
        unicode: 'U+1F1E7 U+1F1E9',
        label: '🇧🇩 Bangladesh',
        emoji: '🇧🇩',
    },
    {
        code: 'BE',
        unicode: 'U+1F1E7 U+1F1EA',
        label: '🇧🇪 Belgium',
        emoji: '🇧🇪',
    },
    {
        code: 'BF',
        unicode: 'U+1F1E7 U+1F1EB',
        label: '🇧🇫 Burkina Faso',
        emoji: '🇧🇫',
    },
    {
        code: 'BG',
        unicode: 'U+1F1E7 U+1F1EC',
        label: '🇧🇬 Bulgaria',
        emoji: '🇧🇬',
    },
    {
        code: 'BH',
        unicode: 'U+1F1E7 U+1F1ED',
        label: '🇧🇭 Bahrain',
        emoji: '🇧🇭',
    },
    {
        code: 'BI',
        unicode: 'U+1F1E7 U+1F1EE',
        label: '🇧🇮 Burundi',
        emoji: '🇧🇮',
    },
    {
        code: 'BJ',
        unicode: 'U+1F1E7 U+1F1EF',
        label: '🇧🇯 Benin',
        emoji: '🇧🇯',
    },
    {
        code: 'BL',
        unicode: 'U+1F1E7 U+1F1F1',
        label: '🇧🇱 St. Barthélemy',
        emoji: '🇧🇱',
    },
    {
        code: 'BM',
        unicode: 'U+1F1E7 U+1F1F2',
        label: '🇧🇲 Bermuda',
        emoji: '🇧🇲',
    },
    {
        code: 'BN',
        unicode: 'U+1F1E7 U+1F1F3',
        label: '🇧🇳 Brunei',
        emoji: '🇧🇳',
    },
    {
        code: 'BO',
        unicode: 'U+1F1E7 U+1F1F4',
        label: '🇧🇴 Bolivia',
        emoji: '🇧🇴',
    },
    {
        code: 'BQ',
        unicode: 'U+1F1E7 U+1F1F6',
        label: '🇧🇶 Caribbean Netherlands',
        emoji: '🇧🇶',
    },
    {
        code: 'BR',
        unicode: 'U+1F1E7 U+1F1F7',
        label: '🇧🇷 Brazil',
        emoji: '🇧🇷',
    },
    {
        code: 'BS',
        unicode: 'U+1F1E7 U+1F1F8',
        label: '🇧🇸 Bahamas',
        emoji: '🇧🇸',
    },
    {
        code: 'BT',
        unicode: 'U+1F1E7 U+1F1F9',
        label: '🇧🇹 Bhutan',
        emoji: '🇧🇹',
    },
    {
        code: 'BV',
        unicode: 'U+1F1E7 U+1F1FB',
        label: '🇧🇻 Bouvet Island',
        emoji: '🇧🇻',
    },
    {
        code: 'BW',
        unicode: 'U+1F1E7 U+1F1FC',
        label: '🇧🇼 Botswana',
        emoji: '🇧🇼',
    },
    {
        code: 'BY',
        unicode: 'U+1F1E7 U+1F1FE',
        label: '🇧🇾 Belarus',
        emoji: '🇧🇾',
    },
    {
        code: 'BZ',
        unicode: 'U+1F1E7 U+1F1FF',
        label: '🇧🇿 Belize',
        emoji: '🇧🇿',
    },
    {
        code: 'CA',
        unicode: 'U+1F1E8 U+1F1E6',
        label: '🇨🇦 Canada',
        emoji: '🇨🇦',
    },
    {
        code: 'CC',
        unicode: 'U+1F1E8 U+1F1E8',
        label: '🇨🇨 Cocos (Keeling) Islands',
        emoji: '🇨🇨',
    },
    {
        code: 'CD',
        unicode: 'U+1F1E8 U+1F1E9',
        label: '🇨🇩 Congo - Kinshasa',
        emoji: '🇨🇩',
    },
    {
        code: 'CF',
        unicode: 'U+1F1E8 U+1F1EB',
        label: '🇨🇫 Central African Republic',
        emoji: '🇨🇫',
    },
    {
        code: 'CG',
        unicode: 'U+1F1E8 U+1F1EC',
        label: '🇨🇬 Congo - Brazzaville',
        emoji: '🇨🇬',
    },
    {
        code: 'CH',
        unicode: 'U+1F1E8 U+1F1ED',
        label: '🇨🇭 Switzerland',
        emoji: '🇨🇭',
    },
    {
        code: 'CI',
        unicode: 'U+1F1E8 U+1F1EE',
        label: '🇨🇮 Côte d’Ivoire',
        emoji: '🇨🇮',
    },
    {
        code: 'CK',
        unicode: 'U+1F1E8 U+1F1F0',
        label: '🇨🇰 Cook Islands',
        emoji: '🇨🇰',
    },
    {
        code: 'CL',
        unicode: 'U+1F1E8 U+1F1F1',
        label: '🇨🇱 Chile',
        emoji: '🇨🇱',
    },
    {
        code: 'CM',
        unicode: 'U+1F1E8 U+1F1F2',
        label: '🇨🇲 Cameroon',
        emoji: '🇨🇲',
    },
    {
        code: 'CN',
        unicode: 'U+1F1E8 U+1F1F3',
        label: '🇨🇳 China',
        emoji: '🇨🇳',
    },
    {
        code: 'CO',
        unicode: 'U+1F1E8 U+1F1F4',
        label: '🇨🇴 Colombia',
        emoji: '🇨🇴',
    },
    {
        code: 'CP',
        unicode: 'U+1F1E8 U+1F1F5',
        label: '🇨🇵 Clipperton Island',
        emoji: '🇨🇵',
    },
    {
        code: 'CR',
        unicode: 'U+1F1E8 U+1F1F7',
        label: '🇨🇷 Costa Rica',
        emoji: '🇨🇷',
    },
    {
        code: 'CU',
        unicode: 'U+1F1E8 U+1F1FA',
        label: '🇨🇺 Cuba',
        emoji: '🇨🇺',
    },
    {
        code: 'CV',
        unicode: 'U+1F1E8 U+1F1FB',
        label: '🇨🇻 Cape Verde',
        emoji: '🇨🇻',
    },
    {
        code: 'CW',
        unicode: 'U+1F1E8 U+1F1FC',
        label: '🇨🇼 Curaçao',
        emoji: '🇨🇼',
    },
    {
        code: 'CX',
        unicode: 'U+1F1E8 U+1F1FD',
        label: '🇨🇽 Christmas Island',
        emoji: '🇨🇽',
    },
    {
        code: 'CY',
        unicode: 'U+1F1E8 U+1F1FE',
        label: '🇨🇾 Cyprus',
        emoji: '🇨🇾',
    },
    {
        code: 'CZ',
        unicode: 'U+1F1E8 U+1F1FF',
        label: '🇨🇿 Czechia',
        emoji: '🇨🇿',
    },
    {
        code: 'DE',
        unicode: 'U+1F1E9 U+1F1EA',
        label: '🇩🇪 Germany',
        emoji: '🇩🇪',
    },
    {
        code: 'DG',
        unicode: 'U+1F1E9 U+1F1EC',
        label: '🇩🇬 Diego Garcia',
        emoji: '🇩🇬',
    },
    {
        code: 'DJ',
        unicode: 'U+1F1E9 U+1F1EF',
        label: '🇩🇯 Djibouti',
        emoji: '🇩🇯',
    },
    {
        code: 'DK',
        unicode: 'U+1F1E9 U+1F1F0',
        label: '🇩🇰 Denmark',
        emoji: '🇩🇰',
    },
    {
        code: 'DM',
        unicode: 'U+1F1E9 U+1F1F2',
        label: '🇩🇲 Dominica',
        emoji: '🇩🇲',
    },
    {
        code: 'DO',
        unicode: 'U+1F1E9 U+1F1F4',
        label: '🇩🇴 Dominican Republic',
        emoji: '🇩🇴',
    },
    {
        code: 'DZ',
        unicode: 'U+1F1E9 U+1F1FF',
        label: '🇩🇿 Algeria',
        emoji: '🇩🇿',
    },
    {
        code: 'EA',
        unicode: 'U+1F1EA U+1F1E6',
        label: '🇪🇦 Ceuta & Melilla',
        emoji: '🇪🇦',
    },
    {
        code: 'EC',
        unicode: 'U+1F1EA U+1F1E8',
        label: '🇪🇨 Ecuador',
        emoji: '🇪🇨',
    },
    {
        code: 'EE',
        unicode: 'U+1F1EA U+1F1EA',
        label: '🇪🇪 Estonia',
        emoji: '🇪🇪',
    },
    {
        code: 'EG',
        unicode: 'U+1F1EA U+1F1EC',
        label: '🇪🇬 Egypt',
        emoji: '🇪🇬',
    },
    {
        code: 'EH',
        unicode: 'U+1F1EA U+1F1ED',
        label: '🇪🇭 Western Sahara',
        emoji: '🇪🇭',
    },
    {
        code: 'ER',
        unicode: 'U+1F1EA U+1F1F7',
        label: '🇪🇷 Eritrea',
        emoji: '🇪🇷',
    },
    {
        code: 'ES',
        unicode: 'U+1F1EA U+1F1F8',
        label: '🇪🇸 Spain',
        emoji: '🇪🇸',
    },
    {
        code: 'ET',
        unicode: 'U+1F1EA U+1F1F9',
        label: '🇪🇹 Ethiopia',
        emoji: '🇪🇹',
    },
    {
        code: 'EU',
        unicode: 'U+1F1EA U+1F1FA',
        label: '🇪🇺 European Union',
        emoji: '🇪🇺',
    },
    {
        code: 'FI',
        unicode: 'U+1F1EB U+1F1EE',
        label: '🇫🇮 Finland',
        emoji: '🇫🇮',
    },
    {
        code: 'FJ',
        unicode: 'U+1F1EB U+1F1EF',
        label: '🇫🇯 Fiji',
        emoji: '🇫🇯',
    },
    {
        code: 'FK',
        unicode: 'U+1F1EB U+1F1F0',
        label: '🇫🇰 Falkland Islands',
        emoji: '🇫🇰',
    },
    {
        code: 'FM',
        unicode: 'U+1F1EB U+1F1F2',
        label: '🇫🇲 Micronesia',
        emoji: '🇫🇲',
    },
    {
        code: 'FO',
        unicode: 'U+1F1EB U+1F1F4',
        label: '🇫🇴 Faroe Islands',
        emoji: '🇫🇴',
    },
    {
        code: 'FR',
        unicode: 'U+1F1EB U+1F1F7',
        label: '🇫🇷 France',
        emoji: '🇫🇷',
    },
    {
        code: 'GA',
        unicode: 'U+1F1EC U+1F1E6',
        label: '🇬🇦 Gabon',
        emoji: '🇬🇦',
    },
    {
        code: 'GB',
        unicode: 'U+1F1EC U+1F1E7',
        label: '🇬🇧 United Kingdom',
        emoji: '🇬🇧',
    },
    {
        code: 'GD',
        unicode: 'U+1F1EC U+1F1E9',
        label: '🇬🇩 Grenada',
        emoji: '🇬🇩',
    },
    {
        code: 'GE',
        unicode: 'U+1F1EC U+1F1EA',
        label: '🇬🇪 Georgia',
        emoji: '🇬🇪',
    },
    {
        code: 'GF',
        unicode: 'U+1F1EC U+1F1EB',
        label: '🇬🇫 French Guiana',
        emoji: '🇬🇫',
    },
    {
        code: 'GG',
        unicode: 'U+1F1EC U+1F1EC',
        label: '🇬🇬 Guernsey',
        emoji: '🇬🇬',
    },
    {
        code: 'GH',
        unicode: 'U+1F1EC U+1F1ED',
        label: '🇬🇭 Ghana',
        emoji: '🇬🇭',
    },
    {
        code: 'GI',
        unicode: 'U+1F1EC U+1F1EE',
        label: '🇬🇮 Gibraltar',
        emoji: '🇬🇮',
    },
    {
        code: 'GL',
        unicode: 'U+1F1EC U+1F1F1',
        label: '🇬🇱 Greenland',
        emoji: '🇬🇱',
    },
    {
        code: 'GM',
        unicode: 'U+1F1EC U+1F1F2',
        label: '🇬🇲 Gambia',
        emoji: '🇬🇲',
    },
    {
        code: 'GN',
        unicode: 'U+1F1EC U+1F1F3',
        label: '🇬🇳 Guinea',
        emoji: '🇬🇳',
    },
    {
        code: 'GP',
        unicode: 'U+1F1EC U+1F1F5',
        label: '🇬🇵 Guadeloupe',
        emoji: '🇬🇵',
    },
    {
        code: 'GQ',
        unicode: 'U+1F1EC U+1F1F6',
        label: '🇬🇶 Equatorial Guinea',
        emoji: '🇬🇶',
    },
    {
        code: 'GR',
        unicode: 'U+1F1EC U+1F1F7',
        label: '🇬🇷 Greece',
        emoji: '🇬🇷',
    },
    {
        code: 'GS',
        unicode: 'U+1F1EC U+1F1F8',
        label: '🇬🇸 South Georgia & South Sandwich Islands',
        emoji: '🇬🇸',
    },
    {
        code: 'GT',
        unicode: 'U+1F1EC U+1F1F9',
        label: '🇬🇹 Guatemala',
        emoji: '🇬🇹',
    },
    {
        code: 'GU',
        unicode: 'U+1F1EC U+1F1FA',
        label: '🇬🇺 Guam',
        emoji: '🇬🇺',
    },
    {
        code: 'GW',
        unicode: 'U+1F1EC U+1F1FC',
        label: '🇬🇼 Guinea-Bissau',
        emoji: '🇬🇼',
    },
    {
        code: 'GY',
        unicode: 'U+1F1EC U+1F1FE',
        label: '🇬🇾 Guyana',
        emoji: '🇬🇾',
    },
    {
        code: 'HK',
        unicode: 'U+1F1ED U+1F1F0',
        label: '🇭🇰 Hong Kong SAR China',
        emoji: '🇭🇰',
    },
    {
        code: 'HM',
        unicode: 'U+1F1ED U+1F1F2',
        label: '🇭🇲 Heard & McDonald Islands',
        emoji: '🇭🇲',
    },
    {
        code: 'HN',
        unicode: 'U+1F1ED U+1F1F3',
        label: '🇭🇳 Honduras',
        emoji: '🇭🇳',
    },
    {
        code: 'HR',
        unicode: 'U+1F1ED U+1F1F7',
        label: '🇭🇷 Croatia',
        emoji: '🇭🇷',
    },
    {
        code: 'HT',
        unicode: 'U+1F1ED U+1F1F9',
        label: '🇭🇹 Haiti',
        emoji: '🇭🇹',
    },
    {
        code: 'HU',
        unicode: 'U+1F1ED U+1F1FA',
        label: '🇭🇺 Hungary',
        emoji: '🇭🇺',
    },
    {
        code: 'IC',
        unicode: 'U+1F1EE U+1F1E8',
        label: '🇮🇨 Canary Islands',
        emoji: '🇮🇨',
    },
    {
        code: 'ID',
        unicode: 'U+1F1EE U+1F1E9',
        label: '🇮🇩 Indonesia',
        emoji: '🇮🇩',
    },
    {
        code: 'IE',
        unicode: 'U+1F1EE U+1F1EA',
        label: '🇮🇪 Ireland',
        emoji: '🇮🇪',
    },
    {
        code: 'IL',
        unicode: 'U+1F1EE U+1F1F1',
        label: '🇮🇱 Israel',
        emoji: '🇮🇱',
    },
    {
        code: 'IM',
        unicode: 'U+1F1EE U+1F1F2',
        label: '🇮🇲 Isle of Man',
        emoji: '🇮🇲',
    },
    {
        code: 'IN',
        unicode: 'U+1F1EE U+1F1F3',
        label: '🇮🇳 India',
        emoji: '🇮🇳',
    },
    {
        code: 'IO',
        unicode: 'U+1F1EE U+1F1F4',
        label: '🇮🇴 British Indian Ocean Territory',
        emoji: '🇮🇴',
    },
    {
        code: 'IQ',
        unicode: 'U+1F1EE U+1F1F6',
        label: '🇮🇶 Iraq',
        emoji: '🇮🇶',
    },
    {
        code: 'IR',
        unicode: 'U+1F1EE U+1F1F7',
        label: '🇮🇷 Iran',
        emoji: '🇮🇷',
    },
    {
        code: 'IS',
        unicode: 'U+1F1EE U+1F1F8',
        label: '🇮🇸 Iceland',
        emoji: '🇮🇸',
    },
    {
        code: 'IT',
        unicode: 'U+1F1EE U+1F1F9',
        label: '🇮🇹 Italy',
        emoji: '🇮🇹',
    },
    {
        code: 'JE',
        unicode: 'U+1F1EF U+1F1EA',
        label: '🇯🇪 Jersey',
        emoji: '🇯🇪',
    },
    {
        code: 'JM',
        unicode: 'U+1F1EF U+1F1F2',
        label: '🇯🇲 Jamaica',
        emoji: '🇯🇲',
    },
    {
        code: 'JO',
        unicode: 'U+1F1EF U+1F1F4',
        label: '🇯🇴 Jordan',
        emoji: '🇯🇴',
    },
    {
        code: 'JP',
        unicode: 'U+1F1EF U+1F1F5',
        label: '🇯🇵 Japan',
        emoji: '🇯🇵',
    },
    {
        code: 'KE',
        unicode: 'U+1F1F0 U+1F1EA',
        label: '🇰🇪 Kenya',
        emoji: '🇰🇪',
    },
    {
        code: 'KG',
        unicode: 'U+1F1F0 U+1F1EC',
        label: '🇰🇬 Kyrgyzstan',
        emoji: '🇰🇬',
    },
    {
        code: 'KH',
        unicode: 'U+1F1F0 U+1F1ED',
        label: '🇰🇭 Cambodia',
        emoji: '🇰🇭',
    },
    {
        code: 'KI',
        unicode: 'U+1F1F0 U+1F1EE',
        label: '🇰🇮 Kiribati',
        emoji: '🇰🇮',
    },
    {
        code: 'KM',
        unicode: 'U+1F1F0 U+1F1F2',
        label: '🇰🇲 Comoros',
        emoji: '🇰🇲',
    },
    {
        code: 'KN',
        unicode: 'U+1F1F0 U+1F1F3',
        label: '🇰🇳 St. Kitts & Nevis',
        emoji: '🇰🇳',
    },
    {
        code: 'KP',
        unicode: 'U+1F1F0 U+1F1F5',
        label: '🇰🇵 North Korea',
        emoji: '🇰🇵',
    },
    {
        code: 'KR',
        unicode: 'U+1F1F0 U+1F1F7',
        label: '🇰🇷 South Korea',
        emoji: '🇰🇷',
    },
    {
        code: 'KW',
        unicode: 'U+1F1F0 U+1F1FC',
        label: '🇰🇼 Kuwait',
        emoji: '🇰🇼',
    },
    {
        code: 'KY',
        unicode: 'U+1F1F0 U+1F1FE',
        label: '🇰🇾 Cayman Islands',
        emoji: '🇰🇾',
    },
    {
        code: 'KZ',
        unicode: 'U+1F1F0 U+1F1FF',
        label: '🇰🇿 Kazakhstan',
        emoji: '🇰🇿',
    },
    {
        code: 'LA',
        unicode: 'U+1F1F1 U+1F1E6',
        label: '🇱🇦 Laos',
        emoji: '🇱🇦',
    },
    {
        code: 'LB',
        unicode: 'U+1F1F1 U+1F1E7',
        label: '🇱🇧 Lebanon',
        emoji: '🇱🇧',
    },
    {
        code: 'LC',
        unicode: 'U+1F1F1 U+1F1E8',
        label: '🇱🇨 St. Lucia',
        emoji: '🇱🇨',
    },
    {
        code: 'LI',
        unicode: 'U+1F1F1 U+1F1EE',
        label: '🇱🇮 Liechtenstein',
        emoji: '🇱🇮',
    },
    {
        code: 'LK',
        unicode: 'U+1F1F1 U+1F1F0',
        label: '🇱🇰 Sri Lanka',
        emoji: '🇱🇰',
    },
    {
        code: 'LR',
        unicode: 'U+1F1F1 U+1F1F7',
        label: '🇱🇷 Liberia',
        emoji: '🇱🇷',
    },
    {
        code: 'LS',
        unicode: 'U+1F1F1 U+1F1F8',
        label: '🇱🇸 Lesotho',
        emoji: '🇱🇸',
    },
    {
        code: 'LT',
        unicode: 'U+1F1F1 U+1F1F9',
        label: '🇱🇹 Lithuania',
        emoji: '🇱🇹',
    },
    {
        code: 'LU',
        unicode: 'U+1F1F1 U+1F1FA',
        label: '🇱🇺 Luxembourg',
        emoji: '🇱🇺',
    },
    {
        code: 'LV',
        unicode: 'U+1F1F1 U+1F1FB',
        label: '🇱🇻 Latvia',
        emoji: '🇱🇻',
    },
    {
        code: 'LY',
        unicode: 'U+1F1F1 U+1F1FE',
        label: '🇱🇾 Libya',
        emoji: '🇱🇾',
    },
    {
        code: 'MA',
        unicode: 'U+1F1F2 U+1F1E6',
        label: '🇲🇦 Morocco',
        emoji: '🇲🇦',
    },
    {
        code: 'MC',
        unicode: 'U+1F1F2 U+1F1E8',
        label: '🇲🇨 Monaco',
        emoji: '🇲🇨',
    },
    {
        code: 'MD',
        unicode: 'U+1F1F2 U+1F1E9',
        label: '🇲🇩 Moldova',
        emoji: '🇲🇩',
    },
    {
        code: 'ME',
        unicode: 'U+1F1F2 U+1F1EA',
        label: '🇲🇪 Montenegro',
        emoji: '🇲🇪',
    },
    {
        code: 'MF',
        unicode: 'U+1F1F2 U+1F1EB',
        label: '🇲🇫 St. Martin',
        emoji: '🇲🇫',
    },
    {
        code: 'MG',
        unicode: 'U+1F1F2 U+1F1EC',
        label: '🇲🇬 Madagascar',
        emoji: '🇲🇬',
    },
    {
        code: 'MH',
        unicode: 'U+1F1F2 U+1F1ED',
        label: '🇲🇭 Marshall Islands',
        emoji: '🇲🇭',
    },
    {
        code: 'MK',
        unicode: 'U+1F1F2 U+1F1F0',
        label: '🇲🇰 Macedonia',
        emoji: '🇲🇰',
    },
    {
        code: 'ML',
        unicode: 'U+1F1F2 U+1F1F1',
        label: '🇲🇱 Mali',
        emoji: '🇲🇱',
    },
    {
        code: 'MM',
        unicode: 'U+1F1F2 U+1F1F2',
        label: '🇲🇲 Myanmar (Burma)',
        emoji: '🇲🇲',
    },
    {
        code: 'MN',
        unicode: 'U+1F1F2 U+1F1F3',
        label: '🇲🇳 Mongolia',
        emoji: '🇲🇳',
    },
    {
        code: 'MO',
        unicode: 'U+1F1F2 U+1F1F4',
        label: '🇲🇴 Macau SAR China',
        emoji: '🇲🇴',
    },
    {
        code: 'MP',
        unicode: 'U+1F1F2 U+1F1F5',
        label: '🇲🇵 Northern Mariana Islands',
        emoji: '🇲🇵',
    },
    {
        code: 'MQ',
        unicode: 'U+1F1F2 U+1F1F6',
        label: '🇲🇶 Martinique',
        emoji: '🇲🇶',
    },
    {
        code: 'MR',
        unicode: 'U+1F1F2 U+1F1F7',
        label: '🇲🇷 Mauritania',
        emoji: '🇲🇷',
    },
    {
        code: 'MS',
        unicode: 'U+1F1F2 U+1F1F8',
        label: '🇲🇸 Montserrat',
        emoji: '🇲🇸',
    },
    {
        code: 'MT',
        unicode: 'U+1F1F2 U+1F1F9',
        label: '🇲🇹 Malta',
        emoji: '🇲🇹',
    },
    {
        code: 'MU',
        unicode: 'U+1F1F2 U+1F1FA',
        label: '🇲🇺 Mauritius',
        emoji: '🇲🇺',
    },
    {
        code: 'MV',
        unicode: 'U+1F1F2 U+1F1FB',
        label: '🇲🇻 Maldives',
        emoji: '🇲🇻',
    },
    {
        code: 'MW',
        unicode: 'U+1F1F2 U+1F1FC',
        label: '🇲🇼 Malawi',
        emoji: '🇲🇼',
    },
    {
        code: 'MX',
        unicode: 'U+1F1F2 U+1F1FD',
        label: '🇲🇽 Mexico',
        emoji: '🇲🇽',
    },
    {
        code: 'MY',
        unicode: 'U+1F1F2 U+1F1FE',
        label: '🇲🇾 Malaysia',
        emoji: '🇲🇾',
    },
    {
        code: 'MZ',
        unicode: 'U+1F1F2 U+1F1FF',
        label: '🇲🇿 Mozambique',
        emoji: '🇲🇿',
    },
    {
        code: 'NA',
        unicode: 'U+1F1F3 U+1F1E6',
        label: '🇳🇦 Namibia',
        emoji: '🇳🇦',
    },
    {
        code: 'NC',
        unicode: 'U+1F1F3 U+1F1E8',
        label: '🇳🇨 New Caledonia',
        emoji: '🇳🇨',
    },
    {
        code: 'NE',
        unicode: 'U+1F1F3 U+1F1EA',
        label: '🇳🇪 Niger',
        emoji: '🇳🇪',
    },
    {
        code: 'NF',
        unicode: 'U+1F1F3 U+1F1EB',
        label: '🇳🇫 Norfolk Island',
        emoji: '🇳🇫',
    },
    {
        code: 'NG',
        unicode: 'U+1F1F3 U+1F1EC',
        label: '🇳🇬 Nigeria',
        emoji: '🇳🇬',
    },
    {
        code: 'NI',
        unicode: 'U+1F1F3 U+1F1EE',
        label: '🇳🇮 Nicaragua',
        emoji: '🇳🇮',
    },
    {
        code: 'NL',
        unicode: 'U+1F1F3 U+1F1F1',
        label: '🇳🇱 Netherlands',
        emoji: '🇳🇱',
    },
    {
        code: 'NO',
        unicode: 'U+1F1F3 U+1F1F4',
        label: '🇳🇴 Norway',
        emoji: '🇳🇴',
    },
    {
        code: 'NP',
        unicode: 'U+1F1F3 U+1F1F5',
        label: '🇳🇵 Nepal',
        emoji: '🇳🇵',
    },
    {
        code: 'NR',
        unicode: 'U+1F1F3 U+1F1F7',
        label: '🇳🇷 Nauru',
        emoji: '🇳🇷',
    },
    {
        code: 'NU',
        unicode: 'U+1F1F3 U+1F1FA',
        label: '🇳🇺 Niue',
        emoji: '🇳🇺',
    },
    {
        code: 'NZ',
        unicode: 'U+1F1F3 U+1F1FF',
        label: '🇳🇿 New Zealand',
        emoji: '🇳🇿',
    },
    {
        code: 'OM',
        unicode: 'U+1F1F4 U+1F1F2',
        label: '🇴🇲 Oman',
        emoji: '🇴🇲',
    },
    {
        code: 'PA',
        unicode: 'U+1F1F5 U+1F1E6',
        label: '🇵🇦 Panama',
        emoji: '🇵🇦',
    },
    {
        code: 'PE',
        unicode: 'U+1F1F5 U+1F1EA',
        label: '🇵🇪 Peru',
        emoji: '🇵🇪',
    },
    {
        code: 'PF',
        unicode: 'U+1F1F5 U+1F1EB',
        label: '🇵🇫 French Polynesia',
        emoji: '🇵🇫',
    },
    {
        code: 'PG',
        unicode: 'U+1F1F5 U+1F1EC',
        label: '🇵🇬 Papua New Guinea',
        emoji: '🇵🇬',
    },
    {
        code: 'PH',
        unicode: 'U+1F1F5 U+1F1ED',
        label: '🇵🇭 Philippines',
        emoji: '🇵🇭',
    },
    {
        code: 'PK',
        unicode: 'U+1F1F5 U+1F1F0',
        label: '🇵🇰 Pakistan',
        emoji: '🇵🇰',
    },
    {
        code: 'PL',
        unicode: 'U+1F1F5 U+1F1F1',
        label: '🇵🇱 Poland',
        emoji: '🇵🇱',
    },
    {
        code: 'PM',
        unicode: 'U+1F1F5 U+1F1F2',
        label: '🇵🇲 St. Pierre & Miquelon',
        emoji: '🇵🇲',
    },
    {
        code: 'PN',
        unicode: 'U+1F1F5 U+1F1F3',
        label: '🇵🇳 Pitcairn Islands',
        emoji: '🇵🇳',
    },
    {
        code: 'PR',
        unicode: 'U+1F1F5 U+1F1F7',
        label: '🇵🇷 Puerto Rico',
        emoji: '🇵🇷',
    },
    {
        code: 'PS',
        unicode: 'U+1F1F5 U+1F1F8',
        label: '🇵🇸 Palestinian Territories',
        emoji: '🇵🇸',
    },
    {
        code: 'PT',
        unicode: 'U+1F1F5 U+1F1F9',
        label: '🇵🇹 Portugal',
        emoji: '🇵🇹',
    },
    {
        code: 'PW',
        unicode: 'U+1F1F5 U+1F1FC',
        label: '🇵🇼 Palau',
        emoji: '🇵🇼',
    },
    {
        code: 'PY',
        unicode: 'U+1F1F5 U+1F1FE',
        label: '🇵🇾 Paraguay',
        emoji: '🇵🇾',
    },
    {
        code: 'QA',
        unicode: 'U+1F1F6 U+1F1E6',
        label: '🇶🇦 Qatar',
        emoji: '🇶🇦',
    },
    {
        code: 'RE',
        unicode: 'U+1F1F7 U+1F1EA',
        label: '🇷🇪 Réunion',
        emoji: '🇷🇪',
    },
    {
        code: 'RO',
        unicode: 'U+1F1F7 U+1F1F4',
        label: '🇷🇴 Romania',
        emoji: '🇷🇴',
    },
    {
        code: 'RS',
        unicode: 'U+1F1F7 U+1F1F8',
        label: '🇷🇸 Serbia',
        emoji: '🇷🇸',
    },
    {
        code: 'RU',
        unicode: 'U+1F1F7 U+1F1FA',
        label: '🇷🇺 Russia',
        emoji: '🇷🇺',
    },
    {
        code: 'RW',
        unicode: 'U+1F1F7 U+1F1FC',
        label: '🇷🇼 Rwanda',
        emoji: '🇷🇼',
    },
    {
        code: 'SA',
        unicode: 'U+1F1F8 U+1F1E6',
        label: '🇸🇦 Saudi Arabia',
        emoji: '🇸🇦',
    },
    {
        code: 'SB',
        unicode: 'U+1F1F8 U+1F1E7',
        label: '🇸🇧 Solomon Islands',
        emoji: '🇸🇧',
    },
    {
        code: 'SC',
        unicode: 'U+1F1F8 U+1F1E8',
        label: '🇸🇨 Seychelles',
        emoji: '🇸🇨',
    },
    {
        code: 'SD',
        unicode: 'U+1F1F8 U+1F1E9',
        label: '🇸🇩 Sudan',
        emoji: '🇸🇩',
    },
    {
        code: 'SE',
        unicode: 'U+1F1F8 U+1F1EA',
        label: '🇸🇪 Sweden',
        emoji: '🇸🇪',
    },
    {
        code: 'SG',
        unicode: 'U+1F1F8 U+1F1EC',
        label: '🇸🇬 Singapore',
        emoji: '🇸🇬',
    },
    {
        code: 'SH',
        unicode: 'U+1F1F8 U+1F1ED',
        label: '🇸🇭 St. Helena',
        emoji: '🇸🇭',
    },
    {
        code: 'SI',
        unicode: 'U+1F1F8 U+1F1EE',
        label: '🇸🇮 Slovenia',
        emoji: '🇸🇮',
    },
    {
        code: 'SJ',
        unicode: 'U+1F1F8 U+1F1EF',
        label: '🇸🇯 Svalbard & Jan Mayen',
        emoji: '🇸🇯',
    },
    {
        code: 'SK',
        unicode: 'U+1F1F8 U+1F1F0',
        label: '🇸🇰 Slovakia',
        emoji: '🇸🇰',
    },
    {
        code: 'SL',
        unicode: 'U+1F1F8 U+1F1F1',
        label: '🇸🇱 Sierra Leone',
        emoji: '🇸🇱',
    },
    {
        code: 'SM',
        unicode: 'U+1F1F8 U+1F1F2',
        label: '🇸🇲 San Marino',
        emoji: '🇸🇲',
    },
    {
        code: 'SN',
        unicode: 'U+1F1F8 U+1F1F3',
        label: '🇸🇳 Senegal',
        emoji: '🇸🇳',
    },
    {
        code: 'SO',
        unicode: 'U+1F1F8 U+1F1F4',
        label: '🇸🇴 Somalia',
        emoji: '🇸🇴',
    },
    {
        code: 'SR',
        unicode: 'U+1F1F8 U+1F1F7',
        label: '🇸🇷 Suriname',
        emoji: '🇸🇷',
    },
    {
        code: 'SS',
        unicode: 'U+1F1F8 U+1F1F8',
        label: '🇸🇸 South Sudan',
        emoji: '🇸🇸',
    },
    {
        code: 'ST',
        unicode: 'U+1F1F8 U+1F1F9',
        label: '🇸🇹 São Tomé & Príncipe',
        emoji: '🇸🇹',
    },
    {
        code: 'SV',
        unicode: 'U+1F1F8 U+1F1FB',
        label: '🇸🇻 El Salvador',
        emoji: '🇸🇻',
    },
    {
        code: 'SX',
        unicode: 'U+1F1F8 U+1F1FD',
        label: '🇸🇽 Sint Maarten',
        emoji: '🇸🇽',
    },
    {
        code: 'SY',
        unicode: 'U+1F1F8 U+1F1FE',
        label: '🇸🇾 Syria',
        emoji: '🇸🇾',
    },
    {
        code: 'SZ',
        unicode: 'U+1F1F8 U+1F1FF',
        label: '🇸🇿 Swaziland',
        emoji: '🇸🇿',
    },
    {
        code: 'TA',
        unicode: 'U+1F1F9 U+1F1E6',
        label: '🇹🇦 Tristan da Cunha',
        emoji: '🇹🇦',
    },
    {
        code: 'TC',
        unicode: 'U+1F1F9 U+1F1E8',
        label: '🇹🇨 Turks & Caicos Islands',
        emoji: '🇹🇨',
    },
    {
        code: 'TD',
        unicode: 'U+1F1F9 U+1F1E9',
        label: '🇹🇩 Chad',
        emoji: '🇹🇩',
    },
    {
        code: 'TF',
        unicode: 'U+1F1F9 U+1F1EB',
        label: '🇹🇫 French Southern Territories',
        emoji: '🇹🇫',
    },
    {
        code: 'TG',
        unicode: 'U+1F1F9 U+1F1EC',
        label: '🇹🇬 Togo',
        emoji: '🇹🇬',
    },
    {
        code: 'TH',
        unicode: 'U+1F1F9 U+1F1ED',
        label: '🇹🇭 Thailand',
        emoji: '🇹🇭',
    },
    {
        code: 'TJ',
        unicode: 'U+1F1F9 U+1F1EF',
        label: '🇹🇯 Tajikistan',
        emoji: '🇹🇯',
    },
    {
        code: 'TK',
        unicode: 'U+1F1F9 U+1F1F0',
        label: '🇹🇰 Tokelau',
        emoji: '🇹🇰',
    },
    {
        code: 'TL',
        unicode: 'U+1F1F9 U+1F1F1',
        label: '🇹🇱 Timor-Leste',
        emoji: '🇹🇱',
    },
    {
        code: 'TM',
        unicode: 'U+1F1F9 U+1F1F2',
        label: '🇹🇲 Turkmenistan',
        emoji: '🇹🇲',
    },
    {
        code: 'TN',
        unicode: 'U+1F1F9 U+1F1F3',
        label: '🇹🇳 Tunisia',
        emoji: '🇹🇳',
    },
    {
        code: 'TO',
        unicode: 'U+1F1F9 U+1F1F4',
        label: '🇹🇴 Tonga',
        emoji: '🇹🇴',
    },
    {
        code: 'TR',
        unicode: 'U+1F1F9 U+1F1F7',
        label: '🇹🇷 Turkey',
        emoji: '🇹🇷',
    },
    {
        code: 'TT',
        unicode: 'U+1F1F9 U+1F1F9',
        label: '🇹🇹 Trinidad & Tobago',
        emoji: '🇹🇹',
    },
    {
        code: 'TV',
        unicode: 'U+1F1F9 U+1F1FB',
        label: '🇹🇻 Tuvalu',
        emoji: '🇹🇻',
    },
    {
        code: 'TW',
        unicode: 'U+1F1F9 U+1F1FC',
        label: '🇹🇼 Taiwan',
        emoji: '🇹🇼',
    },
    {
        code: 'TZ',
        unicode: 'U+1F1F9 U+1F1FF',
        label: '🇹🇿 Tanzania',
        emoji: '🇹🇿',
    },
    {
        code: 'UA',
        unicode: 'U+1F1FA U+1F1E6',
        label: '🇺🇦 Ukraine',
        emoji: '🇺🇦',
    },
    {
        code: 'UG',
        unicode: 'U+1F1FA U+1F1EC',
        label: '🇺🇬 Uganda',
        emoji: '🇺🇬',
    },
    {
        code: 'UM',
        unicode: 'U+1F1FA U+1F1F2',
        label: '🇺🇲 U.S. Outlying Islands',
        emoji: '🇺🇲',
    },
    {
        code: 'UN',
        unicode: 'U+1F1FA U+1F1F3',
        label: '🇺🇳 United Nations',
        emoji: '🇺🇳',
    },
    {
        code: 'US',
        unicode: 'U+1F1FA U+1F1F8',
        label: '🇺🇸 United States',
        emoji: '🇺🇸',
    },
    {
        code: 'UY',
        unicode: 'U+1F1FA U+1F1FE',
        label: '🇺🇾 Uruguay',
        emoji: '🇺🇾',
    },
    {
        code: 'UZ',
        unicode: 'U+1F1FA U+1F1FF',
        label: '🇺🇿 Uzbekistan',
        emoji: '🇺🇿',
    },
    {
        code: 'VA',
        unicode: 'U+1F1FB U+1F1E6',
        label: '🇻🇦 Vatican City',
        emoji: '🇻🇦',
    },
    {
        code: 'VC',
        unicode: 'U+1F1FB U+1F1E8',
        label: '🇻🇨 St. Vincent & Grenadines',
        emoji: '🇻🇨',
    },
    {
        code: 'VE',
        unicode: 'U+1F1FB U+1F1EA',
        label: '🇻🇪 Venezuela',
        emoji: '🇻🇪',
    },
    {
        code: 'VG',
        unicode: 'U+1F1FB U+1F1EC',
        label: '🇻🇬 British Virgin Islands',
        emoji: '🇻🇬',
    },
    {
        code: 'VI',
        unicode: 'U+1F1FB U+1F1EE',
        label: '🇻🇮 U.S. Virgin Islands',
        emoji: '🇻🇮',
    },
    {
        code: 'VN',
        unicode: 'U+1F1FB U+1F1F3',
        label: '🇻🇳 Vietnam',
        emoji: '🇻🇳',
    },
    {
        code: 'VU',
        unicode: 'U+1F1FB U+1F1FA',
        label: '🇻🇺 Vanuatu',
        emoji: '🇻🇺',
    },
    {
        code: 'WF',
        unicode: 'U+1F1FC U+1F1EB',
        label: '🇼🇫 Wallis & Futuna',
        emoji: '🇼🇫',
    },
    {
        code: 'WS',
        unicode: 'U+1F1FC U+1F1F8',
        label: '🇼🇸 Samoa',
        emoji: '🇼🇸',
    },
    {
        code: 'XK',
        unicode: 'U+1F1FD U+1F1F0',
        label: '🇽🇰 Kosovo',
        emoji: '🇽🇰',
    },
    {
        code: 'YE',
        unicode: 'U+1F1FE U+1F1EA',
        label: '🇾🇪 Yemen',
        emoji: '🇾🇪',
    },
    {
        code: 'YT',
        unicode: 'U+1F1FE U+1F1F9',
        label: '🇾🇹 Mayotte',
        emoji: '🇾🇹',
    },
    {
        code: 'ZA',
        unicode: 'U+1F1FF U+1F1E6',
        label: '🇿🇦 South Africa',
        emoji: '🇿🇦',
    },
    {
        code: 'ZM',
        unicode: 'U+1F1FF U+1F1F2',
        label: '🇿🇲 Zambia',
        emoji: '🇿🇲',
    },
    {
        code: 'ZW',
        unicode: 'U+1F1FF U+1F1FC',
        label: '🇿🇼 Zimbabwe',
        emoji: '🇿🇼',
    },
];
