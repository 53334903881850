import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import '../Skeleton.scss';

interface FilterCardProps {}

const FilterCardSkeleton: React.FC<FilterCardProps> = () => {
    return (
        <div className="filter_card_skeleton bg-grey-200">
            <Stack spacing={1}>
                <div className="d-flex justify-content-between">
                    <Skeleton className="mt-1 ml-2" variant="text" width={100} />
                    <Skeleton className="mt-1 mr-2" variant="text" width={63} />
                </div>
                <Skeleton className="mt-1 ml-2" variant="text" width={200} height={58} animation="wave" />
                <div className="filter_industry_card_skeleton bg-grey-200">
                    <Stack spacing={1}>
                        <Skeleton className="mt-2 ml-2" variant="text" width={100} />
                        <Skeleton className="mt-2 ml-2" variant="rectangular" animation="wave" height={42} />
                        <div className="d-flex mt-3">
                            <Skeleton className="mt-1 ml-2" variant="rectangular" width={18} height={18} />
                            <Skeleton className="mt-1 ml-2 w-100" variant="text" />
                        </div>
                        <div className="d-flex mt-3">
                            <Skeleton className="mt-1 ml-2" variant="rectangular" width={18} height={18} />
                            <Skeleton className="mt-1 ml-2 w-100" variant="text" />
                        </div>
                        <div className="d-flex mt-3">
                            <Skeleton className="mt-1 ml-2" variant="rectangular" width={18} height={18} />
                            <Skeleton className="mt-2 ml-2 w-100" variant="text" />
                        </div>
                        <div className="d-flex mt-3">
                            <Skeleton className="mt-1 ml-2" variant="rectangular" width={18} height={18} />
                            <Skeleton className="mt-1 ml-2 w-100" variant="text" />
                        </div>
                        <div className="d-flex mt-3 mb-2">
                            <Skeleton className="ml-2" variant="rectangular" width={18} height={18} />
                            <Skeleton className="ml-2 w-100" variant="text" />
                        </div>
                    </Stack>
                </div>
            </Stack>
        </div>
    );
};

export default FilterCardSkeleton;
