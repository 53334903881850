import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';

import { DoctIcon, DoctIconButton } from 'doct-core';
import { getMonth, getYear } from 'date-fns';
import FormHelperText from '@mui/material/FormHelperText';

import '../DatePickerV2.scss';

interface PropsDatePickerV2 {
    inputProps?: any;
    onChange?: any;
    onChangeController?: any;
    defaultValue?: any;
    id?: string;
    name?: string;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    control?: Control<Record<string, any>>;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    touched?: DeepMap<Record<string, any>, true>;
    validationRules?: any;
    showStar?: boolean;
    withController?: boolean;
    standaloneName?: string;
}

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const years = range(1900, getYear(new Date()) + 1, 1);
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const CoreDatePickerV2: React.FC<PropsDatePickerV2> = ({
    inputProps,
    onChange,
    onChangeController,
    defaultValue,
    id,
    name,
    disabled = false,
    readOnly = false,
    className,
    control,
    isErrors,
    touched,
    validationRules,
    showStar = false,
    standaloneName,
}) => {
    const { label, ...rest } = inputProps || {};

    const [startDate, setStartDate] = useState(null);

    useEffect(() => {
        setStartDate(defaultValue);
    }, [defaultValue]);

    return (
        <div
            className={
                startDate || defaultValue
                    ? `doct-input doct-date-picker-input-v2 doct-date-picker-input-v2-has-value ${className ?? ''}`
                    : `doct-input ${isErrors?.[standaloneName || name] ? 'hasError' : ''} doct-date-picker-input-v2 ${
                          className ?? ''
                      }`
            }
        >
            <div className="doct-date-picker-input-v2-base">
                <DatePicker
                    label="test"
                    name={name}
                    {...rest}
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    decreaseMonth();
                                }}
                                disabled={prevMonthButtonDisabled}
                            >
                                {'<'}
                            </button>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    increaseMonth();
                                }}
                                disabled={nextMonthButtonDisabled}
                            >
                                {'>'}
                            </button>
                        </div>
                    )}
                    selected={defaultValue || startDate}
                    onChange={(date) => {
                        if (onChange) {
                            onChange(date, name);
                        }
                        if (onChangeController) {
                            onChangeController(date);
                        }
                        setStartDate(date);
                    }}
                />
                <label
                    className={
                        defaultValue
                            ? 'doct-date-picker-input-v2__label doct-date-picker-input-v2__label-active'
                            : 'doct-date-picker-input-v2__label'
                    }
                >
                    {label} {showStar ? '*' : ''}
                </label>
                <span className="doct-date-picker-input-v2__hover-line"></span>
                <div className="doct-date-picker-input-v2__date-icon" onClick={() => {}}>
                    <DoctIcon name="date" width="24" height="24" />
                </div>
                <span
                    className="doct-date-picker-input-v2__clearable"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setStartDate(null);
                        if (onChange) onChange(null);
                        if (onChangeController) onChangeController(null);
                    }}
                >
                    <DoctIconButton variant="text" type="secondary" size="large" icon="close" />
                </span>
            </div>
            {isErrors?.[standaloneName || name] && (
                <FormHelperText error> {isErrors[standaloneName || name]?.message} </FormHelperText>
            )}
        </div>
    );
};

const DatePickerV2: React.FC<PropsDatePickerV2> = (props) => {
    const { withController = true } = props;

    if (withController) {
        return (
            <Controller
                rules={props.validationRules}
                render={({ onChange, value }) => {
                    return (
                        <CoreDatePickerV2
                            {...props}
                            onChangeController={onChange}
                            defaultValue={value}
                            isErrors={props.isErrors}
                            standaloneName={props.standaloneName}
                        />
                    );
                }}
                name={props?.name}
                control={props?.control}
            ></Controller>
        );
    }

    return <CoreDatePickerV2 {...props} defaultValue={props?.defaultValue} />;
};

export default DatePickerV2;
