import React from 'react';

import DoctChip from '../Chip/Chip';

interface FilterTagsProps {
    filters: any;
    handleCloseTag: (chipCategory: string, chipValue: unknown) => void;
}

const FilterTags: React.FC<FilterTagsProps> = ({ filters = [], handleCloseTag }) => {
    return filters.map((filter, i) => {
        const chipCategory = Object.keys(filter)[0];
        const chipValue = Object.values(filter)[0];
        return <DoctChip key={i} title={chipValue} onCloseHandler={() => handleCloseTag(chipCategory, chipValue)} />;
    });
};

export default FilterTags;
