import React from 'react';

import DoctCheckbox from '../Form/Checkbox/Checkbox';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function CheckboxLabels({ onChange, id, category, isChecked, count, name, truncateOn }) {
    return (
        <div className="d-flex w-100 filter-checkbox" title={name}>
            <DoctCheckbox
                onChange={(e) => onChange(e, category)}
                isChecked={isChecked}
                id={id}
                count={count}
                label={truncateOn ? (name.length > truncateOn ? `${name.substring(0, truncateOn)}...` : name) : name}
                category={category}
                className="w-100 flex-wrap"
            />
        </div>
    );
}
