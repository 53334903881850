import React from 'react';
import { DoctButton, DoctTypography } from 'doct-core';

import './NotFound.scss';

interface NotFoundProps {
    onClick: () => void;
    btnText?: string;
}

const NotFound: React.FC<NotFoundProps> = ({ onClick, btnText }) => {
    return (
        <div className="not-found-page">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                    <div className="text-content">
                        <DoctTypography className="text-grey-600" fontWeight="regular" variant="h6">
                            Oops! Error 404
                        </DoctTypography>
                        <DoctTypography fontWeight="medium" variant="h1">
                            Sorry this page does not exist
                        </DoctTypography>
                        <DoctButton text={btnText ? btnText : 'Back to Home'} onButtonClickHandler={onClick} />
                    </div>
                    <img width="100%" src="https://docthub.com/media/not-found-img.svg" alt="not-found" />
                </div>
            </div>
        </div>
    );
};

export default NotFound;
