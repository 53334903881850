///////////********** pagination props start **********///////////

let rowsPerPageOption: number;
let perPageOptionPropsOption: number[];
let pagination: boolean;
let totalCount: number;
let onPageChangeHandler: (page) => void;

export const selectPaginationProps = (paginationProps) => {
  if (paginationProps) {
    if (paginationProps.rowsPerPageProps && paginationProps.rowsPerPageProps) {
      rowsPerPageOption = paginationProps.rowsPerPageProps;
    } else if (
      paginationProps.rowsPerPageProps &&
      !paginationProps.rowsPerPageProps &&
      paginationProps.perPageOptionProps
    ) {
      rowsPerPageOption = paginationProps.perPageOptionProps[0];
    } else {
      rowsPerPageOption = 50;
    }

    if (
      paginationProps.perPageOptionProps &&
      paginationProps.perPageOptionProps
    ) {
      perPageOptionPropsOption = paginationProps.perPageOptionProps;
    } else {
      perPageOptionPropsOption = [50, 75, 100];
    }

    if (paginationProps.pagination && paginationProps.pagination) {
      pagination = paginationProps.pagination;
    }

    if (
      paginationProps.onPageChangeHandler &&
      paginationProps.onPageChangeHandler
    ) {
      onPageChangeHandler = paginationProps.onPageChangeHandler;
    }

    if (paginationProps.totalCount && paginationProps.totalCount) {
      totalCount = paginationProps.totalCount;
    }
  }

  return {
    rowsPerPageOption,
    perPageOptionPropsOption,
    pagination,
    totalCount,
    onPageChangeHandler
  };
};

///////////********** pagination props end **********///////////

///////////********** drawer props start **********///////////

export const selectRowProps = (rowProps) => {
  let onRowClick: boolean;
  let onRowClickHandler: (row) => void;

  if (rowProps) {
    if (rowProps.onRowClick && rowProps.onRowClick) {
      onRowClick = rowProps.onRowClick;
    }
    if (rowProps.onRowClickHandler) {
      onRowClickHandler = rowProps.onRowClickHandler;
    }
  }

  return {
    onRowClick,
    onRowClickHandler,
  };
};

///////////********** drawer props end **********///////////

export const statusClasses = {
  pending: "yellow-color font-weight-bold",
  confirmed: "black-color",
  cancelled: "dark-grey-color",
};
