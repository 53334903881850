import React from 'react';
import { Control, FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import DoctAutoComplete from '../AutoComplete/AutoComplete';
import { dialCodes } from '../../../Config/dialCode.js';
import { mappedOptions } from '../../../Config/utils.js';
import { TextField } from '@mui/material';

interface CountryDropdownProps {
    control: Control<Record<string, any>>;
    disabled?: boolean;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    validationRules?: any;
    name?: string;
    id?: string;
    label?: string;
}

const PhoneCodeDropdown: React.FC<CountryDropdownProps> = ({
    control,
    isErrors,
    validationRules,
    name = 'dial_code',
    id = 'dialCode',
    disabled = true,
    ...rest
}) => {
    const dialCodesOptions = mappedOptions(dialCodes);

    return disabled ? (
        <TextField
            id={id}
            name={name}
            value="+91"
            disabled
            InputProps={{
                readOnly: true,
            }}
            sx={{
                backgroundColor: '#e8e8e8',
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '& .Mui-disabled': {
                    backgroundColor: '#e8e8e8',
                    borderRadius: '6px',
                    padding: '6px',
                },
            }}
            {...rest}
        />
    ) : (
        <DoctAutoComplete
            name={name}
            id={id}
            control={control}
            isErrors={isErrors}
            options={dialCodesOptions}
            validationRules={validationRules}
            defaultValue={{
                name: 'India',
                value: '+91',
                countryCode: 'IN',
            }}
            optionLabel="value"
            {...rest}
        />
    );
};

export default PhoneCodeDropdown;
