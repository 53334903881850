import React from 'react';
import { DoctButton, DoctCol, DoctIcon, DoctRow, DoctTypography } from 'doct-core';
import { FeatureBoxResponsive } from './Components';

import './DoctNavigationMenuEnterpriseAppResponsive.scss';

// interface DoctNavigationMenuEnterpriseAppResponsiveProps {
//     //
// }

const DoctNavigationMenuEnterpriseResponsive: React.FC = ({}) => {
    const productList =
        typeof window !== 'undefined'
            ? [
                  {
                      href: window?.DoctUrls?.doct_recruiters_url,
                      title: 'Post a Job',
                      description: 'Recruiter Dashboard',
                      iconName: 'jobsEnterpriseFilled',
                  },
                  {
                      href: window?.DoctUrls?.doct_institutes_url,
                      title: 'List a Course',
                      description: 'Institute Dashboard',
                      iconName: 'courseEnterpriseFilled',
                  },
                  {
                      href: window?.DoctUrls?.doct_event_organizers,
                      title: 'Create an event',
                      description: 'Events Dashboard',
                      iconName: 'eventEnterpriseFilled',
                  },
                  {
                      href: `${window?.DoctUrls?.doct_membership_url}`,
                      title: 'Membership Management',
                      description: 'Association Management Solutions',
                      iconName: 'membershipOutline',
                      className: 'ml-2',
                  },
              ]
            : [];

    return (
        <div className="navigation-menu responsive-navigation-menu mt-5 bg-white">
            <div className="responsive-navigation-menu-enterprise pb-4 d-flex justify-content-center flex-wrap mx-auto">
                <div className="d-flex flex-wrap justify-content-center">
                    {productList.map((item, index) => (
                        <div
                            key={index}
                            className="d-flex flex-column align-items-center mb-4"
                            style={{
                                width: '45%', // Ensures two items per row with spacing
                                margin: '0 2.5%', // Adds horizontal spacing between items
                            }}
                        >
                            <a href={item?.href} className="text-decoration-none d-flex flex-column align-items-center">
                                <DoctIcon width="32" height="32" name={item?.iconName} />
                                <DoctTypography
                                    variant={window?.innerWidth < 768 ? 'textLabel1' : 'subtitle2'}
                                    className="mt-2 text-grey-800 font-weight-semibold text-center"
                                >
                                    {item?.title}
                                </DoctTypography>
                            </a>
                        </div>
                    ))}
                </div>

                <div>
                    <a
                        href={`${window?.DoctUrls?.doct_enterprise_url}/dashboard`}
                        className="responsive-navigation-menu-go-to-dashboard d-flex flex-column align-items-center mt-sm-2"
                    >
                        <DoctTypography
                            variant="textLabel2"
                            className="text-grey-800 p-2 font-weight-medium d-flex align-items-center enterprise-filled-border"
                        >
                            <DoctIcon name="enterpriseFilled" width="32" height="32" className="mr-2" /> What is Docthub
                            Enterprise?
                        </DoctTypography>
                    </a>
                    <div
                        style={{ backgroundColor: 'rgb(67 137 255 / 6%)', borderRadius: '20px' }}
                        className="p-3 mt-4 mx-n3"
                    >
                        <div className="d-flex align-items-center justify-content-around">
                            <DoctTypography
                                variant="subtitle2"
                                fontWeight="bold"
                                className="mr-2 text-primary-700 my-0"
                            >
                                Already a user?
                            </DoctTypography>

                            <div
                                className="text-center text-primary-800 p-2 font-weight-bold"
                                style={{ border: '2px solid #01505F', borderRadius: '22px' }}
                            >
                                <div className="cursor-pointer">
                                    <a
                                        href={`${window?.DoctUrls?.doct_enterprise_url}/dashboard`}
                                        className="text-primary-700"
                                    >
                                        Enterprise Login &gt;
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-around mt-3">
                            <DoctTypography variant="subtitle2" fontWeight="bold" className="text-primary-700 my-0">
                                New user?
                            </DoctTypography>
                            <a href={`${window?.DoctUrls?.doct_enterprise_account_url}`}>
                                <DoctButton text="Create Enterprise Account" type="semantic-info" size="medium" />
                            </a>
                        </div>
                        <DoctTypography variant="subtitle3" className="text-grey-500 text-center">
                            Docthub Enterprise account lets you Post jobs, List <br /> courses, Create events, Manage
                            Associations, Campus <br /> placements, E-certifications, All in one business <br />{' '}
                            account.
                        </DoctTypography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoctNavigationMenuEnterpriseResponsive;
