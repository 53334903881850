import React from 'react';
import { DoctTypography } from 'doct-core';

import './DoctEventCard.scss';

interface DoctCardProps {
    cardHeader?: React.ReactElement;
    cardBody?: React.ReactElement;
    cardFooter?: React.ReactElement;
    className?: string;
    imageSrc?: string;
    title: string;
    textLabel: string;
}

const DoctCard: React.FC<DoctCardProps> = ({
    cardHeader,
    cardBody,
    cardFooter,
    className,
    imageSrc,
    title,
    textLabel,
}) => {
    return (
        <div className={className ? className : ''}>
            <div className="doct-card doct-image-card bg-white h-100 d-flex flex-column">
                <div className="doct-card-image-wrapper overflow-hidden">
                    <img className="mw-100 w-100 h-100" src={imageSrc} alt={title} />
                </div>
                <div className="doct-card-header">{cardHeader}</div>
                <div className="doct-card-body">
                    <DoctTypography variant="subtitle1" className="m-0 text-grey-800">
                        {title}
                    </DoctTypography>
                    <DoctTypography variant="textLabel2" className="mb-0 text-label text-grey-600">
                        {textLabel}
                    </DoctTypography>
                    {cardBody}
                </div>
                <div className="doct-card-footer">{cardFooter}</div>
            </div>
        </div>
    );
};

export default DoctCard;
