import React, { ReactElement } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
// import Icon from '../Ui/Icons';

import DoctSnackbarMessage from './DoctSnackbarMessage';

import './DoctSnackbar.scss';

interface Props {
    title: string;
    type?: string;
    autoHideDuration?: number;
    showSnackbar: boolean;
}

export default function DoctAlert({ type, title, autoHideDuration, showSnackbar }: Props): ReactElement {
    const [open, setOpen] = React.useState(showSnackbar);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            // ? autoHideDuration : 5000
            autoHideDuration={autoHideDuration}
            // TransitionComponent
            onClose={handleClose}
            key={type}
            message={<DoctSnackbarMessage handleClose={handleClose} title={title} type={type} />}
            // action={
            //     <React.Fragment>
            //         <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            //             {/* <Icon name="close" width="24" /> */}
            //             icon
            //         </IconButton>
            //     </React.Fragment>
            // }
        />
    );
}
