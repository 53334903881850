import React from 'react';

import DoctModal from '../../Modal/DoctModal';
import { DoctTypography } from 'doct-core';

import '../Static.scss';

interface TermsOfServiceProps {
    handleClose: () => void;
    open: boolean;
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({ handleClose, open }) => {
    return (
        <DoctModal
            className="privacy-policy-modal static-modal"
            title="Terms of Use"
            width={572}
            showFooter={false}
            open={open}
            handleClose={handleClose}
        >
            <DoctTypography variant="body2" className="text-grey-600">
                Docthub Health Tech Private Limited (“Docthub”) is the author and publisher of the internet resource
                www.docthub.com (“Website”). Docthub owns and operates the services provided through the Website and
                mobile applications.
            </DoctTypography>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                1. NATURE AND APPLICABILITY OF TERMS OF USE:
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <>
                    Please carefully go through these terms of use (“Terms”) and the privacy policy available at
                    www.docthub.com (“Privacy Policy”) before you decide to access the Website or Mobile app or avail
                    the services made available on the Website and Mobile app by Docthub. These Terms of Use and the
                    Privacy Policy together constitute a legal agreement (“Agreement”) between you and Docthub in
                    connection with your visit to the Website, Mobile app and your use of the Services (as defined
                    below).
                </>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The Agreement applies to you whether you are -
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        a) A student, professional or service provider (whether an individual professional or an
                        organization) or similar institution wishing to be listed, or already listed, on the Docthub,
                        including designated, authorized associates of such professionals or institutions 
                        <b>(“Professional(s)”, “you” or “User”);</b> or
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        b) A person, his/her representatives or affiliates, searching for any information , materials,
                        knowledge sharing, article, reviews, doctor, hospital through the Docthub 
                        <b>(“End-User”, “you” or “User”);</b> or
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        c) Otherwise a user of the Website <b>(“you” or “User”)</b>. This Agreement applies to those
                        services made available by Docthub on the Website and Mobile app, which are offered free of
                        charge as well as chargeable to the Users <b>(“Services”)</b>, including the <b>following</b>:
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        d) <b>COURSES:</b> Includes listing of courses of Bachelor, Master, Diploma, PG Diploma
                        Certificate, PG Certificate, fellowships, Observerships, Internships, Preceptorship, Online
                        Course, Hands-On Training, Training Course, PhD Degrees, Doctorate and more. User can apply
                        online for the course, pay a fees to any partnered Organisation listed on the Docthub, however
                        Docthub do not take any legal responsibility on behalf of its partnered or listed Organisation
                        on its platform.
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        e) <b>EVENTS:</b> The application has an aggregator platform for different types of healthcare
                        events; Conference, Workshops, Seminars, Exhibitions, Expo, Webinars, CMEs, CPDs, Master
                        Classes, Consortium, Meet-Ups in different healthcare specialties and disciplines. Its partnered
                        Organisation listed its events on Docthub, for users to register tickets online through Docthub
                        platform. However, the event’s responsibility, invoice, payment refund or other administrative
                        concerns are directly managed by the event Organisation, Docthub is not liable for any such
                        concerns.
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        f) <b>JOBS:</b> The application is an aggregator platform for healthcare jobs vacancy listings
                        globally in different sectors, job categories and functional areas. The job posts are listed
                        through its partnered organisations and by Docthub team as received information of Job Vacancies
                        from different reliable sources. However, docthub shall not be responsible for authentication of
                        these information. By applying to any job, the user application details are send to recruiter or
                        organization who have posted that job vacancy through email or Docthub Dashboard. The further
                        recruitment, interview and selection process is supposed to do by recruiter or organization’s
                        concern person, Docthub is not taking any responsibility for any such process.
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        g) The Users have the facility to (i) create and maintain ‘User Accounts’, (ii) search for
                        Individual Users/ hospitals/ institute by name, specialty, and geographical area, or any other
                        criteria that may be developed and made available by Docthub, (iii) to make an request/ post an
                        job application or requirement announcement to publish among the users or visitors of the
                        website, and (iv) download the standard medical templates/ documentations/ policies etc.
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        h) The Services may change from time to time, at the sole discretion of Docthub, and the
                        Agreement will apply to your visit to and your use of the Website to avail the Service, as well
                        as to all information provided by you on the Website at any given point in time.
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    i) This Agreement defines the terms of use under which you are allowed to use the Website and
                    describes the manner in which we shall treat your account while you are registered as a member with
                    us. If you have any questions about any part of the Agreement, feel free to contact us at{' '}
                    <a href="mailto: office@docthub.com"> office@docthub.com.</a>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    j) By downloading or accessing the Website to use the Services, you irrevocably accept all the
                    conditions stipulated in this Agreement, the Terms of Use and Privacy Policy as available on the
                    Website, and agree to abide by them. This Agreement supersedes all previous oral and written terms
                    and conditions (if any) communicated to you relating to your use of the Website to avail the
                    Services. By availing any Service, you signify your acceptance of the terms of this Agreement.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    k) We reserve the right to modify or terminate any portion of the Agreement for any reason and at
                    any time, and such modifications shall be informed to you via email or SMS, You should read the
                    Agreement at regular intervals. Your use of the Website following any such modification constitutes
                    your agreement to follow and be bound by the Agreement so modified.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        l) You acknowledge that you will be bound by this Agreement for availing any of the Services
                        offered by us. If you do not agree with any part of the Agreement, please do not use the Website
                        or avail any Services.
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    m) Your access to use of the Website and the Services will be solely at the discretion of Docthub.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    n) The Agreement is published in compliance of, and is governed by the provisions of Indian law,
                    including but not limited to:
                    <blockquote>- the Indian Contract Act, 1872,</blockquote>
                    <blockquote>- the (Indian) Information Technology Act, 2000, and </blockquote>
                    <blockquote>
                        - the rules, regulations, guidelines and clarifications framed there under, including the
                        (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive
                        Personal Information) Rules, 2011 (the <b>“SPI Rules”</b>), and the (Indian) Information
                        Technology (Intermediaries Guidelines) Rules, 2011 (the <b>“IG Rules”</b>).{' '}
                    </blockquote>
                </DoctTypography>
            </ul>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                2. CONDITIONS OF USE:
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                You must be 18 years of age or older to register, use the Services, or visit or use the Website in any
                manner. By registering, visiting and using the Website or accepting this Agreement, you represent and
                warrant to Docthub that you are 18 years of age or older, and that you have the right, authority and
                capacity to use the Website and the Services available through the Website, and agree to and abide by
                this Agreement.
            </DoctTypography>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                3. TERMS OF USE APPLICABLE TO ALL USERS:
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The terms in this Clause 3 are applicable only to Users.
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    3.1. END-USER ACCOUNT AND DATA PRIVACY
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.1.1. The terms “personal information” and “sensitive personal data or information” are defined
                        under the SPI Rules, and are reproduced in the Privacy Policy.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.1.2. Docthub may by its Services, collect information relating to the devices through which
                        you access the Website, and anonymous data of your usage. The collected information will be used
                        only for improving the quality of Docthub’s services and to build new services.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.1.3. The Website allows Docthub to have access to registered Users’ personal email or phone
                        number, for communication purpose so as to provide you a better way of website services and for
                        obtaining feedback in relation to the Individual Users and their practice.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.1.4. The Privacy Policy sets out, inter-alia:
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        The type of information collected from Users, including sensitive personal data or information;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        The purpose, means and modes of usage of such information;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        How and to whom Docthub will disclose such information; and,
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        Other information mandated by the SPI Rules
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.1.5. The User is expected to read and understand the Privacy Policy, so as to ensure that he
                        or she has the knowledge of, inter-alia:
                    </DoctTypography>
                    <ul>
                        <DoctTypography variant="list" className="text-grey-600">
                            i. the fact that certain information is being collected;
                        </DoctTypography>
                        <DoctTypography variant="list" className="text-grey-600">
                            ii. the purpose for which the information is being collected;
                        </DoctTypography>
                        <DoctTypography variant="list" className="text-grey-600">
                            iii. the intended recipients of the information;
                        </DoctTypography>
                        <DoctTypography variant="list" className="text-grey-600">
                            iv. the nature of collection and retention of the information; and
                        </DoctTypography>
                        <DoctTypography variant="list" className="text-grey-600">
                            v. The name and address of the agency that is collecting the information and the agency that
                            will retain the information; and
                        </DoctTypography>
                        <DoctTypography variant="list" className="text-grey-600">
                            vi. The various rights available to such Users in respect of such information.
                        </DoctTypography>
                    </ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.1.6. Docthub shall not be responsible in any manner for the authenticity of the personal
                        information or sensitive personal data or information supplied by the User to Docthub or to any
                        other person acting on behalf of Docthub.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.1.7. The User is responsible for maintaining the confidentiality of the User’s account access
                        information and password, if the User is registered on the Website. The User shall be
                        responsible for all usage of the User’s account and password, whether or not authorized by the
                        User. The User shall immediately notify Docthub of any actual or suspected unauthorized use of
                        the User’s account or password. Although Docthub will not be liable for your losses caused by
                        any unauthorized use of your account, you may be liable for the losses of Docthub or such other
                        parties as the case may be, due to any unauthorized use of your account.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.1.8. If a User provides any information that is untrue, inaccurate, not current or incomplete
                        (or becomes untrue, inaccurate, not current or incomplete), or Docthub has reasonable grounds to
                        suspect that such information is untrue, inaccurate, not current or incomplete, Docthub has the
                        right to discontinue the Services to the User at its sole discretion.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.1.9. Docthub may use such information collected from the Users from time to time for the
                        purposes of debugging customer support related issues.
                    </DoctTypography>
                </ul>
                <DoctTypography variant="list" className="text-grey-600">
                    3.2. END-USER ACCOUNT AND DATA PRIVACY
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.2.1. The Services provided by Docthub or any of its licensors or service providers are
                        provided on an “as is” and “as available’ basis, and without any warranties or conditions
                        (express or implied, including the implied warranties of merchantability, accuracy, fitness for
                        a particular purpose, title and non-infringement, arising by statute or otherwise in law or from
                        a course of dealing or usage or trade). Docthub does not provide or make any representation,
                        warranty or guarantee, express or implied about the Website or the Services. Docthub does not
                        guarantee the accuracy or completeness of any content or information provided by Users on the
                        Website. To the fullest extent permitted by law, Docthub disclaims all liability arising out of
                        the User’s use or reliance upon the Website, the Services, representations and warranties made
                        by other Users or Service Providers, the content or information provided by the Users on the
                        Website, or any opinion or suggestion given or expressed by Docthub or any User in relation to
                        any User or services provided by such User or Service Provider.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.2.2. The Website may be linked to the website of third parties, affiliates and business
                        partners. Docthub has no control over, and not liable or responsible for content, accuracy,
                        validity, reliability, quality of such websites or made available by/through our Website.
                        Inclusion of any link on the Website does not imply that Docthub endorses the linked site. User
                        may use the links and these services at User’s own risk.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.2.3. Docthub assumes no responsibility, and shall not be liable for, any damages to, or
                        viruses that may infect User’s equipment on account of User’s access to, use of, or browsing the
                        Website or the downloading of any material, data, text, images, video content, or audio content
                        from the Website. If a User is dissatisfied with the Website, User’s sole remedy is to
                        discontinue using the Website.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.2.4. If Docthub determines that you have provided fraudulent, inaccurate, or incomplete
                        information, including through feedback, Docthub reserves the right to immediately suspend your
                        access to the Website or any of your accounts with Docthub and makes such declaration on the
                        website alongside your name/your clinic/ hospitals/ institute’s name as determined by Docthub
                        for the protection of its business and in the interests of Users. You shall be liable to
                        indemnify Docthub for any losses incurred as a result of your misrepresentations or fraudulent
                        feedback that has adversely affected Docthub or its Users.
                    </DoctTypography>
                </ul>
                <DoctTypography variant="list" className="text-grey-600">
                    3.3. CONTENT OWNERSHIP AND COPYRIGHT CONDITIONS OF ACCESS:
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        3.3.1. The contents listed on the Website are (i) User generated content, or (ii) belong to
                        Docthub. The information that is collected by Docthub directly or indirectly from the End- Users
                        and the Individual Users/ hospitals/ institutes shall belong to Docthub. Copying of the
                        copyrighted content published by Docthub on the Website for any commercial purpose or for the
                        purpose of earning profit will be a violation of copyright and Docthub reserves its rights under
                        applicable law accordingly.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        <>
                            3.3.2. Docthub authorizes the User to view and access the content available on or from the
                            Website solely for ordering, receiving, delivering and communicating only as per this
                            Agreement. The contents of the Website, information, text, graphics, images, logos, button
                            icons, software code, design, and the collection, arrangement and assembly of content on the
                            Website (collectively, <b>“Docthub Content”</b> ), are the property of Docthub and are
                            protected under copyright, trademark and other laws. User shall not modify the Docthub
                            Content or reproduce, display, publicly perform, distribute, or otherwise use the Docthub
                            Content in any way for any public or commercial purpose or for personal gain.
                        </>
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        <>
                            User shall not access the Services for purposes of monitoring their availability,
                            performance or functionality, or for any other benchmarking or competitive purposes.
                        </>
                    </DoctTypography>
                </ul>
                <DoctTypography variant="list" className="text-grey-600">
                    3.4. REVIEWS AND FEEDBACK:
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        By using this Website, you agree that any information shared by you with Docthub or with any
                        doctor/ hospital/ institute/ clinic will be subject to our Privacy Policy.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        You are solely responsible for the content that you choose to submit for publication on the
                        Website, including any feedback, ratings, or reviews (“Critical Content”) relating to Individual
                        Users, hospitals, institutes, clinics or other Individual Users. The role of Docthub in
                        publishing Critical Content is restricted to that of an ‘intermediary’ under the Information
                        Technology Act, 2000. Docthub disclaims all responsibility with respect to the content of
                        Critical Content, and its role with respect to such content is restricted to its obligations as
                        an ‘intermediary’ under the said Act. Docthub shall not be liable to pay any consideration to
                        any User for re-publishing or downloading any content across any of its platforms.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        Your publication of reviews and feedback on the Website is governed by Clause 5 of these Terms.
                        Without prejudice to the detailed terms stated in Clause 5, you hereby agree not to post or
                        publish any content on the Website that (a) infringes any third-party intellectual property or
                        publicity or privacy rights, or (b) violates any applicable law or regulation, including but not
                        limited to the IG Rules and SPI Rules. Docthub, at its sole discretion, may choose not to
                        publish your reviews and feedback, if so required by applicable law, and in accordance with
                        Clause 5 of these Terms. You agree that Docthub may contact you through telephone, email, SMS,
                        or any other electronic means of communication for the purpose of:
                    </DoctTypography>
                    <ul>
                        <DoctTypography variant="list" className="text-grey-600">
                            a. Obtaining feedback in relation to Website or Docthub’s services; and/or
                        </DoctTypography>
                        <DoctTypography variant="list" className="text-grey-600">
                            b. Obtaining feedback in relation to any Individual Users, hospitals, institute, clinics
                            listed on the Website; and/or
                        </DoctTypography>
                        <DoctTypography variant="list" className="text-grey-600">
                            c. Resolving any complaints, information, or queries by users regarding your Critical
                            Content;
                        </DoctTypography>
                    </ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        And you agree to provide your fullest co-operation further to such communication by Docthub.
                        Docthub’s Feedback Collection and Fraud Detection Policy, is annexed as the Schedule hereto, and
                        remains subject always to these Terms.
                    </DoctTypography>
                </ul>
            </ul>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                4. TERMS OF USE FOR INDIVIDUAL USERS:
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The terms in this Clause 4 are applicable only to Individual Users.
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    4.1. PROFILE OWNERSHIP AND EDITING RIGHTS
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Docthub ensures easy access to the Individual Users by providing a tool to update your profile
                    information. Docthub reserves the right of ownership of all the individual user’s profile and
                    photographs and to moderate the changes or updates requested by Individual Users. However, Docthub
                    takes the independent decision whether to publish or reject the requests submitted for the
                    respective changes or updates. You hereby represent and warrant that you are fully entitled under
                    law to upload all content uploaded by you as part of your profile or otherwise while using Docthub’s
                    services, and that no such content breaches any third party rights, including intellectual property
                    rights. Upon becoming aware of a breach of the foregoing representation, Docthub may modify or
                    delete parts of your profile information at its sole discretion with or without notice to you.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    4.2. PROFILE OWNERSHIP AND EDITING RIGHTS
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        <>
                            4.2.1. All Critical Content is content created by the Users of{' '}
                            <a href="https://www.docthub.com/">www.Docthub.com</a> (“Website”) and the clients of
                            Docthub customers and Individual Users and Providers, including the End-Users. As a
                            platform, Docthub does not take responsibility for Critical Content and its role with
                            respect to Critical Content is restricted to that of an ‘intermediary’ under the Information
                            Technology Act, 2000. The role of Docthub and other legal rights and obligations relating to
                            the Critical Content are further detailed in Clauses 5 of these Terms. Docthub’s Feedback
                            Collection and Fraud Detection Policy, is annexed as the Schedule hereto, and remains
                            subject always to these Terms.
                        </>
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        4.2.2. Docthub reserves the right to collect feedback and Critical Content for all the Medical
                        Students, Resident Doctors, Medical Practitioners, Individual Users, Clinics and Healthcare
                        Providers listed on the Website.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        4.2.3. Docthub shall have no obligation to pre-screen, review, flag, filter, modify, refuse or
                        remove any or all Critical Content from any Service, except as required by applicable law.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        4.2.4. You understand that by using the Services you may be exposed to Critical Content or other
                        content that you may find offensive or objectionable. Docthub shall not be liable for any effect
                        on Healthcare Professional and Provider’s business due to Critical Content of a negative nature.
                        In these respects, you may use the Service at your own risk. Docthub however, as an
                        ‘intermediary, takes steps as required to comply with applicable law as regards the publication
                        of Critical Content. The legal rights and obligations with respect to Critical Content and any
                        other information sought to be published by Users are further detailed in 5 of these Terms.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        4.2.5. Docthub will take down information under standards consistent with applicable law, and
                        shall in no circumstances be liable or responsible for Critical Content, which has been created
                        by the Users. The principles set out in relation to third party content in the terms of Service
                        for the Website shall be applicable mutatis mutandis in relation to Critical Content posted on
                        the Website.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        4.2.6. If Docthub determines that you have provided inaccurate information or enabled fraudulent
                        feedback, Docthub reserves the right to immediately suspend any of your accounts with Docthub
                        and makes such declaration on the website alongside your name/your hospitals/ institute/ clinics
                        name as determined by Docthub for the protection of its business and in the interests of Users.
                    </DoctTypography>
                </ul>
                <DoctTypography variant="list" className="text-grey-600">
                    4.3. RELEVANCE ALGORITHM
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Docthub has designed the relevance algorithm in the best interest of the Users/ End-User/ Service
                    Providers and may adjust the relevance algorithm from time to time to improve the quality of the
                    results given to the users. It is a pure merit driven, proprietary algorithm which cannot be altered
                    for specific Individual Users or Providers. Docthub shall not be liable for any effect on the
                    Healthcare Professional or Provider’s business interests due to the change in the Relevance
                    Algorithm.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    4.4. INDEPENDENT SERVICES
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Your use of each Service confers upon you only the rights and obligations relating to such Service,
                    and not to any other service that may be provided by Docthub.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    4.5. DOCTHUB REACH RIGHTS
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Docthub reserves the rights to display sponsored ads on the Website. These ads would be marked as
                    “Ads”. Without prejudice to the status of other content, Docthub will not be liable for the accuracy
                    of information or the claims made in the Sponsored ads. Docthub does not encourage the Users to
                    visit the Sponsored ads page or to avail any services from them. Docthub will not be liable for the
                    services of the providers of the Sponsored ads.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    You represent and warrant that you will use these Services in accordance with applicable law. Any
                    contravention of applicable law as a result of your use of these Services is your sole
                    responsibility, and Docthub accepts no liability for the same.
                </DoctTypography>
            </ul>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                5. RIGHTS AND OBLIGATIONS RELATING TO CONTENT
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    5.1. As mandated by Regulation 3(2) of the IG Rules, Docthub hereby informs Users that they are not
                    permitted to host, display, upload, modify, publish, transmit, update or share any information that:
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        A. belongs to another person and to which the User does not have any right to;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        B. is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic,
                        libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable,
                        disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any
                        manner whatever;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        C. harm minors in any way;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        D. infringes any patent, trademark, copyright or other proprietary rights;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        E. violates any law for the time being in force;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        F. deceives or misleads the addressee about the origin of such messages or communicates any
                        information which is grossly offensive or menacing in nature;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        G. impersonate another person;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        H. contains software viruses or any other computer code, files or programs designed to
                        interrupt, destroy or limit the functionality of any computer resource;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        I. threatens the unity, integrity, defense, security or sovereignty of India, friendly relations
                        with foreign states, or public order or causes incitement to the commission of any cognizable
                        offence or prevents investigation of any offence or is insulting any other nation.
                    </DoctTypography>
                </ul>
                <DoctTypography variant="list" className="text-grey-600">
                    5.2. Users are also prohibited from:
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        A. violating or attempting to violate the integrity or security of the Website or any Docthub
                        Content;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        B. transmitting any information (including job posts, messages and hyperlinks) on or through the
                        Website that is disruptive or competitive to the provision of Services by Docthub;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        C. intentionally submitting on the Website any incomplete, false or inaccurate information;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        D. making any unsolicited communications to other Users;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        E. using any engine, software, tool, agent or other device or mechanism (such as spiders,
                        robots, avatars or intelligent agents) to navigate or search the Website;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        F. attempting to decipher, decompile, disassemble or reverse engineer any part of the Website;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        G. copying or duplicating in any manner any of the Docthub Content or other information
                        available from the Website;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        H. Framing or hot linking or deep linking any Docthub Content.
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        I. Circumventing or disabling any digital rights management, usage rules, or other security
                        features of the Software.
                    </DoctTypography>
                </ul>
                <DoctTypography variant="list" className="text-grey-600">
                    5.3. Docthub, upon obtaining knowledge by itself or been brought to actual knowledge by an affected
                    person in writing or through email signed with electronic signature about any such information as
                    mentioned above, shall be entitled to disable such information that is in contravention of Clauses
                    5.1 and 5.2. Docthub shall also be entitled to preserve such information and associated records for
                    at least 90 (ninety) days for production to governmental authorities for investigation purposes.
                </DoctTypography>
            </ul>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                6. TERMINATION
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    6.1. Docthub, upon obtaining knowledge by itself or been brought to actual knowledge by an affected
                    person in writing or through email signed with electronic signature about any such information as
                    mentioned above, shall be entitled to disable such information that is in contravention of Clauses
                    5.1 and 5.2. Docthub shall also be entitled to preserve such information and associated records for
                    at least 90 (ninety) days for production to governmental authorities for investigation purposes.
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        A. Such User breaches any terms and conditions of the Agreement;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        B. A third party reports violation of any of its right as a result of your use of the Services;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        C. Docthub is unable to verify or authenticate any information provide to Docthub by a User;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        D. Docthub has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on
                        part of such User; or
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        E. Docthub believes in its sole discretion that User’s actions may cause legal liability for
                        such User, other Users or for Docthub or are contrary to the interests of the Website.
                    </DoctTypography>
                </ul>
                <DoctTypography variant="list" className="text-grey-600">
                    6.2. Once temporarily suspended, indefinitely suspended or terminated, the User may not continue to
                    use the Website under the same account, a different account or re-register under a new account. On
                    termination of an account due to the reasons mentioned herein, such User shall no longer have access
                    to data, messages, files and other material kept on the Website by such User. The User shall ensure
                    that he/she/it has continuous backup of any medical services the User has rendered in order to
                    comply with the User’s record/ information data keeping process and practices.
                </DoctTypography>
            </ul>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                7. LIMITATION OF LIABILITY
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                In no event, including but not limited to negligence, shall Docthub, or any of its directors, officers,
                employees, agents or content or service providers (collectively, the “Protected Entities”) be liable for
                any direct, indirect, special, incidental, consequential, exemplary or punitive damages arising from, or
                directly or indirectly related to, the use of, or the inability to use, the Website or the content,
                materials and functions related thereto, the Services, User’s provision of information via the Website,
                lost business or lost End-Users, even if such Protected Entity has been advised of the possibility of
                such damages. In no event shall the Protected Entities be liable for:
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    a. provision of or failure to provide all or any service by Individual Users or Providers to Users
                    contacted or managed through the Website;
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    b. any content posted, transmitted, exchanged or received by or on behalf of any User or other
                    person on or through the Website;
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    c. any unauthorized access to or alteration of your transmissions or data; or
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    d. Any other matter relating to the Website or the Service.
                </DoctTypography>
            </ul>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                8. RETENTION AND REMOVAL
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                Docthub may retain such information collected from Users from its Website or Services for as long as
                necessary, depending on the type of information; purpose, means and modes of usage of such information;
                and according to the SPI Rules. Computer web server logs may be preserved as long as administratively
                necessary.
            </DoctTypography>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                9. APPLICABLE LAW AND DISPUTE SETTLEMENT:
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    9.1. You agree that this Agreement and any contractual obligation between Docthub and User will be
                    governed by the laws of India.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    9.2. Any dispute, claim or controversy arising out of or relating to this Agreement, including the
                    determination of the scope or applicability of this Agreement to arbitrate, or your use of the
                    Website or the Services or information to which it gives access, shall be determined by arbitration
                    in India, before a sole arbitrator appointed by Docthub. Arbitration shall be conducted in
                    accordance with the Arbitration and Conciliation Act, 1996. The seat of such arbitration shall be
                    Rajkot. All proceedings of such arbitration, including, without limitation, any awards, shall be in
                    the English language. The award shall be final and binding on the parties to the dispute.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    9.3. Subject to the above Clause 9.2, the courts at Rajkot shall have exclusive jurisdiction over
                    any disputes arising out of or in relation to this Agreement, your use of the Website or the
                    Services or the information to which it gives access.
                </DoctTypography>
            </ul>
            <DoctTypography variant="subtitle1" className="text-grey-600" fontWeight="bold">
                10. Docthub “Dos and Don’ts”
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    10. Dos:
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        a. Comply with all applicable laws, including, without limitation, privacy laws, intellectual
                        property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        b. Provide accurate information to us and keep it updated;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        c. Use your real name on your profile; and
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        d. Use the Services in a professional manner
                    </DoctTypography>
                </ul>
                <DoctTypography variant="list" className="text-grey-600">
                    10. Don’ts:
                </DoctTypography>
                <ul>
                    <DoctTypography variant="list" className="text-grey-600">
                        <b>You agree that you will not: </b>
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        a. Create a false identity on Docthub, misrepresent your identity, create a Member profile for
                        anyone other than yourself (a real person), or use or attempt to use another’s account;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        b. Develop, support or use software, devices, scripts, robots, or any other means or processes
                        (including crawlers, browser plugins and add-ons, or any other technology) to scrape the
                        Services or otherwise copy profiles and other data from the Services;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        c. Override any security feature or bypass or circumvent any access controls or use limits of
                        the Service (such as caps on keyword searches or profile views);
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        d. Copy, use, disclose or distribute any information obtained from the Services, whether
                        directly or through third parties (such as search engines), without the consent of Docthub;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        e. Disclose information that you do not have the consent to disclose (such as confidential
                        information of others (including your employer/ institute/ organization/ college/ association/
                        society));
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        f. Violate the intellectual property rights of others, including copyrights, patents,
                        trademarks, trade secrets, or other proprietary rights. For example, do not copy or distribute
                        (except through the available sharing functionality) the posts or other content of others
                        without their permission, which they may give by posting under a Creative Commons license;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        g. Violate the intellectual property or other rights of Docthub, including, without limitation,
                        (i) copying or distributing our learning videos or other materials or (ii) copying or
                        distributing our technology, unless it is released under open source licenses; (iii) using the
                        word “Docthub” or our logos in any business name, email, or URL;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        h. Post anything that contains software viruses, worms, or any other harmful code;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        i. Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source
                        code for the Services or any related technology that is not open source;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        j. Imply or state that you are affiliated with or endorsed by Docthub without our express
                        consent (e.g., representing yourself as an accredited Docthub trainer);
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        k. Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or
                        access to the same, without Docthub’s consent;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        l. Deep-link to our Services for any purpose other than to promote your profile or activity or
                        academic course or event or a Group on our Services, without Docthub’s consent;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        m. Use bots or other automated methods to access the Services, add or download contacts, send or
                        redirect messages;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        n. Monitor the Services’ availability, performance or functionality for any competitive purpose;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        o. Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the
                        Services;
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        p. Overlay or otherwise modify the Services or their appearance (such as by inserting elements
                        into the Services or removing, covering, or obscuring an advertisement included on the
                        Services);
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        q. Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam,
                        denial of service attack, viruses, gaming algorithms); and/or
                    </DoctTypography>
                    <DoctTypography variant="list" className="text-grey-600">
                        r. Violate the Privacy Policy or any additional terms concerning a specific Service that are
                        provided when you sign up for or start using such Service.
                    </DoctTypography>
                </ul>
            </ul>
            <DoctTypography variant="subtitle1" className="text-grey-600 text-uppercase">
                11. PUBLISHING ANY MATERIAL ON DOCTHUB PLATFORM:
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                Docthub provide the publishing platform a superlative platform for healthcare and/or medical
                professionals to empower your professional footprint by sharing knowledge. This guidelines has to use
                while using this platform by all Users or Entity (Organization/ institute/ college society/
                association);
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                Content published on Docthub's platform remains your work. You own the rights to any original articles
                you publish.
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    11.1. You can request to delete any of your published content on our platform at any time.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    11.2. Docthub can distribute your content, annotate your content (e.g., to highlight that your views
                    may not be the views of Docthub), and sell advertisement on pages where your content appears.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    11.3. Expect that your articles will be publicly available and can be shared.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    11.4. Postings for job openings or for job opportunities or academic information or information
                    related to academic courses like notices, fee, bond, stipend, exam, result, dates are don't belong
                    to our publishing platform.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    11.5. Advertisements and promotions for events, products, or services aren't appropriate content for
                    article publishing. Advertisements can be acquired using Docthub Marketing services.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    11.6. Remember to be professional and don't post anything misleading, fraudulent, obscene,
                    threatening, hateful, defamatory, discriminatory, or illegal.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    11.7. You're responsible for the content of your articles, including any harm caused by you to
                    others, or harm caused to you through your use of this service or harm to any entity (Organization/
                    enterprise/ company/ institute/ college/ association/ society/NGO/ Hospital/Clinic).
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        11.8. Docthub may restrict, suspend, or terminate your Docthub account and/or disable your
                        articles for any violation of the Term of Use Agreement. Please refer to our <b>Terms of Use</b>
                        for full details.
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    11.9. Docthub will disable accounts found using infringing content.
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="text-grey-600">
                Don't publish anything you don't have permission to share. This includes other people or institute or
                organization's articles, information that you've found on the Internet, or content that belongs to your
                employer/ institute/ organization but not you. Most content on the Internet belongs to someone, and
                unless you have clear permission from the owner to share it, you shouldn't include it in your articles
                or publication. You can republish something that you have published somewhere else, as long as it is
                original content that you own its rights to.
            </DoctTypography>
            <DoctTypography variant="subtitle1" className="text-grey-600">
                12. CONTACT INFORMATION GRIEVANCE OFFICER
            </DoctTypography>
            <ul className="static-list">
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        12.1. If a User has any questions concerning Docthub, the Website, this Agreement, the Services,
                        or anything related to any of the foregoing, Docthub customer support can be reached at the
                        following email address: office@docthub.com or via the contact information available from the
                        following hyperlink: <a href="https://www.docthub.com/">www.docthub.com</a>
                    </>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <>
                        12.2. In accordance with the Information Technology Act, 2000, and the rules made there under,
                        if you have any grievance with respect to the Website or the service, including any
                        discrepancies and grievances with respect to processing of information, you can contact at:
                        <address>
                            <b>Grievance Officer</b> <br />
                            Docthub Health Tech Private Limited
                            <br />
                            202, GNFC Info Tower, Near Pakwan Cross Road, SG Highway
                            <br /> Ahmedabad. 380054. Gujarat. India.
                            <br />
                            Telephone: (+91) 79 26855579
                            <br /> Email: <a href="mailto:office@docthub.com"> office@docthub.com</a>
                        </address>
                    </>
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="text-grey-600">
                In the event you suffer as a result of access or usage of our Website by any person in violation of Rule
                3 of the IG Rules, please address your grievance to the above person.
            </DoctTypography>
            <DoctTypography variant="subtitle1" className="text-grey-600">
                13. SEVERABILITY
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                If any provision of the Agreement is held by a court of competent jurisdiction or arbitral tribunal to
                be unenforceable under applicable law, then such provision shall be excluded from this Agreement and the
                remainder of the Agreement shall be interpreted as if such provision were so excluded and shall be
                enforceable in accordance with its terms; provided however that, in such event, the Agreement shall be
                interpreted so as to give effect, to the greatest extent consistent with and permitted by applicable
                law, to the meaning and intention of the excluded provision as determined by such court of competent
                jurisdiction or arbitral tribunal.
            </DoctTypography>
            <DoctTypography variant="subtitle1" className="text-grey-600">
                14. WAIVER
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                No provision of this Agreement shall be deemed to be waived and no breach excused, unless such waiver or
                consent shall be in writing and signed by Docthub. Any consent by Docthub to, or a waiver by Docthub of
                any breach by you, whether expressed or implied, shall not constitute consent to, waiver of, or excuse
                for any other different or subsequent breach.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600" fontWeight="bold">
                YOU HAVE READ THESE TERMS OF USE AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE
            </DoctTypography>
        </DoctModal>
    );
};

export default TermsOfService;
