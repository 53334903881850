import React from 'react';

import DoctLoading from './DoctLoading';

import './DoctLoading.scss';

interface PageLoadingProps {
    spinnerSize?: number;
}

const PageLoading: React.FC<PageLoadingProps> = ({ spinnerSize }) => {
    return (
        <div className="page-loading">
            <DoctLoading type="circular" spinnerSize={spinnerSize} />
        </div>
    );
};

export default PageLoading;
