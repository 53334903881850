import React, { ReactElement } from 'react';
import Slider from 'react-slick';
import TestimonialCard from '../Cards/TestimonialCard';
import { DoctTestimonialType } from './PropTypes';
import './Testimonial.scss';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            onClick={onClick}
        >
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ fill: 'white' }}>
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
            </svg>
        </div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            onClick={onClick}
        >
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ fill: 'white' }}>
                <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
            </svg>
        </div>
    );
}

const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    autoplay: false,
    variableWidth: true,
    slidesToShow: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 2,
            },
        },
    ],
};

interface Props {
    testimonialsItems: DoctTestimonialType[];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function CardTestimonials({ testimonialsItems, ...rest }: Props): ReactElement {
    const TestimonialsItems = testimonialsItems.map((testimonial, i) => (
        <TestimonialCard key={i} testimonial={testimonial} />
    ));
    return (
        <div className="card-testimonials-container">
            <Slider {...settings} {...rest}>
                {TestimonialsItems}
            </Slider>
        </div>
    );
}
