/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { DoctTypography } from 'doct-core';
import DoctModal from '../../Modal/DoctModal';
import '../Static.scss';

interface PaymentPolicyProps {
    handleClose: () => void;
    open: boolean;
}
const PaymentPolicy: React.FC<PaymentPolicyProps> = ({ handleClose, open }) => {
    const [doctUrls, setDoctUrls] = useState(null);

    useEffect(() => {
        setDoctUrls(typeof window !== 'undefined' && window.DoctUrls ? window.DoctUrls : null);
    }, []);

    return (
        <DoctModal
            className="privacy-policy-modal static-modal"
            title="Payment Policy"
            width={572}
            showFooter={false}
            open={open}
            handleClose={handleClose}
        >
            <DoctTypography variant="body2" className="text-grey-600">
                Our Payment & Refund Policy were last updated on 16 June 2022.
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                Please read these terms and conditions carefully before using any Docthub Platform Services.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                BACKGROUND
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>
                    Docthub.com is a digital platform for healthcare professionals, offering healthcare career and
                    professional upgrade website as well as Android and iOS Mobile app for Users. It aggregates
                    healthcare jobs, courses, events, masterclass, certification programs offered by different partnered
                    organizations. Docthub – Enterprise Solutions is for business users to post their content and get
                    user responses on related Docthub platform. It also includes purchase of items and materials,
                    digital features, tools, Software as a Service (SaaS), Data as a Service (DaaS) to grow and manage
                    their professional work.
                </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                INTERPRETATION
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                DEFINITIONS
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                For the purposes of these Terms and Conditions:
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Affiliate” </b> means an entity that controls, is controlled by or is under common control with
                    a party, where "control" means ownership of the shares, equity interest or other securities entitled
                    to vote for election of directors or other managing authority.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>"Account"</b> means a unique account created for You to access our Service or parts of our
                    Service. Docthub platform has two type of accounts; Individual and Enterprise.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Individual Account” </b> refers to general users who are accessing and exploring Docthub
                    products or applications and able to connect, interact, order for different services.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Enterprise Account” </b> refers to business or organizational users who are representing legal
                    entity or freelance professional and using dashboard to post a content, get response from Individual
                    Users, access and manage their professional work through Docthub Enterprise Solutions.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b> “User” </b> refers to as any account holder, a generalized terminology for Individual User and
                    Enterprise User
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Individual User” </b> refers to any person using Docthub platform or applications through its
                    Individual Account.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b> “Enterprise User” </b> refers to any organizational representative, designated or authorized
                    person who is using Enterprise Dashboard through their Enterprise Account.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Company” </b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                    refers to DOCTHUB HEALTH TECH PRIVATE LIMITED.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Country” </b> refers to INDIA.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Content” </b> refers to content such as text, images, videos or other information that can be
                    posted, uploaded, linked to or otherwise made available by You, regardless of the form of that
                    content.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Device” </b> means any device that can access the Service such as a computer, a cellphone or a
                    digital tablet.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Feedback” </b>means feedback, innovations or suggestions sent by You regarding the attributes,
                    performance or features of our Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Products” </b> refer to the products or items offered for sale on the Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Orders” </b> mean a request by You to purchase Products from Us.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Promotions” </b> refer to contests, sweepstakes or other promotions offered through the Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Service” </b> refers to the Website <a href={`${doctUrls?.doct_brand_url}`}> www.docthub.com</a>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Product” </b> refers to subscriptions, tool, cloud solutions, professional service, web
                    applications, material, items, or any other Goods and Services offered by or listed on Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Aggregate Product” </b>refers to any product or service listed or posted by Enterprise User
                    through Enterprise Dashboard.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Aggregate Product Order” </b> refers to any product or service initiated by any user on Docthub
                    Platform which is listed or posted by Enterprise User through Enterprise Dashboard.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Enterprise Dashboard” </b> ” refers to enterprise.docthub.com, accessible for Enterprise Users
                    only for their organizational product, service or content listing and posting to get responses and
                    orders from Individual Orders through different Docthub platform or applications.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Terms and Conditions” </b> ” (also referred as <b>"Terms"</b>) mean these Terms and Conditions
                    that form the entire agreement between You and the Company regarding the use of the Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Third-party Social Media Service” </b> means any services or content (including data,
                    information, products or services) provided by a third-party that may be displayed, included or made
                    available by the Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Website” </b>” refers to DOCTHUB, accessible from{' '}
                    <a href={`${doctUrls?.doct_brand_url}`}> www.docthub.com</a>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“You” </b> means the individual accessing or using the Service, or the company, or other legal
                    entity on behalf of which such individual is accessing or using the Service, as applicable.
                </DoctTypography>
            </ul>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                TERMS AND CONDITIONS
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Charges: </b>
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    If there are charges associated with a portion of the Services, you agree to pay those charges in
                    the currency specified. The prices stated for services exclude all applicable tax and currency
                    exchange settlement, and Payment Getaway Charges, unless stated otherwise.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Taxes are calculated based on your location at the time your Docthub account was registered unless
                    local law required a different basis for the calculation. We may suspend or cancel the Service if we
                    do not receive an on time, full payment from you.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Suspension or cancellation of the services for non-payment could result in a loss of access to and
                    use of your account and its content. Connecting to the Internet via a corporate or other private
                    network that masks your location may casus of charges of different from those display for your
                    actual location.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Depended upon location some transaction required foreign conversion or be processes in another
                    country. Your bank might be charge additional fees for those services.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b> Your Billing Account:</b>
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    To Pay the Charges for Services, you will be asked to provide payment method at the time you Sign up
                    for Services. For all Services you can access and change you billing information and payment method
                    from your account.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Additionally, you agree to permit Docthub to use any updated account information regarding your
                    selected payment method, provided by your issuing bank or the applicable payment network.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    You agree to promptly update your account and other information, including you email address and
                    payment method detail, so we can complete your transaction and contact you as needed in connection
                    with your transaction. Change made to your billing account will not affect charges we submit to your
                    billing account before we could reasonably act on your changes to your billing account.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b> Billing: </b>By providing Docthub with Payment method you (i) represent that you are authorized
                    to use payment method you provided and that any payment information you provide is true and
                    accurate, (ii) authorized Docthub to charge you for the services or available content using for your
                    payment and (iii) authorized to Docthub to charges you for any paid feature of Services you choose
                    to sing up for or use while these terms are in force. We may bill you (a) in advance (b) at the time
                    of purchase (c) shortly after purchase or (d) on a recurring basis for subscription Services. Also,
                    we may charge you up to the amount you have approved, and we will notify you in advance of any
                    change in the amount to be charged for recurring subscription Services, We May bill you at the same
                    time for more than one prior billing period for amounts that haven’t previously been processed.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>Recurring Billing: </b> When you Purchase services on a subscription basis (e.g., monthly, every
                    3 month or annually), you agree that you are authorizing recurring payment, and payment will be made
                    to Docthub by the method and at the recurring interval you my agreed to, until the subscription for
                    those services is terminated by you or by Docthub. You must cancel your services before next billing
                    date to stop being charged to continue your services. By authorizing recurring payments, you are
                    authorizing Docthub to process such payment as either electronic debit or fund transfer or as an
                    electronic draft from your designated account, or as charges to your designated account.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Refund policy: </b>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Unless otherwise provided by law or the terms or the policy of particular Services offer, all
                    purchase are final and non-refundable.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    If you believe that Docthub has charged you in error, you must contact within 30-days of such
                    charge. No refund will be given for any charges more than 30-day old, unless otherwise required by
                    law.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    We reserve the right to issue refunds or credit our sole discretion unless otherwise required by
                    law. If we issue refund or credit, we are under no obligation to issue the same or similar refund in
                    the Future. This refund policy does not affect any statutory right that may apply.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    If you live in Taiwan or Israel, please note that according to the Consumer protection Act and its
                    regulation, all Purchase pertaining to digital Content provided via intangible form and online
                    services are final and non-refundable when such Services have been provided online, you cannot claim
                    any cooling off period or any refund.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Refund of any Aggregate Product Order is in sole discretion of particular Enterprise User or their
                    Organizational policy, Docthub has NO role in their refund policy.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> Canceling Services:</b> You may cancel a service at any time, with or without cause. Cancelling
                    paid Service stops future charges to Continue the Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>⦁ Gift card and Discount offer: </b> Redemption and use of gift card and Discount offer are
                    governed by Docthub Gift Card and Discount terms and conditions.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Payment to You: </b> If we own you a payment, then you agree to timely and accurately provide us
                    with any information we need to get for payment for you. Subject to applicable law, you are
                    responsible for any taxes and charges you may incur as a result of this payment to you. You must
                    also comply with other conditions we place on your right to any payment. If you receive a payment in
                    error, we may reverse or required return of the payment. You agree to cooperation with us in our
                    efforts to do this. We may also reduce the payment to you without notice to adjust for any previous
                    overpayment.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Price Change: </b> We may change the price any time and if you have a recurring Service, we will
                    notify you by email, or other reasonable manner, at least 15 days before the price change. If you
                    not agree to price change, you must cancel and stop using the Service before the price change take
                    effect. If there is a fixed term and Prices for your Service offer, that price will remain force for
                    the fixed term.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Trial -Period offers: </b> If you are taking part in any trial period offer, you may require to
                    cancel the trail Services within the timeframe communicated to you when you accepted the offer in
                    order to avoid being charged to continue the services at the end of the trail period.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Bank Account Payment method: </b> You may register an eligible bank account with your Docthub
                    account to use it as a payment method. Eligible bank account include account held at a financial
                    institution capable of receiving direct debit entries. Term you agreed to when adding your bank
                    account as payment method in your Docthub account also apply. You represent and warrant that your
                    registered bank account is held in your name or you are authorized to register and use this bank
                    account as a payment method. By registering or selecting your bank account held in your payment
                    method, you authorized Docthub to initiate one or more debits for the total amount of your purchase
                    or subscription charge (in accordance with the term of your subscription services) from your bank
                    account, and you authorize the financial institution that holds your bank account to deduct such
                    debit or accept such credits.
                </DoctTypography>
            </ul>
            <DoctTypography variant="list" className="font-weight-bold text-grey-600">
                CONTACTS
            </DoctTypography>
            <DoctTypography variant="list" className="text-grey-600">
                If you have any questions about this Privacy Policy, You can contact us:
            </DoctTypography>
            <ul className="mt-2 static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    By visiting this page on our website:
                    <a href={`${doctUrls?.doct_brand_url}/contact-us`} target="_blank" rel="noreferrer">
                        https://www.docthub.com/contact-us
                    </a>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    By sending us an email:<a href="mailto:office@docthub.com ">office@docthub.com </a>
                </DoctTypography>
            </ul>
        </DoctModal>
    );
};

export default PaymentPolicy;
