import React, { useState, useEffect } from 'react';
import DoctModal from '../Modal/DoctModal';
import CustomOTPInputField from './CustomOTPInputField';
import './DoctOTPVerification.scss';

interface OTPVerification {
    handleClose: () => void;
    onResendClick: () => void;
    onButtonSubmit: (value) => void;
    error?: boolean;
    verifiedMessage?: any;
    open: boolean;
    isOTPValue?: boolean;
    isSubmitButtonDisable?: boolean;
    isResendDisable?: boolean;
    countDownTime?: any;
    variant?: string;
    phoneNumber?: any;
}

const OTPVerification: React.FC<OTPVerification> = ({
    handleClose,
    error,
    verifiedMessage,
    open,
    onResendClick,
    onButtonSubmit,
    isOTPValue,
    isResendDisable,
    countDownTime,
    variant,
    phoneNumber,
}) => {
    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
    const [typographyText, setTypographyText] = useState('');
    const [modalTitle, setModalTitle] = useState('OTP Verification for Change Number');

    useEffect(() => {
        // if (!isOTPValue) return;
        setOtpValues(['', '', '', '', '', '']);
    }, [isOTPValue]);

    useEffect(() => {
        if (variant === 'mobile') {
            setTypographyText(
                'We have sent you an OTP to this mobile number,<br/> to verify that it’s you.<br/> Please check your phone. If you do not receive in a 60 seconds, try to resend.',
            );
            setModalTitle('OTP Verification for change Number');
        } else if (variant === 'password') {
            setTypographyText(
                'We have sent you an OTP to your registered<br/> email address, to verify that it’s you.<br/> Please check your email inbox. If you do not receive in a 60 seconds, Try Resend.',
            );
            setModalTitle('OTP Verification for Change Password');
        } else if (variant === 'email') {
            setTypographyText(
                'We have sent you an OTP to your registered<br/> email address, to verify that it’s you.<br/> Please check your email inbox. If you do not receive in a 60 seconds, Try Resend.',
            );
            setModalTitle('OTP Verification for Change Email');
        } else if (variant === 'newMobile') {
            setTypographyText(
                `We have sent you an OTP to your<br/> new mobile number,<br/> <span class="font-weight-bold"> +91 ${phoneNumber}<br/> </span> Please check your phone. If you do not receive in a 60 seconds, try to resend, or check your number.`,
            );
            setModalTitle('OTP Verification for New Mobile Number');
        } else {
            setTypographyText(
                'We have sent you an OTP to this mobile number,<br/> to verify that it’s you.<br/> Please check your phone, If do not receive in a 60 <br/> seconds, Try Resend.',
            );
            setModalTitle('OTP Verification of Mobile Number');
        }
    }, [variant]);

    const handlePrimaryButtonClick = () => {
        const otpString = otpValues?.join('');
        onButtonSubmit(otpString);
    };

    return (
        <>
            <DoctModal
                open={open}
                title={modalTitle}
                width={360}
                iconName=""
                primaryBtnText="Submit"
                className="otp-verification-modal"
                handleClose={handleClose}
                handlePrimaryButtonClick={handlePrimaryButtonClick}
                disableBackdropClick={true}
            >
                <div className="text-label-1 mb-3 text-center" dangerouslySetInnerHTML={{ __html: typographyText }} />

                <CustomOTPInputField
                    error={error}
                    verifiedMessage={verifiedMessage}
                    onResendClick={onResendClick}
                    setOtpValues={setOtpValues}
                    otpValues={otpValues}
                    isResendDisable={isResendDisable}
                    countDownTime={countDownTime}
                />
            </DoctModal>
        </>
    );
};

export default OTPVerification;
