import React, { ReactElement, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

function Row({ children }: Props): ReactElement {
    return <div className="row">{children}</div>;
}

export default Row;
