import React, { ReactElement, ReactNode } from 'react';

import './DoctLayout.scss';

interface Props {
    children: ReactNode;
    className?: string;
}

function DoctLayout({ children, className, ...props }: Props): ReactElement {
    return (
        <div className={`doct-layout${className ? ` ${className}` : ''}`} {...props}>
            {children}
        </div>
    );
}

export default DoctLayout;
