import { DoctIcon } from 'doct-core';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import React, { ReactElement } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import './AutoComplete.scss';

interface Props {
    label?: string;
    id: string;
    name: string;
    control: Control<Record<string, any>>;
    variant?: 'standard' | 'filled' | 'outlined';
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    touched?: DeepMap<Record<string, any>, true>;
    validationRules?: any;
    defaultValue?: any;
    showStar?: boolean;
    options: any[];
    showExtraLabel?: string;
    disableClearable?: boolean;
    optionLabel?: string;
    placeholder?: string;
    onEndScroll?: any;
    loading?: boolean;
    onClearInput?: () => void;
    getOptionDisabled?: () => boolean;
    onChange?: (e: React.SyntheticEvent<Element, Event>, value: any) => void;
    onInputChange?: (e: React.SyntheticEvent<Element, Event>, value: any) => void;
    ListboxProps?: any;
    value?: string | Record<string, any>;
    freeSolo?: boolean;
}

export default function DoctAutoComplete({
    label,
    id,
    name,
    variant = 'filled',
    disabled = false,
    readOnly = false,
    className,
    control,
    isErrors,
    touched,
    defaultValue,
    validationRules,
    showStar = false,
    options,
    disableClearable = false,
    optionLabel = 'label',
    placeholder,
    onClearInput,
    onEndScroll,
    getOptionDisabled,
    loading = false,
    onChange = () => null,
    ListboxProps = {},
    onInputChange,
    value,
    freeSolo = false,
    ...rest
}: Props): ReactElement {
    // Checking if label is not passed as a key then throwing an error;
    const isLabelAvailable = options && options.every((obj) => obj.hasOwnProperty('label'));
    if (!isLabelAvailable) throw new Error('Properties name should be label');

    const filterOptions = createFilterOptions({
        stringify: (option: { label: string }) => option.label,
    });

    return (
        // Adding readonly class to set custom style
        <div
            className={`doct-autocomplete-select input-text-truncate doct-input with-custom-close ${
                readOnly ? ' doct-read-only-input' : ''
            }${label ? ' ' : ' none-doct-input-label'}`}
        >
            <Controller
                rules={validationRules} // Rules, pattern and message for errors
                render={(controllerProps) => (
                    <>
                        <Autocomplete
                            {...controllerProps}
                            id={id}
                            disableClearable={disableClearable}
                            disabled={disabled}
                            disablePortal
                            freeSolo={freeSolo}
                            value={value}
                            clearIcon={
                                <div className="cutsom-close-icon d-flex align-items-center" onClick={onClearInput}>
                                    <DoctIcon name="close" width="24" className="text-grey-600" />
                                </div>
                            }
                            filterOptions={filterOptions}
                            options={[...options]}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            getOptionLabel={(option) =>
                                option && (optionLabel === 'label' ? option.label : option.value)
                            }
                            getOptionDisabled={getOptionDisabled}
                            // renderOption={(option) => <span>{option.label}</span>}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={placeholder}
                                    error={isErrors[name] ? true : false}
                                    helperText={isErrors[name]?.message}
                                    className={`doct-input${controllerProps.value ? ' doct-input-focused' : ''}${
                                        readOnly ? ' doct-read-only-input' : ''
                                    }${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}`}
                                    required={showStar && Object.keys(validationRules).length >= 1}
                                    label={label}
                                    variant={variant}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            onChange={(e, data) => {
                                controllerProps.onChange(data);
                                onChange(e, data);
                            }}
                            onInputChange={onInputChange}
                            ListboxProps={{
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        onEndScroll();
                                    }
                                },
                                ...ListboxProps,
                            }}
                            loading={loading}
                        />
                    </>
                )}
                defaultValue={defaultValue}
                name={name}
                control={control}
                {...rest}
            />
        </div>
    );
}
