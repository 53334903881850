import React from 'react';
import Badge from '@mui/material/Badge';

import './Badge.scss';

interface BadgeProps {
    children: any;
    horizontalPosition?: 'left' | 'right';
    badgeCount: number;
}

const BadgeComponent: React.FC<BadgeProps> = ({ children, badgeCount, horizontalPosition = 'left' }) => {
    return (
        <Badge
            anchorOrigin={{
                vertical: 'top',
                horizontal: horizontalPosition,
            }}
            badgeContent={badgeCount}
            color="primary"
        >
            {children}
        </Badge>
    );
};

export default BadgeComponent;
