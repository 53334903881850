import React, { ReactElement } from 'react';
import withStyles from '@mui/styles/withStyles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { InputLabel } from '@mui/material';

import './DoctDropdown.scss';

const CustomInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
}));

interface Props {
    menuItems: Record<string, string | number>[];
    handleChange: (event) => void;
    value: unknown;
    width?: number;
    label?: string;
}

function DoctDropdownSelect({ menuItems, handleChange, value, width, label }: Props): ReactElement {
    return (
        <FormControl variant="filled" sx={{ m: 1, minWidth: width }} className="doct-dropdown-select">
            {label && <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>}
            <Select
                className="doct-dropdown-select"
                id="customized-select"
                labelId="customized-select"
                value={value}
                MenuProps={{ className: 'doct-dropdown-select-menu' }}
                onChange={handleChange}
                label={label}
                input={<CustomInput />}
            >
                {menuItems.map((item) => (
                    <MenuItem className="doct-dropdown-select-item" key={item.id} value={item.value}>
                        {item.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default DoctDropdownSelect;
