import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepsFooter from './StepsFooter';
import { DoctContainer } from 'doct-core';

import './Steps.scss';

const useQontoStepIconStyles = makeStyles({
    root: {
        display: 'flex',
        height: 22,
        alignItems: 'center',
        zIndex: 1,
    },
    active: {
        color: '#fff',
    },
    circle: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: '#fff',
    },
});

function QontoStepIcon(props: StepIconProps) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? (
                <div className="completed-icon makeStyles-circle-51"></div>
            ) : (
                <div className="outside-circle">
                    {' '}
                    <div className={`${classes.circle} ${active ? 'active-circle' : 'upcoming-circle'}`} />
                </div>
            )}
        </div>
    );
}

const ColorlibConnector: any = withStyles({
    alternativeLabel: {},
    active: {
        '& $line': {
            backgroundColor: '#cdecf1',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#cdecf1',
        },
    },
    line: {
        height: 20,
        border: 0,
        backgroundColor: '#32b3c9;',
        borderRadius: 1,
    },
})(StepConnector);

interface Props {
    changeStepBool: boolean;
    onNextHandler: () => void;
    onBackHandler: () => void;
    stepsData: {
        //  stepContentClassName: string; stepFooterClassName: string; stepsContent: ({ label: string; content: JSX.Element; summaryStep?: undefined; } | { label: string; content: string; summaryStep?: undefined; } | { ...; })[]; finalStepContent: { ...; }; stepsFooter: { ...; }; }' is not assignable to type '{ stepContentClassName: string; stepFooterClassName: string; stepsContent: { label: string; content: string | Element; }[]; defaultStep?: number; finalStepContent: Record<string, unknown>; stepsFooter: { ...; };
        defaultStep?: number;
        stepContentClassName?: string;
        stepFooterClassName?: string;

        stepsContent: {
            label: string;
            content: string | JSX.Element;
            summaryStep?: { label: string; content: string | JSX.Element; footer: string | JSX.Element };
        }[];
        finalStepContent: { content: string; footer: string | JSX.Element };
        stepsFooter: {
            leftSideContent?: { content: string | JSX.Element; show: number[] }[];
            primaryButtonContent?: { content: string | JSX.Element; show: number[] }[];
            secondaryButtonContent?: { content: string | JSX.Element; show: number[] }[];
        };
    };
}

export default function Steps({ stepsData, changeStepBool, onNextHandler, onBackHandler }: Props): ReactElement {
    const {
        stepsContent,
        stepContentClassName,
        defaultStep,
        stepFooterClassName,
        finalStepContent,
        stepsFooter: { leftSideContent, primaryButtonContent, secondaryButtonContent },
    } = stepsData;

    const [activeStep, setActiveStep] = React.useState(defaultStep || 0);
    // console.log(changeStepBool, 'changeStepBool');

    // total steps
    const totalSteps = stepsContent.length;
    // check if stepsContent has summary step
    const isSummaryStep = stepsContent.find((step) => step.summaryStep);
    const summaryStep = isSummaryStep && isSummaryStep.summaryStep;
    // check if user is on final step
    const isFinalStep = activeStep === totalSteps - 2;
    // show summary step conditionally, if user is in final step and have to show summary step
    const showSummaryStep = isSummaryStep && isFinalStep;

    const handleNext = () => {
        onNextHandler ? onNextHandler() : null;
        {
            changeStepBool
                ? setActiveStep((prevActiveStep) =>
                      // showing summary step after finish step if have to show
                      showSummaryStep ? totalSteps : prevActiveStep + 1,
                  )
                : null;
        }
    };

    const handleBack = () => {
        onBackHandler ? onBackHandler() : null;
        {
            changeStepBool ? setActiveStep((prevActiveStep) => prevActiveStep - 1) : null;
        }
    };

    const getStepContent = (step: number) =>
        stepsContent[step] && stepsContent[step].content && stepsContent[step].content;

    return (
        <>
            <Stepper
                className={`step-${activeStep}`}
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
            >
                {stepsContent.map(({ label, summaryStep }, i) => (
                    <Step key={i}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label || summaryStep.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className={stepContentClassName ? stepContentClassName : ''}>
                <DoctContainer>
                    {totalSteps === activeStep && !summaryStep
                        ? finalStepContent.content
                        : totalSteps === activeStep && summaryStep
                        ? summaryStep.content
                        : getStepContent(activeStep)}
                </DoctContainer>
            </div>
            <div className={stepFooterClassName ? stepFooterClassName : ''}>
                <DoctContainer>
                    {activeStep === totalSteps && !summaryStep ? (
                        finalStepContent.footer
                    ) : activeStep === totalSteps && summaryStep ? (
                        <>{summaryStep.footer}</>
                    ) : (
                        <StepsFooter
                            handleBack={handleBack}
                            handleNext={handleNext}
                            leftSideContent={leftSideContent}
                            activeStep={activeStep}
                            primaryButtonContent={primaryButtonContent}
                            secondaryButtonContent={secondaryButtonContent}
                        />
                    )}
                </DoctContainer>
            </div>
        </>
    );
}
