import React, { ReactElement } from 'react';

import DoctIcon from '../Icons/Icons';
import { ButtonSize, ButtonType, ButtonVariant } from './enum';

import './Button.scss';

interface Props {
    text: string;
    variant?: 'text' | 'contained' | 'outlined';
    size?: string;
    icon?: string;
    iconPosition?: string;
    disabled?: boolean;
    onButtonClickHandler?: React.MouseEventHandler<HTMLElement>;
    type?:
        | 'primary'
        | 'secondary'
        | 'semantic-danger'
        | 'semantic-success'
        | 'semantic-warning'
        | 'semantic-info'
        | 'inverse';
    className?: string;
}

export default function DoctButton({
    text,
    variant = 'contained',
    size = 'large',
    type = 'primary',
    icon,
    iconPosition = 'left',
    disabled,
    onButtonClickHandler,
    className,
}: Props): ReactElement {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
        if (onButtonClickHandler) {
            (onButtonClickHandler as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>)(e);
        }
    };

    const classNameList = [`doct-button d-flex align-items-center justify-content-center ${className}`];

    // Button Type
    if (type === ButtonType.Primary && variant !== ButtonVariant.Outlined)
        classNameList.push('bg-primary text-white doct-primary-button');
    else if (type === ButtonType.Secondary) classNameList.push('text-grey-600 doct-secondary-button');
    else if (type === ButtonType.Inverse) classNameList.push('text-grey-600 doct-inverse-button');
    else if (type === ButtonType.SemanticDanger) classNameList.push('bg-danger text-white');
    else if (type === ButtonType.SemanticSuccess) classNameList.push('bg-success text-white');
    else if (type === ButtonType.SemanticWarning) classNameList.push('bg-warning text-white');
    else if (type === ButtonType.SemanticInfo) classNameList.push('bg-info text-white');

    // Button Size
    if (size === ButtonSize.Medium) classNameList.push('doct-medium-button');
    else if (size === ButtonSize.Small) classNameList.push('doct-small-button');
    else classNameList.push('doct-large-button');

    // Disabled
    if (disabled) classNameList.push('disabled');

    // Button Variant
    if (variant === ButtonVariant.Contained) classNameList.push('doct-contained-button');
    else if (variant === ButtonVariant.Text) classNameList.push('doct-text-button');
    else classNameList.push('doct-outlined-button');

    return (
        <button onClick={handleClick} className={classNameList.join(' ')} disabled={disabled}>
            {/* if required left side icon */}
            {iconPosition === 'left' && icon && <DoctIcon className={iconPosition} name={icon} />}
            {text}
            {/* if required right side icon */}
            {iconPosition === 'right' && icon && <DoctIcon className={iconPosition} name={icon} />}
        </button>
    );
}
