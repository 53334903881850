import React, { ReactElement, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Checkbox } from '@mui/material';
import Chip from '../../Chip/Chip';

import { DoctIcon } from 'doct-core';

interface Props {
    options: any[];
    getOptionLabel?: (option: any) => string;
    variant?: 'standard' | 'filled' | 'outlined';
    textFieldProps?: any;
    autoCompleteProps?: any;
    value: any[];
    onChange: (value: any[]) => void;
    valueAccessBy?: string;
    onClose?: () => void;
    withFixedWidthChips?: boolean;
    maxSelections?: number | undefined;
}

function DoctWrapper({ children, label, readOnly }: { children: React.ReactNode; label?: string; readOnly?: boolean }) {
    return (
        <div
            className={`doct-autocomplete-select doct-autocomplete-select-with-chips input-text-truncate with-custom-close ${
                readOnly ? ' doct-read-only-input' : ''
            }${label ? ' ' : ' none-doct-input-label'}`}
        >
            {children}
        </div>
    );
}

export default function AutoCompleteWithChips({
    options = [],
    getOptionLabel = (option) => option.title,
    variant = 'filled',
    textFieldProps = {},
    autoCompleteProps = {},
    value = [],
    onChange = () => {},
    valueAccessBy = 'id',
    onClose = () => {},
    withFixedWidthChips = false,
    maxSelections,
}: Props): ReactElement {
    const onDelete = (id: string) => () => {
        const filteredValue = value.filter((v) => v[valueAccessBy] !== id);
        onChange(filteredValue);
    };

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setInputValue(value);
    };

    const handleAutocompleteClose = () => {
        onClose(); // Trigger the onClose prop provided by the parent component
    };

    const handleSelectionChange = (e: React.ChangeEvent<{}>, newValue: any[]) => {
        if (maxSelections !== undefined && newValue.length > maxSelections) {
            newValue = newValue.slice(0, maxSelections);
        }
        onChange(newValue);
    };

    return (
        <DoctWrapper label={textFieldProps?.label} readOnly={autoCompleteProps?.readOnly}>
            <Autocomplete
                multiple
                onClose={handleAutocompleteClose}
                options={options}
                getOptionLabel={getOptionLabel}
                value={value}
                onChange={handleSelectionChange}
                renderTags={() => null}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                disableCloseOnSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={variant}
                        {...textFieldProps}
                        className={'doct-input'}
                        onKeyDown={(e) => {
                            if (e.key === 'Backspace') {
                                e.stopPropagation();
                            }
                        }}
                    />
                )}
                renderOption={(props, option: any, state) => {
                    const isDisabled = maxSelections !== undefined && !state.selected && value.length >= maxSelections;
                    return (
                        <li {...props} key={option.id}>
                            <Checkbox
                                checked={state.selected}
                                className="mr-2"
                                disableRipple
                                classes={{ root: 'p-0' }}
                            />
                            <span style={{ opacity: isDisabled ? 0.5 : 1 }}>{option.title}</span>
                        </li>
                    );
                }}
                disableClearable={!inputValue}
                clearIcon={
                    <div
                        className="cutsom-close-icon d-flex align-items-center"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setInputValue('');
                        }}
                    >
                        <DoctIcon name="close" width="20" height="20" className="text-grey-600" />
                    </div>
                }
                {...autoCompleteProps}
            />

            {value?.map((v, index) => (
                <span key={index} className={withFixedWidthChips ? 'mx-1 mb-1 mt-1 with-fixed-width-chips' : ''}>
                    <Chip key={v[valueAccessBy]} title={v.title} onCloseHandler={onDelete(v[valueAccessBy])} />
                </span>
            ))}
        </DoctWrapper>
    );
}
