import React, { ReactElement } from 'react';
import Tab from '@mui/material/Tab';
import './DoctTab.scss';
interface Props {
    label: string;
    indicatorColor?: string;
    textColor?: string;
    className?: string;
}

function DoctTab({ label, textColor, ...rest }: Props): ReactElement {
    const classes = [];

    if (textColor === 'primary') {
        classes.push('doct-tab-button');
    }

    return <Tab className={classes.join(' ')} label={label} {...rest} />;
}

export default DoctTab;
