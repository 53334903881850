import React from 'react';
interface IconProps {
    width?: number | string;
    height?: number | string;
}

export const JobOutlineIcon = ({ width = 44, height = 44 }: IconProps): JSX.Element => {
    return (
        <svg id="jobsIcon44" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <rect id="bounding" width="44" height="44" fill="none" />
            <g id="Group_1982" data-name="Group 1982" transform="translate(4 4.333)">
                <path
                    id="line"
                    d="M5.333,2A3.337,3.337,0,0,0,2,5.333v8a3.337,3.337,0,0,0,3.333,3.333H18.667A3.337,3.337,0,0,0,22,13.333v-8A3.337,3.337,0,0,0,18.667,2H5.333m0-2H18.667A5.333,5.333,0,0,1,24,5.333v8a5.333,5.333,0,0,1-5.333,5.333H5.333A5.333,5.333,0,0,1,0,13.333v-8A5.333,5.333,0,0,1,5.333,0Z"
                    transform="translate(0 3.667)"
                    fill="#00a8f2"
                />
                <path
                    id="Path_1338"
                    data-name="Path 1338"
                    d="M18.078,7.434a1,1,0,0,1-1-1V5.946A1.949,1.949,0,0,0,15.132,4h-3.44A1.949,1.949,0,0,0,9.745,5.946v.488a1,1,0,1,1-2,0V5.946A3.951,3.951,0,0,1,11.692,2h3.44a3.951,3.951,0,0,1,3.947,3.947v.488A1,1,0,0,1,18.078,7.434Z"
                    transform="translate(-1.085 -2)"
                    fill="#00a8f2"
                />
                <path
                    id="Path_1340"
                    data-name="Path 1340"
                    d="M5612.212-17147.588a16.391,16.391,0,0,1-4.677-.7,20.017,20.017,0,0,1-7.035-3.781l1.333-1.49-.666.744.665-.746a18.258,18.258,0,0,0,6.341,3.375,13.666,13.666,0,0,0,6.345.393,15.1,15.1,0,0,0,7.318-3.768l1.328,1.5a17.07,17.07,0,0,1-8.336,4.25A15.012,15.012,0,0,1,5612.212-17147.588Z"
                    transform="translate(-5599.833 17160.781)"
                    fill="#00a8f2"
                />
                <path
                    id="Line_480"
                    data-name="Line 480"
                    d="M1.25,2.667h-2V0h2Z"
                    transform="translate(11.75 13)"
                    fill="#00a8f2"
                />
            </g>
        </svg>
    );
};

export const ResumeBuilderIcon = ({ width = 42, height = 42 }: IconProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <g id="Group_306392" data-name="Group 306392" transform="translate(-1597 -359)">
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="24"
                    height="24"
                    transform="translate(1597 359)"
                    fill="none"
                />
                <path
                    id="Union_88"
                    data-name="Union 88"
                    d="M-5938.764,20008v-1.918l6.131-6.131a1.175,1.175,0,0,1,1.737.234,1.149,1.149,0,0,1,.125,1.729l-6.081,6.086Zm-7.17-.719a4.066,4.066,0,0,1-4.067-4.053v-12.182a4.062,4.062,0,0,1,4.067-4.051h6.638l5.523,5.412v6.488a.815.815,0,0,1-.813.813.815.815,0,0,1-.8-.682v-5.916h-2.867a2.013,2.013,0,0,1-2.009-2.014v-2.486h-5.669a2.444,2.444,0,0,0-2.445,2.436v12.182a2.439,2.439,0,0,0,2.445,2.432h4.143a.814.814,0,0,1,.708.809.81.81,0,0,1-.8.813Zm.256-4.1a.758.758,0,0,1-.753-.764.753.753,0,0,1,.753-.754h5.408a.754.754,0,0,1,.758.754.759.759,0,0,1-.758.764Zm0-2.979a.757.757,0,0,1-.753-.758.753.753,0,0,1,.753-.758h7.572a.753.753,0,0,1,.753.758.757.757,0,0,1-.753.758Zm-.663-3.631a.807.807,0,0,1,.2-1.129,4.633,4.633,0,0,1,2.375-.818,4.637,4.637,0,0,1,2.38.818.8.8,0,0,1,.2,1.129.811.811,0,0,1-1.13.2,3.077,3.077,0,0,0-1.446-.523,3.053,3.053,0,0,0-1.441.527.838.838,0,0,1-.467.146A.8.8,0,0,1-5946.341,19996.574Zm.949-4.428a1.62,1.62,0,0,1,1.622-1.617,1.621,1.621,0,0,1,1.627,1.617,1.622,1.622,0,0,1-1.627,1.621A1.621,1.621,0,0,1-5945.392,19992.146Z"
                    transform="translate(7549.501 -19626)"
                    fill="#4b7cff"
                />
            </g>
        </svg>
    );
};

export const BlogsIcon = ({}: IconProps): JSX.Element => {
    return (
        <svg id="blogsIcon44" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <rect id="bounding" width="32" height="32" fill="none" />
            <g id="Group_2024" data-name="Group 2024" transform="translate(5.333 4)">
                <path
                    id="line"
                    d="M5.333,2A3.337,3.337,0,0,0,2,5.333V18.667A3.337,3.337,0,0,0,5.333,22H16a3.337,3.337,0,0,0,3.333-3.333V5.333A3.337,3.337,0,0,0,16,2H5.333m0-2H16a5.333,5.333,0,0,1,5.333,5.333V18.667A5.333,5.333,0,0,1,16,24H5.333A5.333,5.333,0,0,1,0,18.667V5.333A5.333,5.333,0,0,1,5.333,0Z"
                    transform="translate(0 0)"
                    fill="#00a0c0"
                />
                <path
                    id="Line_469"
                    data-name="Line 469"
                    d="M8.25,1.25h-8a1,1,0,1,1,0-2h8a1,1,0,1,1,0,2Z"
                    transform="translate(6.417 6.417)"
                    fill="#00a0c0"
                />
                <path
                    id="Line_483"
                    data-name="Line 483"
                    d="M8.25,1.25h-8a1,1,0,1,1,0-2h8a1,1,0,1,1,0,2Z"
                    transform="translate(6.417 11.75)"
                    fill="#00a0c0"
                />
                <path
                    id="Line_484"
                    data-name="Line 484"
                    d="M4.25,1.25h-4a1,1,0,1,1,0-2h4a1,1,0,1,1,0,2Z"
                    transform="translate(6.417 17.083)"
                    fill="#00a0c0"
                />
            </g>
        </svg>
    );
};

// Repeat similarly for other icons...

export const RolePageIcon = ({ width = 44, height = 44 }: IconProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <g id="merged" transform="translate(-1141 -526)">
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="32"
                    height="32"
                    transform="translate(1141 526)"
                    fill="none"
                />
                <g id="coursesIcon32" transform="translate(1143.667 528.667)">
                    <path
                        id="bounding"
                        d="M13.333,2A11.333,11.333,0,1,0,24.667,13.333,11.346,11.346,0,0,0,13.333,2m0-2A13.333,13.333,0,1,1,0,13.333,13.333,13.333,0,0,1,13.333,0Z"
                        transform="translate(0 0)"
                        fill="#00a0c0"
                    />
                    <path
                        id="Path_1341"
                        data-name="Path 1341"
                        d="M285.215,165.965h-2.37V161.62H278.5v-2.37h6.715Z"
                        transform="translate(-268.524 -149.274)"
                        fill="#00a0c0"
                    />
                </g>
            </g>
        </svg>
    );
};
export const MembershipIcon = ({ width = 44, height = 44 }: IconProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <g id="membershipsIcon24" transform="translate(-728 -220)">
                <rect id="bounding" width="32" height="32" transform="translate(728 220)" fill="none" />
                <path
                    id="newspaper_FILL0_wght300_GRAD0_opsz20"
                    d="M4.213,24.059a2.244,2.244,0,0,1-1.645-.693,2.249,2.249,0,0,1-.693-1.647V4.181q0-.523.247-.614t.614.245l.892.894,1.2-1.232a1.119,1.119,0,0,1,.384-.245,1.119,1.119,0,0,1,.894,0,1.119,1.119,0,0,1,.384.245L7.721,4.7,8.952,3.473a1.119,1.119,0,0,1,.384-.245,1.116,1.116,0,0,1,.892,0,1.116,1.116,0,0,1,.385.245l1.2,1.232,1.232-1.232a1.339,1.339,0,0,1,.384-.261.977.977,0,0,1,.892,0,1.339,1.339,0,0,1,.384.261L15.937,4.7l1.2-1.232a1.116,1.116,0,0,1,.385-.245,1.116,1.116,0,0,1,.892,0,1.119,1.119,0,0,1,.384.245L20.029,4.7l1.23-1.232a1.119,1.119,0,0,1,.384-.245,1.119,1.119,0,0,1,.894,0,1.119,1.119,0,0,1,.384.245l1.2,1.232.892-.894q.369-.338.614-.245t.247.614V21.719a2.365,2.365,0,0,1-2.338,2.339Zm0-1.97H12.89v-8H3.844v7.63a.368.368,0,0,0,.369.369Zm10.647,0h8.677a.368.368,0,0,0,.369-.369V19.074H14.86Zm0-4.985h9.046V14.089H14.86ZM3.844,12.119H23.906V7.935H3.844Z"
                    transform="translate(730.125 222.229)"
                    fill="#00a0c0"
                />
            </g>
        </svg>
    );
};
export const LogbookIcon = ({ width = 44, height = 44 }: IconProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <g id="logbookIcon44" transform="translate(-648 -347)">
                <rect id="bounding" width="32" height="32" transform="translate(648 347)" fill="none" />
                <g id="Group_2005" data-name="Group 2005" transform="translate(653.333 351)">
                    <path
                        id="line"
                        d="M5.333,2A3.337,3.337,0,0,0,2,5.333V22H16a3.337,3.337,0,0,0,3.333-3.333V5.333A3.337,3.337,0,0,0,16,2H5.333m0-2H16a5.333,5.333,0,0,1,5.333,5.333V18.667A5.333,5.333,0,0,1,16,24H0V5.333A5.333,5.333,0,0,1,5.333,0Z"
                        transform="translate(0 0)"
                        fill="#ad60e5"
                    />
                    <path
                        id="Line_469"
                        data-name="Line 469"
                        d="M1.583,1.25H.25a1,1,0,0,1,0-2H1.583a1,1,0,0,1,0,2Z"
                        transform="translate(5.083 6.417)"
                        fill="#ad60e5"
                    />
                    <path
                        id="Line_486"
                        data-name="Line 486"
                        d="M6.25,1.25h-6a1,1,0,0,1,0-2h6a1,1,0,1,1,0,2Z"
                        transform="translate(9.75 6.417)"
                        fill="#ad60e5"
                    />
                    <path
                        id="Line_488"
                        data-name="Line 488"
                        d="M1.583,1.25H.25a1,1,0,0,1,0-2H1.583a1,1,0,0,1,0,2Z"
                        transform="translate(5.083 11.75)"
                        fill="#ad60e5"
                    />
                    <path
                        id="Line_487"
                        data-name="Line 487"
                        d="M6.25,1.25h-6a1,1,0,0,1,0-2h6a1,1,0,1,1,0,2Z"
                        transform="translate(9.75 11.75)"
                        fill="#ad60e5"
                    />
                    <path
                        id="Line_488-2"
                        data-name="Line 488"
                        d="M1.583,1.25H.25a1,1,0,0,1,0-2H1.583a1,1,0,0,1,0,2Z"
                        transform="translate(5.083 17.083)"
                        fill="#ad60e5"
                    />
                    <path
                        id="Line_487-2"
                        data-name="Line 487"
                        d="M6.25,1.25h-6a1,1,0,0,1,0-2h6a1,1,0,1,1,0,2Z"
                        transform="translate(9.75 17.083)"
                        fill="#ad60e5"
                    />
                </g>
            </g>
        </svg>
    );
};
export const EventsOutlineIcon = ({ width = 44, height = 44 }: IconProps): JSX.Element => {
    return (
        <svg id="eventsIcon44" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <rect id="bounding" width="44" height="44" fill="none" />
            <g id="Group_1971" data-name="Group 1971" transform="translate(4 3)">
                <path
                    id="line"
                    d="M5.333,2A3.337,3.337,0,0,0,2,5.333V16a3.337,3.337,0,0,0,3.333,3.333H18.667A3.337,3.337,0,0,0,22,16V5.333A3.337,3.337,0,0,0,18.667,2H5.333m0-2H18.667A5.333,5.333,0,0,1,24,5.333V16a5.333,5.333,0,0,1-5.333,5.333H5.333A5.333,5.333,0,0,1,0,16V5.333A5.333,5.333,0,0,1,5.333,0Z"
                    transform="translate(0 2.333)"
                    fill="#fe217f"
                />
                <path
                    id="Line_469"
                    data-name="Line 469"
                    d="M.25,5.917a1,1,0,0,1-1-1V.25a1,1,0,0,1,2,0V4.917A1,1,0,0,1,.25,5.917Z"
                    transform="translate(6.417 0.75)"
                    fill="#fe217f"
                />
                <path
                    id="Line_470"
                    data-name="Line 470"
                    d="M.25,5.917a1,1,0,0,1-1-1V.25a1,1,0,0,1,2,0V4.917A1,1,0,0,1,.25,5.917Z"
                    transform="translate(17.083 0.75)"
                    fill="#fe217f"
                />
                <path
                    id="Path_1322"
                    data-name="Path 1322"
                    d="M5368.917-17235.422a.993.993,0,0,1-.708-.289l-2.667-2.664a1.009,1.009,0,0,1,0-1.422,1.01,1.01,0,0,1,1.414,0l1.961,1.963,4.625-4.627a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-5.333,5.336A.991.991,0,0,1,5368.917-17235.422Z"
                    transform="translate(-5358.25 17252.09)"
                    fill="#fe217f"
                />
            </g>
        </svg>
    );
};
export const LibraryIcon = ({ width = 44, height = 44 }: IconProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <g id="membershipsIcon24" transform="translate(-728 -220)">
                <rect id="bounding" width="32" height="32" transform="translate(728 220)" fill="none" />
                <path
                    id="newspaper_FILL0_wght300_GRAD0_opsz20"
                    d="M4.213,24.059a2.244,2.244,0,0,1-1.645-.693,2.249,2.249,0,0,1-.693-1.647V4.181q0-.523.247-.614t.614.245l.892.894,1.2-1.232a1.119,1.119,0,0,1,.384-.245,1.119,1.119,0,0,1,.894,0,1.119,1.119,0,0,1,.384.245L7.721,4.7,8.952,3.473a1.119,1.119,0,0,1,.384-.245,1.116,1.116,0,0,1,.892,0,1.116,1.116,0,0,1,.385.245l1.2,1.232,1.232-1.232a1.339,1.339,0,0,1,.384-.261.977.977,0,0,1,.892,0,1.339,1.339,0,0,1,.384.261L15.937,4.7l1.2-1.232a1.116,1.116,0,0,1,.385-.245,1.116,1.116,0,0,1,.892,0,1.119,1.119,0,0,1,.384.245L20.029,4.7l1.23-1.232a1.119,1.119,0,0,1,.384-.245,1.119,1.119,0,0,1,.894,0,1.119,1.119,0,0,1,.384.245l1.2,1.232.892-.894q.369-.338.614-.245t.247.614V21.719a2.365,2.365,0,0,1-2.338,2.339Zm0-1.97H12.89v-8H3.844v7.63a.368.368,0,0,0,.369.369Zm10.647,0h8.677a.368.368,0,0,0,.369-.369V19.074H14.86Zm0-4.985h9.046V14.089H14.86ZM3.844,12.119H23.906V7.935H3.844Z"
                    transform="translate(730.125 222.229)"
                    fill="#00a0c0"
                />
            </g>
        </svg>
    );
};

export const CourseOutlineIcon = ({ width = 44, height = 44 }: IconProps): JSX.Element => {
    return (
        <svg id="coursesIcon44" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <rect id="bounding" width="32" height="32" fill="none" />
            <g id="Group_1968" data-name="Group 1968" transform="translate(3.667 5.667)">
                <path
                    id="Path_1329"
                    data-name="Path 1329"
                    d="M5535.592-17136.918a1.027,1.027,0,0,1-.253-.029l-.469-.119-10.834-2.375a1,1,0,0,1-.786-.977v-17.334a1,1,0,0,1,.373-.777,1,1,0,0,1,.841-.2l10.706,2.346.038.008.278.07a.012.012,0,0,0,.008,0l.18.047a1,1,0,0,1,.723,1.215,1,1,0,0,1-1.215.723l-.3-.074a.009.009,0,0,0-.008,0l-.143-.035-9.476-2.076v15.287l10.063,2.207a.13.13,0,0,1,.027.006l.389.1a.009.009,0,0,1,.008,0l.109.027a1,1,0,0,1,.713,1.219A1,1,0,0,1,5535.592-17136.918Z"
                    transform="translate(-5523.25 17158.752)"
                    fill="#00b871"
                />
                <path
                    id="Path_1330"
                    data-name="Path 1330"
                    d="M5525.743-17136.918a1,1,0,0,1-.967-.746,1,1,0,0,1,.714-1.219l.109-.027s0,0,.007,0l.389-.1a.148.148,0,0,1,.027-.006l10.063-2.207v-15.287l-9.475,2.076-.143.035s-.007,0-.009,0l-.218.055a1.006,1.006,0,0,1-1.219-.719,1,1,0,0,1,.719-1.219l.1-.027a.012.012,0,0,0,.008,0l.122-.029.157-.041.036-.008,10.707-2.346a1,1,0,0,1,.84.2,1,1,0,0,1,.374.777v17.334a1,1,0,0,1-.786.977l-10.835,2.375-.468.119A1.027,1.027,0,0,1,5525.743-17136.918Z"
                    transform="translate(-5513.419 17158.752)"
                    fill="#00b871"
                />
                <path
                    id="Path_1331"
                    data-name="Path 1331"
                    d="M5532.761-17139.238a1,1,0,0,1-1-1v-15.512a1,1,0,0,1,1-1,1,1,0,0,1,1,1v15.512A1,1,0,0,1,5532.761-17139.238Z"
                    transform="translate(-5520.412 17159.414)"
                    fill="#00b871"
                />
                <path
                    id="Path_1332"
                    data-name="Path 1332"
                    d="M5533.761-17152.525l-2,.525v-4h2Z"
                    transform="translate(-5513.761 17158.336)"
                    fill="#00b871"
                />
            </g>
        </svg>
    );
};

export const NewsIcon = ({ width = 44, height = 44 }: IconProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <g id="membershipsIcon24" transform="translate(-728 -220)">
                <rect id="bounding" width="32" height="32" transform="translate(728 220)" fill="none" />
                <path
                    id="newspaper_FILL0_wght300_GRAD0_opsz20"
                    d="M4.213,24.059a2.244,2.244,0,0,1-1.645-.693,2.249,2.249,0,0,1-.693-1.647V4.181q0-.523.247-.614t.614.245l.892.894,1.2-1.232a1.119,1.119,0,0,1,.384-.245,1.119,1.119,0,0,1,.894,0,1.119,1.119,0,0,1,.384.245L7.721,4.7,8.952,3.473a1.119,1.119,0,0,1,.384-.245,1.116,1.116,0,0,1,.892,0,1.116,1.116,0,0,1,.385.245l1.2,1.232,1.232-1.232a1.339,1.339,0,0,1,.384-.261.977.977,0,0,1,.892,0,1.339,1.339,0,0,1,.384.261L15.937,4.7l1.2-1.232a1.116,1.116,0,0,1,.385-.245,1.116,1.116,0,0,1,.892,0,1.119,1.119,0,0,1,.384.245L20.029,4.7l1.23-1.232a1.119,1.119,0,0,1,.384-.245,1.119,1.119,0,0,1,.894,0,1.119,1.119,0,0,1,.384.245l1.2,1.232.892-.894q.369-.338.614-.245t.247.614V21.719a2.365,2.365,0,0,1-2.338,2.339Zm0-1.97H12.89v-8H3.844v7.63a.368.368,0,0,0,.369.369Zm10.647,0h8.677a.368.368,0,0,0,.369-.369V19.074H14.86Zm0-4.985h9.046V14.089H14.86ZM3.844,12.119H23.906V7.935H3.844Z"
                    transform="translate(730.125 222.229)"
                    fill="#00a0c0"
                />
            </g>
        </svg>
    );
};
