import React, { ReactElement } from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function DoctTabContent({ children, value, index, ...rest }: TabPanelProps): ReactElement {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...rest}
        >
            {value === index && children}
        </div>
    );
}

export default DoctTabContent;
