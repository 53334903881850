import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Control, FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import DoctChip from '../../Chip/Chip';
import useAutocomplete from '@mui/material/useAutocomplete';

interface FreeSoloMultiSelectProps {
    register: any;
    setValue: any;
    name: string;
    isErrors: DeepMap<Record<string, any>, FieldError>;
    control: Control<Record<string, any>>;
    options: any;
    label: string;
    readOnly?: boolean;
    disabled?: boolean;
    className?: string;
    maxChips?: number;
    minChips?: number;
    placeholder?: string;
    errorMessage?: {
        requiredMessage?: string;
        maxChipsMessage?: string;
        minChipsMessage?: string;
    };
    defaultValue?: string[];
    setMultipleValue: (option) => void;
    multipleValue: string[];
}

const FreeSoloMultiSelect: React.FC<FreeSoloMultiSelectProps> = ({
    register,
    setValue,
    isErrors,
    readOnly,
    label,
    className,
    disabled,
    options,
    maxChips,
    errorMessage,
    minChips,
    placeholder,
    name,
    defaultValue,
    setMultipleValue,
    multipleValue,
}) => {
    // const { getTagProps } = useAutocomplete({ id: name });

    useEffect(() => {
        errorMessage && Object.keys(errorMessage).length >= 1
            ? register(name, {
                  validate: {
                      hasValue: (value) => value?.length || errorMessage.requiredMessage,
                      greaterThan: (value) => value?.length <= maxChips || `${errorMessage.maxChipsMessage}`,
                      lessThan: (value) => value?.length >= minChips || `${errorMessage.minChipsMessage}`,
                  },
              })
            : register(name, {});
    }, [register]);

    return (
        <div className={`doct-autocomplete-select${readOnly ? ' doct-read-only-input' : ''}`}>
            <Autocomplete
                multiple
                freeSolo
                defaultValue={defaultValue}
                options={options || []}
                value={multipleValue}
                onChange={(e, options) => {
                    setValue(name, options);
                    setMultipleValue(options);
                }}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                // renderTags={(value: string[]) => {}}
                renderTags={(value: any, getTagProps) => ''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={placeholder}
                        className={`doct-input${readOnly ? ' doct-read-only-input' : ''}${
                            className ? ` ${className}` : ''
                        }${disabled ? ' disabled' : ''}`}
                        error={Boolean(isErrors?.autocomplete)}
                        helperText={isErrors?.autocomplete?.message}
                    />
                )}
            />
        </div>
    );
};

export default FreeSoloMultiSelect;
