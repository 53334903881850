import { DoctButton, DoctTypography } from 'doct-core';
import React, { useRef, useState, useEffect } from 'react';

type CustomOTPInputFieldProps = {
    error?: any;
    verifiedMessage?: any;
    onResendClick?: any;
    setOtpValues?: any;
    otpValues?: any;
    isResendDisable?: boolean;
    countDownTime?: any;
};

const CustomOTPInputField: React.FC<CustomOTPInputFieldProps> = ({
    error,
    verifiedMessage,
    onResendClick,
    setOtpValues,
    otpValues,
    isResendDisable,
    countDownTime = 60,
}) => {
    const [countdown, setCountdown] = useState(countDownTime);
    const [isResendClicked, setIsResendClicked] = useState<boolean>(false);
    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()] as any;
    const handleChange = (event, index) => {
        const { value, keyCode } = event.target;

        // Only accept numbers as input
        if (!/^\d*$/.test(value)) {
            return;
        }

        setOtpValues((prev) => prev.map((v, i) => (i === index ? value : v)));

        // Move focus to previous input box if backspace is pressed or value is removed
        if ((keyCode === 8 || value === '') && index !== 0) {
            inputRefs[index - 1].current.focus();
        }

        // Move focus to next input box if a digit is entered
        if (keyCode !== 8 && index !== otpValues.length - 1) {
            if (value.length === 1) {
                inputRefs[index + 1].current.focus();
            }
        }
    };

    const handlePaste = (event, index) => {
        const pastedData = event.clipboardData.getData('text').trim();

        // Only accept pasted data with the same length as the expected OTP length
        if (pastedData.length !== otpValues.length) {
            return;
        }

        // Only accept numbers as input
        if (!/^\d*$/.test(pastedData)) {
            return;
        }

        // Update the OTP values with the pasted data
        setOtpValues(pastedData.split(''));

        // Move focus to last input box
        inputRefs[otpValues.length - 1].current.focus();
    };

    useEffect(() => {
        if (countdown === 0) return;
        const interval = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [countdown]);

    useEffect(() => {
        setCountdown(countDownTime);
    }, [countDownTime]);

    useEffect(() => {
        const index = otpValues.findIndex((v) => v === '');
        if (index !== -1) inputRefs[index].current.focus();
    }, [otpValues]);

    //NOTE: chrome has a bug ignoreing maxLength when type of input is number so below soultion is inspired by below
    // https://stackoverflow.com/questions/18510845/maxlength-ignored-for-input-type-number-in-chrome
    const handleKeyPress = (event) => {
        if (event.target.value.length === 1) {
            event.preventDefault();
        }
    };

    return (
        <>
            <div className="d-flex justify-content-center align-items-center">
                {otpValues.map((value, index) => (
                    <input
                        key={index}
                        ref={inputRefs[index]}
                        value={value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, index)}
                        onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => handlePaste(event, index)}
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            if (event.keyCode === 8 && value === '' && index !== 0) {
                                inputRefs[index - 1].current.focus();
                            }
                        }}
                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            const keyCode = event.which ? event.which : event.keyCode;
                            if (keyCode < 48 || keyCode > 57) {
                                event.preventDefault();
                            }
                        }}
                        onInput={(event: React.FormEvent<HTMLInputElement>) => {
                            const input = (event.target as HTMLInputElement).value;
                            (event.target as HTMLInputElement).value = input.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                        }}
                        onCut={(event: React.ClipboardEvent<HTMLInputElement>) => {
                            event.preventDefault();
                        }}
                        onCopy={(event: React.ClipboardEvent<HTMLInputElement>) => {
                            event.preventDefault();
                        }}
                        onDrag={(event: React.DragEvent<HTMLInputElement>) => {
                            event.preventDefault();
                        }}
                        onDrop={(event: React.DragEvent<HTMLInputElement>) => {
                            event.preventDefault();
                        }}
                        className="otp-input text-center box-shadow"
                        type="text"
                        inputMode="numeric"
                        maxLength={1}
                    />
                ))}
            </div>
            {error && (
                <DoctTypography variant="textLabel2" className="text-danger font-italic text-center">
                    Incorrect OTP, Please enter correct code.
                </DoctTypography>
            )}
            {verifiedMessage && (
                <DoctTypography variant="textLabel2" className="text-danger font-italic text-center">
                    {verifiedMessage}
                </DoctTypography>
            )}

            <DoctTypography variant="textLabel2" className="text-center text-grey-600">
                If OTP doesn’t arrive, you can get a new one in {countdown} seconds
            </DoctTypography>
            <div className="d-flex justify-content-center align-items-center">
                <DoctButton
                    text="Resend"
                    variant="text"
                    type="inverse"
                    size="medium"
                    disabled={countdown != 0 || isResendDisable}
                    onButtonClickHandler={() => {
                        setIsResendClicked((prev) => prev!);
                        onResendClick();
                    }}
                />
            </div>
        </>
    );
};

export default CustomOTPInputField;
