import React, { ReactElement, ReactNode } from 'react';
import './Grid.scss';

interface Props {
    children: ReactNode;
}

function Container({ children }: Props): ReactElement {
    return <div className="container">{children}</div>;
}

export default Container;
