import React, { ReactElement } from 'react';

import { Controller, Control, FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';

import './RadioButtons.scss';

type StrNum = string | number;
interface Props {
    control: Control<Record<string, any>>;
    name: string;
    options: { value: StrNum; label: StrNum; disabled?: boolean }[];
    id: string;
    ariaLabel?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    validationRules?: any;
    defaultValue?: StrNum;
    className?: string;
}

export default function DoctRadioButtons({
    name,
    id,
    isErrors,
    validationRules,
    control,
    options,
    defaultValue = options[0].value,
    className,
    ...rest
}: Props): ReactElement {
    return (
        <>
            <Controller
                rules={validationRules}
                render={({ onChange, value }) => (
                    <RadioGroup
                        className={`flex-row doct-radio-buttons${className ? ` ${className}` : ''} `}
                        name={name}
                        value={value}
                        onChange={onChange}
                        aria-label={name}
                    >
                        {options.map(({ value: optionValue, label, disabled }, i) => {
                            return (
                                <FormControlLabel
                                    className={value == optionValue ? 'doct-label-active' : ''}
                                    disabled={disabled}
                                    key={i}
                                    value={optionValue}
                                    control={<Radio />}
                                    label={label}
                                />
                            );
                        })}
                    </RadioGroup>
                )}
                defaultValue={defaultValue}
                name={name}
                control={control}
                {...rest}
            />
            {isErrors[name] && (
                <FormHelperText className="Mui-error MuiFormHelperText-filled" id={id}>
                    {isErrors[name]?.message}
                </FormHelperText>
            )}
        </>
    );
}
