import React from 'react';

import { DoctIcon, DoctTypography } from 'doct-core';

import './FeatureCard.scss';

interface FeatureCardProps {
    iconProps: { name: string; size?: string; color?: string };
    title: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, iconProps: { name, size = '24px', color } }) => {
    return (
        <div className="feature-card d-flex flex-column justify-between align-items-center">
            <div className="text-center">
                <DoctIcon name={name} width={size} fill={color} />
                <DoctTypography className="m-0 text-grey-800" variant="subtitle1">
                    {title}
                </DoctTypography>
            </div>
            <span className="text-center feature-card-icon-wrapper d-flex align-items-center justify-content-center mt-auto">
                <DoctIcon name="right" width="24" />
            </span>
        </div>
    );
};

export default FeatureCard;
