import React, { ReactElement } from 'react';
import { DoctTypography, DoctButton } from 'doct-core';

import './DoctCookiesBar.scss';

interface Props {
    onConfirmClick: () => void;
    onCoockiesPolicyClick: () => void;
}

export default function DoctCookiesBar({ onCoockiesPolicyClick, onConfirmClick }: Props): ReactElement {
    return (
        <div className="coockies-panel">
            <DoctTypography variant="input" className="input-text">
                We use cookies to improve your experience. By continuing to browse the site, you agree to our
            </DoctTypography>
            <DoctButton
                variant="text"
                onButtonClickHandler={onCoockiesPolicyClick}
                type="primary"
                text="Cookie Policy"
                size="medium"
            />
            <DoctButton
                variant="contained"
                onButtonClickHandler={onConfirmClick}
                type="primary"
                text="Got it"
                size="medium"
            />
        </div>
    );
}
