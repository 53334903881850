import React from 'react';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function TestingTextField() {
    // const classes = useStyles();

    return <TextField required id="standard-required" label="Required" defaultValue="Hello World" />;
}
