import React, { ReactElement } from 'react';
import Tabs from '@mui/material/Tabs';
import DoctTab from './DoctTab';

interface Props {
    handleChange?: any;
    value?: any;
    indicatorColor?: any;
    textColor?: any;
    className?: any;
    tabOptions?: any;
}

function DoctTabWrapper({
    handleChange,
    value,
    indicatorColor,
    textColor,
    className,
    tabOptions,
    ...rest
}: Props): ReactElement {
    const classes = [];

    classes.push('doct-tab-primary');

    return (
        <>
            <Tabs
                value={value}
                indicatorColor={indicatorColor}
                textColor={textColor}
                onChange={handleChange}
                className={classes.join(' ')}
                {...rest}
            >
                {tabOptions.map((item, index) => (
                    <DoctTab label={item.label} key={index} />
                ))}
            </Tabs>
            <div className="doct-tab-divider"></div>
        </>
    );
}

export default DoctTabWrapper;
