export const dialCodes = [
    {
        name: 'Afghanistan',
        label: '+93',
        countryCode: 'AF',
    },
    {
        name: 'Åland Islands',
        label: '+358',
        countryCode: 'AX',
    },
    {
        name: 'Albania',
        label: '+355',
        countryCode: 'AL',
    },
    {
        name: 'Algeria',
        label: '+213',
        countryCode: 'DZ',
    },
    {
        name: 'American Samoa',
        label: '+1684',
        countryCode: 'AS',
    },
    {
        name: 'Andorra',
        label: '+376',
        countryCode: 'AD',
    },
    {
        name: 'Angola',
        label: '+244',
        countryCode: 'AO',
    },
    {
        name: 'Anguilla',
        label: '+1264',
        countryCode: 'AI',
    },
    {
        name: 'Antarctica',
        label: '+672',
        countryCode: 'AQ',
    },
    {
        name: 'Antigua and Barbuda',
        label: '+1268',
        countryCode: 'AG',
    },
    {
        name: 'Argentina',
        label: '+54',
        countryCode: 'AR',
    },
    {
        name: 'Armenia',
        label: '+374',
        countryCode: 'AM',
    },
    {
        name: 'Aruba',
        label: '+297',
        countryCode: 'AW',
    },
    {
        name: 'Australia',
        label: '+61',
        countryCode: 'AU',
    },
    {
        name: 'Austria',
        label: '+43',
        countryCode: 'AT',
    },
    {
        name: 'Azerbaijan',
        label: '+994',
        countryCode: 'AZ',
    },
    {
        name: 'Bahamas',
        label: '+1242',
        countryCode: 'BS',
    },
    {
        name: 'Bahrain',
        label: '+973',
        countryCode: 'BH',
    },
    {
        name: 'Bangladesh',
        label: '+880',
        countryCode: 'BD',
    },
    {
        name: 'Barbados',
        label: '+1246',
        countryCode: 'BB',
    },
    {
        name: 'Belarus',
        label: '+375',
        countryCode: 'BY',
    },
    {
        name: 'Belgium',
        label: '+32',
        countryCode: 'BE',
    },
    {
        name: 'Belize',
        label: '+501',
        countryCode: 'BZ',
    },
    {
        name: 'Benin',
        label: '+229',
        countryCode: 'BJ',
    },
    {
        name: 'Bermuda',
        label: '+1441',
        countryCode: 'BM',
    },
    {
        name: 'Bhutan',
        label: '+975',
        countryCode: 'BT',
    },
    {
        name: 'Bolivia, Plurinational State of bolivia',
        label: '+591',
        countryCode: 'BO',
    },
    {
        name: 'Bosnia and Herzegovina',
        label: '+387',
        countryCode: 'BA',
    },
    {
        name: 'Botswana',
        label: '+267',
        countryCode: 'BW',
    },
    {
        name: 'Bouvet Island',
        label: '+47',
        countryCode: 'BV',
    },
    {
        name: 'Brazil',
        label: '+55',
        countryCode: 'BR',
    },
    {
        name: 'British Indian Ocean Territory',
        label: '+246',
        countryCode: 'IO',
    },
    {
        name: 'Brunei Darussalam',
        label: '+673',
        countryCode: 'BN',
    },
    {
        name: 'Bulgaria',
        label: '+359',
        countryCode: 'BG',
    },
    {
        name: 'Burkina Faso',
        label: '+226',
        countryCode: 'BF',
    },
    {
        name: 'Burundi',
        label: '+257',
        countryCode: 'BI',
    },
    {
        name: 'Cambodia',
        label: '+855',
        countryCode: 'KH',
    },
    {
        name: 'Cameroon',
        label: '+237',
        countryCode: 'CM',
    },
    {
        name: 'Canada',
        label: '+1',
        countryCode: 'CA',
    },
    {
        name: 'Cape Verde',
        label: '+238',
        countryCode: 'CV',
    },
    {
        name: 'Cayman Islands',
        label: '+ 345',
        countryCode: 'KY',
    },
    {
        name: 'Central African Republic',
        label: '+236',
        countryCode: 'CF',
    },
    {
        name: 'Chad',
        label: '+235',
        countryCode: 'TD',
    },
    {
        name: 'Chile',
        label: '+56',
        countryCode: 'CL',
    },
    {
        name: 'China',
        label: '+86',
        countryCode: 'CN',
    },
    {
        name: 'Christmas Island',
        label: '+61',
        countryCode: 'CX',
    },
    {
        name: 'Cocos (Keeling) Islands',
        label: '+61',
        countryCode: 'CC',
    },
    {
        name: 'Colombia',
        label: '+57',
        countryCode: 'CO',
    },
    {
        name: 'Comoros',
        label: '+269',
        countryCode: 'KM',
    },
    {
        name: 'Congo',
        label: '+242',
        countryCode: 'CG',
    },
    {
        name: 'Congo, The Democratic Republic of the Congo',
        label: '+243',
        countryCode: 'CD',
    },
    {
        name: 'Cook Islands',
        label: '+682',
        countryCode: 'CK',
    },
    {
        name: 'Costa Rica',
        label: '+506',
        countryCode: 'CR',
    },
    {
        name: "Cote d'Ivoire",
        label: '+225',
        countryCode: 'CI',
    },
    {
        name: 'Croatia',
        label: '+385',
        countryCode: 'HR',
    },
    {
        name: 'Cuba',
        label: '+53',
        countryCode: 'CU',
    },
    {
        name: 'Cyprus',
        label: '+357',
        countryCode: 'CY',
    },
    {
        name: 'Czech Republic',
        label: '+420',
        countryCode: 'CZ',
    },
    {
        name: 'Denmark',
        label: '+45',
        countryCode: 'DK',
    },
    {
        name: 'Djibouti',
        label: '+253',
        countryCode: 'DJ',
    },
    {
        name: 'Dominica',
        label: '+1767',
        countryCode: 'DM',
    },
    {
        name: 'Dominican Republic',
        label: '+1849',
        countryCode: 'DO',
    },
    {
        name: 'Ecuador',
        label: '+593',
        countryCode: 'EC',
    },
    {
        name: 'Egypt',
        label: '+20',
        countryCode: 'EG',
    },
    {
        name: 'El Salvador',
        label: '+503',
        countryCode: 'SV',
    },
    {
        name: 'Equatorial Guinea',
        label: '+240',
        countryCode: 'GQ',
    },
    {
        name: 'Eritrea',
        label: '+291',
        countryCode: 'ER',
    },
    {
        name: 'Estonia',
        label: '+372',
        countryCode: 'EE',
    },
    {
        name: 'Ethiopia',
        label: '+251',
        countryCode: 'ET',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        label: '+500',
        countryCode: 'FK',
    },
    {
        name: 'Faroe Islands',
        label: '+298',
        countryCode: 'FO',
    },
    {
        name: 'Fiji',
        label: '+679',
        countryCode: 'FJ',
    },
    {
        name: 'Finland',
        label: '+358',
        countryCode: 'FI',
    },
    {
        name: 'France',
        label: '+33',
        countryCode: 'FR',
    },
    {
        name: 'French Guiana',
        label: '+594',
        countryCode: 'GF',
    },
    {
        name: 'French Polynesia',
        label: '+689',
        countryCode: 'PF',
    },
    {
        name: 'French Southern Territories',
        label: '+262',
        countryCode: 'TF',
    },
    {
        name: 'Gabon',
        label: '+241',
        countryCode: 'GA',
    },
    {
        name: 'Gambia',
        label: '+220',
        countryCode: 'GM',
    },
    {
        name: 'Georgia',
        label: '+995',
        countryCode: 'GE',
    },
    {
        name: 'Germany',
        label: '+49',
        countryCode: 'DE',
    },
    {
        name: 'Ghana',
        label: '+233',
        countryCode: 'GH',
    },
    {
        name: 'Gibraltar',
        label: '+350',
        countryCode: 'GI',
    },
    {
        name: 'Greece',
        label: '+30',
        countryCode: 'GR',
    },
    {
        name: 'Greenland',
        label: '+299',
        countryCode: 'GL',
    },
    {
        name: 'Grenada',
        label: '+1473',
        countryCode: 'GD',
    },
    {
        name: 'Guadeloupe',
        label: '+590',
        countryCode: 'GP',
    },
    {
        name: 'Guam',
        label: '+1671',
        countryCode: 'GU',
    },
    {
        name: 'Guatemala',
        label: '+502',
        countryCode: 'GT',
    },
    {
        name: 'Guernsey',
        label: '+44',
        countryCode: 'GG',
    },
    {
        name: 'Guinea',
        label: '+224',
        countryCode: 'GN',
    },
    {
        name: 'Guinea-Bissau',
        label: '+245',
        countryCode: 'GW',
    },
    {
        name: 'Guyana',
        label: '+592',
        countryCode: 'GY',
    },
    {
        name: 'Haiti',
        label: '+509',
        countryCode: 'HT',
    },
    {
        name: 'Heard Island and Mcdonald Islands',
        label: '+0',
        countryCode: 'HM',
    },
    {
        name: 'Holy See (Vatican City State)',
        label: '+379',
        countryCode: 'VA',
    },
    {
        name: 'Honduras',
        label: '+504',
        countryCode: 'HN',
    },
    {
        name: 'Hong Kong',
        label: '+852',
        countryCode: 'HK',
    },
    {
        name: 'Hungary',
        label: '+36',
        countryCode: 'HU',
    },
    {
        name: 'Iceland',
        label: '+354',
        countryCode: 'IS',
    },
    {
        name: 'India',
        label: '+91',
        countryCode: 'IN',
    },
    {
        name: 'Indonesia',
        label: '+62',
        countryCode: 'ID',
    },
    {
        name: 'Iran, Islamic Republic of Persian Gulf',
        label: '+98',
        countryCode: 'IR',
    },
    {
        name: 'Iraq',
        label: '+964',
        countryCode: 'IQ',
    },
    {
        name: 'Ireland',
        label: '+353',
        countryCode: 'IE',
    },
    {
        name: 'Isle of Man',
        label: '+44',
        countryCode: 'IM',
    },
    {
        name: 'Israel',
        label: '+972',
        countryCode: 'IL',
    },
    {
        name: 'Italy',
        label: '+39',
        countryCode: 'IT',
    },
    {
        name: 'Jamaica',
        label: '+1876',
        countryCode: 'JM',
    },
    {
        name: 'Japan',
        label: '+81',
        countryCode: 'JP',
    },
    {
        name: 'Jersey',
        label: '+44',
        countryCode: 'JE',
    },
    {
        name: 'Jordan',
        label: '+962',
        countryCode: 'JO',
    },
    {
        name: 'Kazakhstan',
        label: '+7',
        countryCode: 'KZ',
    },
    {
        name: 'Kenya',
        label: '+254',
        countryCode: 'KE',
    },
    {
        name: 'Kiribati',
        label: '+686',
        countryCode: 'KI',
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        label: '+850',
        countryCode: 'KP',
    },
    {
        name: 'Korea, Republic of South Korea',
        label: '+82',
        countryCode: 'KR',
    },
    {
        name: 'Kosovo',
        label: '+383',
        countryCode: 'XK',
    },
    {
        name: 'Kuwait',
        label: '+965',
        countryCode: 'KW',
    },
    {
        name: 'Kyrgyzstan',
        label: '+996',
        countryCode: 'KG',
    },
    {
        name: 'Laos',
        label: '+856',
        countryCode: 'LA',
    },
    {
        name: 'Latvia',
        label: '+371',
        countryCode: 'LV',
    },
    {
        name: 'Lebanon',
        label: '+961',
        countryCode: 'LB',
    },
    {
        name: 'Lesotho',
        label: '+266',
        countryCode: 'LS',
    },
    {
        name: 'Liberia',
        label: '+231',
        countryCode: 'LR',
    },
    {
        name: 'Libyan Arab Jamahiriya',
        label: '+218',
        countryCode: 'LY',
    },
    {
        name: 'Liechtenstein',
        label: '+423',
        countryCode: 'LI',
    },
    {
        name: 'Lithuania',
        label: '+370',
        countryCode: 'LT',
    },
    {
        name: 'Luxembourg',
        label: '+352',
        countryCode: 'LU',
    },
    {
        name: 'Macao',
        label: '+853',
        countryCode: 'MO',
    },
    {
        name: 'Macedonia',
        label: '+389',
        countryCode: 'MK',
    },
    {
        name: 'Madagascar',
        label: '+261',
        countryCode: 'MG',
    },
    {
        name: 'Malawi',
        label: '+265',
        countryCode: 'MW',
    },
    {
        name: 'Malaysia',
        label: '+60',
        countryCode: 'MY',
    },
    {
        name: 'Maldives',
        label: '+960',
        countryCode: 'MV',
    },
    {
        name: 'Mali',
        label: '+223',
        countryCode: 'ML',
    },
    {
        name: 'Malta',
        label: '+356',
        countryCode: 'MT',
    },
    {
        name: 'Marshall Islands',
        label: '+692',
        countryCode: 'MH',
    },
    {
        name: 'Martinique',
        label: '+596',
        countryCode: 'MQ',
    },
    {
        name: 'Mauritania',
        label: '+222',
        countryCode: 'MR',
    },
    {
        name: 'Mauritius',
        label: '+230',
        countryCode: 'MU',
    },
    {
        name: 'Mayotte',
        label: '+262',
        countryCode: 'YT',
    },
    {
        name: 'Mexico',
        label: '+52',
        countryCode: 'MX',
    },
    {
        name: 'Micronesia, Federated States of Micronesia',
        label: '+691',
        countryCode: 'FM',
    },
    {
        name: 'Moldova',
        label: '+373',
        countryCode: 'MD',
    },
    {
        name: 'Monaco',
        label: '+377',
        countryCode: 'MC',
    },
    {
        name: 'Mongolia',
        label: '+976',
        countryCode: 'MN',
    },
    {
        name: 'Montenegro',
        label: '+382',
        countryCode: 'ME',
    },
    {
        name: 'Montserrat',
        label: '+1664',
        countryCode: 'MS',
    },
    {
        name: 'Morocco',
        label: '+212',
        countryCode: 'MA',
    },
    {
        name: 'Mozambique',
        label: '+258',
        countryCode: 'MZ',
    },
    {
        name: 'Myanmar',
        label: '+95',
        countryCode: 'MM',
    },
    {
        name: 'Namibia',
        label: '+264',
        countryCode: 'NA',
    },
    {
        name: 'Nauru',
        label: '+674',
        countryCode: 'NR',
    },
    {
        name: 'Nepal',
        label: '+977',
        countryCode: 'NP',
    },
    {
        name: 'Netherlands',
        label: '+31',
        countryCode: 'NL',
    },
    {
        name: 'Netherlands Antilles',
        label: '+599',
        countryCode: 'AN',
    },
    {
        name: 'New Caledonia',
        label: '+687',
        countryCode: 'NC',
    },
    {
        name: 'New Zealand',
        label: '+64',
        countryCode: 'NZ',
    },
    {
        name: 'Nicaragua',
        label: '+505',
        countryCode: 'NI',
    },
    {
        name: 'Niger',
        label: '+227',
        countryCode: 'NE',
    },
    {
        name: 'Nigeria',
        label: '+234',
        countryCode: 'NG',
    },
    {
        name: 'Niue',
        label: '+683',
        countryCode: 'NU',
    },
    {
        name: 'Norfolk Island',
        label: '+672',
        countryCode: 'NF',
    },
    {
        name: 'Northern Mariana Islands',
        label: '+1670',
        countryCode: 'MP',
    },
    {
        name: 'Norway',
        label: '+47',
        countryCode: 'NO',
    },
    {
        name: 'Oman',
        label: '+968',
        countryCode: 'OM',
    },
    {
        name: 'Pakistan',
        label: '+92',
        countryCode: 'PK',
    },
    {
        name: 'Palau',
        label: '+680',
        countryCode: 'PW',
    },
    {
        name: 'Palestinian Territory, Occupied',
        label: '+970',
        countryCode: 'PS',
    },
    {
        name: 'Panama',
        label: '+507',
        countryCode: 'PA',
    },
    {
        name: 'Papua New Guinea',
        label: '+675',
        countryCode: 'PG',
    },
    {
        name: 'Paraguay',
        label: '+595',
        countryCode: 'PY',
    },
    {
        name: 'Peru',
        label: '+51',
        countryCode: 'PE',
    },
    {
        name: 'Philippines',
        label: '+63',
        countryCode: 'PH',
    },
    {
        name: 'Pitcairn',
        label: '+64',
        countryCode: 'PN',
    },
    {
        name: 'Poland',
        label: '+48',
        countryCode: 'PL',
    },
    {
        name: 'Portugal',
        label: '+351',
        countryCode: 'PT',
    },
    {
        name: 'Puerto Rico',
        label: '+1939',
        countryCode: 'PR',
    },
    {
        name: 'Qatar',
        label: '+974',
        countryCode: 'QA',
    },
    {
        name: 'Romania',
        label: '+40',
        countryCode: 'RO',
    },
    {
        name: 'Russia',
        label: '+7',
        countryCode: 'RU',
    },
    {
        name: 'Rwanda',
        label: '+250',
        countryCode: 'RW',
    },
    {
        name: 'Reunion',
        label: '+262',
        countryCode: 'RE',
    },
    {
        name: 'Saint Barthelemy',
        label: '+590',
        countryCode: 'BL',
    },
    {
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        label: '+290',
        countryCode: 'SH',
    },
    {
        name: 'Saint Kitts and Nevis',
        label: '+1869',
        countryCode: 'KN',
    },
    {
        name: 'Saint Lucia',
        label: '+1758',
        countryCode: 'LC',
    },
    {
        name: 'Saint Martin',
        label: '+590',
        countryCode: 'MF',
    },
    {
        name: 'Saint Pierre and Miquelon',
        label: '+508',
        countryCode: 'PM',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        label: '+1784',
        countryCode: 'VC',
    },
    {
        name: 'Samoa',
        label: '+685',
        countryCode: 'WS',
    },
    {
        name: 'San Marino',
        label: '+378',
        countryCode: 'SM',
    },
    {
        name: 'Sao Tome and Principe',
        label: '+239',
        countryCode: 'ST',
    },
    {
        name: 'Saudi Arabia',
        label: '+966',
        countryCode: 'SA',
    },
    {
        name: 'Senegal',
        label: '+221',
        countryCode: 'SN',
    },
    {
        name: 'Serbia',
        label: '+381',
        countryCode: 'RS',
    },
    {
        name: 'Seychelles',
        label: '+248',
        countryCode: 'SC',
    },
    {
        name: 'Sierra Leone',
        label: '+232',
        countryCode: 'SL',
    },
    {
        name: 'Singapore',
        label: '+65',
        countryCode: 'SG',
    },
    {
        name: 'Slovakia',
        label: '+421',
        countryCode: 'SK',
    },
    {
        name: 'Slovenia',
        label: '+386',
        countryCode: 'SI',
    },
    {
        name: 'Solomon Islands',
        label: '+677',
        countryCode: 'SB',
    },
    {
        name: 'Somalia',
        label: '+252',
        countryCode: 'SO',
    },
    {
        name: 'South Africa',
        label: '+27',
        countryCode: 'ZA',
    },
    {
        name: 'South Sudan',
        label: '+211',
        countryCode: 'SS',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        label: '+500',
        countryCode: 'GS',
    },
    {
        name: 'Spain',
        label: '+34',
        countryCode: 'ES',
    },
    {
        name: 'Sri Lanka',
        label: '+94',
        countryCode: 'LK',
    },
    {
        name: 'Sudan',
        label: '+249',
        countryCode: 'SD',
    },
    {
        name: 'Suriname',
        label: '+597',
        countryCode: 'SR',
    },
    {
        name: 'Svalbard and Jan Mayen',
        label: '+47',
        countryCode: 'SJ',
    },
    {
        name: 'Swaziland',
        label: '+268',
        countryCode: 'SZ',
    },
    {
        name: 'Sweden',
        label: '+46',
        countryCode: 'SE',
    },
    {
        name: 'Switzerland',
        label: '+41',
        countryCode: 'CH',
    },
    {
        name: 'Syrian Arab Republic',
        label: '+963',
        countryCode: 'SY',
    },
    {
        name: 'Taiwan',
        label: '+886',
        countryCode: 'TW',
    },
    {
        name: 'Tajikistan',
        label: '+992',
        countryCode: 'TJ',
    },
    {
        name: 'Tanzania, United Republic of Tanzania',
        label: '+255',
        countryCode: 'TZ',
    },
    {
        name: 'Thailand',
        label: '+66',
        countryCode: 'TH',
    },
    {
        name: 'Timor-Leste',
        label: '+670',
        countryCode: 'TL',
    },
    {
        name: 'Togo',
        label: '+228',
        countryCode: 'TG',
    },
    {
        name: 'Tokelau',
        label: '+690',
        countryCode: 'TK',
    },
    {
        name: 'Tonga',
        label: '+676',
        countryCode: 'TO',
    },
    {
        name: 'Trinidad and Tobago',
        label: '+1868',
        countryCode: 'TT',
    },
    {
        name: 'Tunisia',
        label: '+216',
        countryCode: 'TN',
    },
    {
        name: 'Turkey',
        label: '+90',
        countryCode: 'TR',
    },
    {
        name: 'Turkmenistan',
        label: '+993',
        countryCode: 'TM',
    },
    {
        name: 'Turks and Caicos Islands',
        label: '+1649',
        countryCode: 'TC',
    },
    {
        name: 'Tuvalu',
        label: '+688',
        countryCode: 'TV',
    },
    {
        name: 'Uganda',
        label: '+256',
        countryCode: 'UG',
    },
    {
        name: 'Ukraine',
        label: '+380',
        countryCode: 'UA',
    },
    {
        name: 'United Arab Emirates',
        label: '+971',
        countryCode: 'AE',
    },
    {
        name: 'United Kingdom',
        label: '+44',
        countryCode: 'GB',
    },
    {
        name: 'United States',
        label: '+1',
        countryCode: 'US',
    },
    {
        name: 'Uruguay',
        label: '+598',
        countryCode: 'UY',
    },
    {
        name: 'Uzbekistan',
        label: '+998',
        countryCode: 'UZ',
    },
    {
        name: 'Vanuatu',
        label: '+678',
        countryCode: 'VU',
    },
    {
        name: 'Venezuela, Bolivarian Republic of Venezuela',
        label: '+58',
        countryCode: 'VE',
    },
    {
        name: 'Vietnam',
        label: '+84',
        countryCode: 'VN',
    },
    {
        name: 'Virgin Islands, British',
        label: '+1284',
        countryCode: 'VG',
    },
    {
        name: 'Virgin Islands, U.S.',
        label: '+1340',
        countryCode: 'VI',
    },
    {
        name: 'Wallis and Futuna',
        label: '+681',
        countryCode: 'WF',
    },
    {
        name: 'Yemen',
        label: '+967',
        countryCode: 'YE',
    },
    {
        name: 'Zambia',
        label: '+260',
        countryCode: 'ZM',
    },
    {
        name: 'Zimbabwe',
        label: '+263',
        countryCode: 'ZW',
    },
];
