import React, { ReactElement } from 'react';
// import Button from '../Buttons/DoctButton';

interface Props {
    leftSideContent: any;
    activeStep: number;
    handleBack: () => void;
    handleNext: () => void;
    primaryButtonContent?: any;
    secondaryButtonContent?: any;
}

export default function StepsFooter({
    leftSideContent,
    activeStep,
    handleBack,
    handleNext,
    primaryButtonContent,
    secondaryButtonContent,
}: Props): ReactElement {
    return (
        <div className={`d-flex align-items-center justify-content-between`}>
            {/* left side content render conditionally */}
            <div>
                {leftSideContent.map((item, i) => {
                    return item.show && item.show.length > 0 ? (
                        item.show.map(
                            (step) =>
                                step === activeStep && <React.Fragment key={activeStep}>{item.content}</React.Fragment>,
                        )
                    ) : (
                        <React.Fragment key={i}>{item.content}</React.Fragment>
                    );
                })}
            </div>
            {/* <div>
                {secondaryButtonContent
                    ? secondaryButtonContent.map((item, i) => {
                          return item.show && item.show.length > 0 ? (
                              item.show.map(
                                  (step) =>
                                      step === activeStep && (
                                          <Button size="small" onButtonClickHandler={handleBack} text={item.content} />
                                      ),
                              )
                          ) : (
                              <Button size="small" onButtonClickHandler={handleBack} text={item.content} />
                          );
                      })
                    : activeStep >= 1 && <Button size="small" onButtonClickHandler={handleBack} text={'Go Back'} />}
                {primaryButtonContent ? (
                    primaryButtonContent.map((item, i) => {
                        return item.show && item.show.length > 0 ? (
                            item.show.map(
                                (step) =>
                                    step === activeStep && (
                                        <Button size="small" onButtonClickHandler={handleNext} text={item.content} />
                                    ),
                            )
                        ) : (
                            <Button size="small" onButtonClickHandler={handleNext} text={item.content} />
                        );
                    })
                ) : (
                    <Button size="small" onButtonClickHandler={handleNext} text={'Go next'} />
                )}
            </div> */}
        </div>
    );
}
