export enum ButtonSize {
    Medium = 'medium',
    Small = 'small',
}

export enum ButtonType {
    Primary = 'primary',
    Secondary = 'secondary',
    SemanticDanger = 'semantic-danger',
    SemanticSuccess = 'semantic-success',
    SemanticWarning = 'semantic-warning',
    SemanticInfo = 'semantic-info',
    Inverse = 'inverse',
}

export enum ButtonVariant {
    Text = 'text',
    Outlined = 'outlined',
    Contained = 'contained',
}
