import React, { ReactNode } from 'react';

import { DoctButton, DoctIcon, DoctIconButton, DoctTypography } from 'doct-core';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

import './DoctModal.scss';

const useStyles = makeStyles(() =>
    createStyles({
        modalCenter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: '#fff',
            padding: 0,
        },
    }),
);

interface DoctModalProps {
    width?: number;
    title?: string;
    children: ReactNode;
    open: boolean;
    handleClose: () => void;
    handleSecondaryButtonClick?: () => any;
    handlePrimaryButtonClick?: () => any;
    handleIconButtonClick?: () => any;
    iconName?: string;
    secondaryBtnText?: string;
    primaryBtnText?: string;
    disableBackdropClick?: boolean;
    showFooter?: boolean;
    className?: string;
    isPrimaryBtnDisable?: boolean;
    isSecondaryBtnDisable?: boolean;
}

const DoctModal: React.FC<DoctModalProps> = ({
    width,
    title,
    children,
    open,
    handleClose,
    handleSecondaryButtonClick,
    handlePrimaryButtonClick,
    handleIconButtonClick,
    secondaryBtnText = 'Cancel',
    primaryBtnText = 'Ok',
    iconName = 'facebook',
    disableBackdropClick = false,
    showFooter = true,
    className,
    isPrimaryBtnDisable = false,
    isSecondaryBtnDisable = false,
}) => {
    const classes = useStyles();

    const onCancelButtonClick = () => {
        handleClose(); // By default on cancel modal will be closed
        // If any additional click handle required on cancel
        handleSecondaryButtonClick ? handleSecondaryButtonClick() : '';
    };

    return (
        <Modal // `disableBackdropClick` is removed by codemod.
            // You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)

            disableEnforceFocus
            disableRestoreFocus
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={`${classes.modalCenter} doct-modal${className ? ` ${className}` : ''}`}
            open={open}
            onClose={handleClose}
            disableAutoFocus
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div>
                    <div
                        className={`${classes.paper} doct-modal-content-wrapper`}
                        style={{
                            maxWidth: width ? width : '328px',
                            width: width ? width : '328px',
                        }}
                    >
                        <div
                            className={`doct-modal-header bg-grey-200 d-flex ${
                                title ? 'justify-content-between' : 'justify-content-end'
                            } align-items-center`}
                        >
                            {title && (
                                <DoctTypography variant="subtitle1" className="text-grey-800 m-0">
                                    {title}
                                </DoctTypography>
                            )}
                            <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                                <DoctIcon className="text-grey-400" name="close" width="24" />
                            </div>
                        </div>
                        <div className="doct-modal-body bg-grey-200">{children}</div>
                        {showFooter && (
                            <div
                                className={`doct-modal-footer bg-white d-flex ${
                                    iconName ? `justify-content-between` : 'justify-content-end'
                                }`}
                            >
                                {iconName && (
                                    <DoctIconButton
                                        size="medium"
                                        onButtonClickHandler={handleIconButtonClick}
                                        icon={iconName}
                                        type="secondary"
                                        variant="outlined"
                                        disabled={isSecondaryBtnDisable}
                                    />
                                )}
                                <div className="d-flex">
                                    <DoctButton
                                        size="medium"
                                        type="secondary"
                                        text={secondaryBtnText}
                                        onButtonClickHandler={onCancelButtonClick}
                                    />
                                    <DoctButton
                                        size="medium"
                                        type="primary"
                                        text={primaryBtnText}
                                        onButtonClickHandler={handlePrimaryButtonClick}
                                        disabled={isPrimaryBtnDisable}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default DoctModal;
