import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import './Checkbox.scss';

interface CheckboxProps {
    onChange?: (e: any) => void;
    isChecked?: boolean;
    category?: string;
    className?: string;
    id?: string;
    dataValue?: string | number;
    label: string;
    count?: number | string;
    value?: any;
    checkboxProps?: any;
    formControlLabelProps?: any;
}

const CheckboxComponent: React.FC<CheckboxProps> = ({
    className,
    dataValue,
    onChange,
    id,
    category,
    isChecked,
    label,
    count,
    value,
    checkboxProps,
    formControlLabelProps,
    ...rest
}) => {
    return (
        <FormControlLabel
            data-value={dataValue}
            className={`doct-checkbox${className ? ` ${className}` : ''}`}
            control={
                <Checkbox
                    checked={isChecked}
                    color="primary"
                    onChange={onChange}
                    name={label}
                    id={id}
                    {...checkboxProps}
                />
            }
            label={`${label} ${count ? `(${count})` : ''} `}
            {...formControlLabelProps}
        />
    );
};

export default CheckboxComponent;

// import React, { ReactElement } from 'react';

// import { Controller, Control, FieldError } from 'react-hook-form';
// import { DeepMap } from 'react-hook-form/dist/types/utils';

// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// import './Checkbox.scss';

// interface Props {
//     label: string;
//     id: string;
//     name: string;
//     control: Control<Record<string, any>>;
//     disabled?: boolean;
//     readOnly?: boolean;
//     className?: string;
//     isErrors?: DeepMap<Record<string, any>, FieldError>;
//     // touched?: DeepMap<Record<string, any>, true>;
//     validationRules?: any;
//     defaultValue?: boolean;
//     indeterminate?: boolean;
// }

// export default function DoctPasswordInput({
//     label = 'Checkbox',
//     id,
//     name,
//     disabled = false,
//     className,
//     control,
//     defaultValue = false,
//     validationRules,
//     indeterminate = false,
//     ...rest
// }: Props): ReactElement {
//     return (
//         <Controller
//             name={name}
//             id={id}
//             rules={validationRules}
//             control={control}
//             defaultValue={defaultValue}
//             render={({ onChange, value }) => (
//                 <FormControlLabel
//                     className={`doct-checkbox${className ? ` ${className}` : ''}`}
//                     control={
//                         <Checkbox
//                             disabled={disabled}
//                             indeterminate={indeterminate}
//                             color="primary"
//                             onChange={(e) => onChange(e.target.checked)}
//                             checked={value}
//                         />
//                     }
//                     label={label}
//                 />
//             )}
//             {...rest}
//         />
//     );
// }
